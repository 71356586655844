import { fetchProfile } from 'lib/models/profile';
import { useEffect, useMemo } from 'react';

import useElement from './useElement';
import useStoreSelector from 'store/useStoreSelector';

function useProfile() {
	const { profile: loggedProfile, logged } = useStoreSelector(({ session }) => session);

	const { element: networkProfile, loading, refetch } = useElement(fetchProfile);

	const withProfile = !loading && !!networkProfile;

	const withError = !loading && !!networkProfile && !isProfile(networkProfile);

	const profile = useMemo(() => {
		return loggedProfile || (isProfile(networkProfile) ? networkProfile : null);
	}, [loggedProfile, networkProfile]);

	useEffect(() => {
		if (logged && !withProfile && !withError) {
			refetch();
		}
	}, [withProfile, withError, logged, refetch]);

	return profile;
}

export default useProfile;

function isProfile(
	data:
		| Profile
		| {
				error: true;
		  }
		| null
): data is Profile {
	return !!data && !(data as any).error;
}
