import { useCallback } from 'react';

import useStoreDispatch from 'store/useStoreDispatch';

import { login as loginDispatch } from 'store/reducers/session';

import useUpdateLanguage from 'hooks/useUpdateLanguage';

function useLogin() {
	const dispatch = useStoreDispatch();

	const updateLanguage = useUpdateLanguage();

	const login = useCallback(
		(response: Session) => {
			dispatch(loginDispatch(response));
			if (response.profile?.language) {
				updateLanguage(response.profile.language);
			}
		},
		[dispatch, updateLanguage]
	);

	return login;
}

export default useLogin;
