import {EqualityFn, useSelector} from 'react-redux';
import {RootState} from 'store/Store';

type Selector<T> = (state: RootState) => T;

export default function useStoreSelector<T>(
  selector: Selector<T>,
  equalityFn?: EqualityFn<T>,
) {
  const data = useSelector(selector, equalityFn);

  return data;
}
