import useClient from './useClient';

function useDebtAccounts() {
	const data = useClient();

	return {
		loading: data.loading,
		error: data.error,
		accounts: data.client?.debtsAccounts ?? [],
		refetch: data.refetch,
	};
}

export default useDebtAccounts;
