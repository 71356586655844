export const DRAWER_WIDTH = 240;

export const APPBAR_MAX_HEIGHT = 128;

// IF YOU ARE IN DEV MODE API_DOMAIN IS SET IN .env
// @ts-ignore
let API_DOMAIN = `${process.env.REACT_APP_API_DOMAIN}`;

// Check if the last index is a /, if not add it:
if (API_DOMAIN.substring(API_DOMAIN.length - 1) !== '/') {
	API_DOMAIN = `${API_DOMAIN}/`;
}

const AUTH_URL = 'auth';

const LOGIN_URL = `${AUTH_URL}/login`; // axios baseURL is ${API_DOMAIN}

const RECOVERY_URL = `${AUTH_URL}/password-recovery`; // axios baseURL is ${API_DOMAIN}

const RECOVERY_CONFIRM_URL = `${AUTH_URL}/password-recovery/confirm`; // axios baseURL is ${API_DOMAIN}

const ME_URL = `${AUTH_URL}/me`;

const FIRST_TIME_URL = `${AUTH_URL}/first-time`;

const USERS_URL = 'users';

const PEOPLE_URL = 'people';

const PROFILE_URL = 'profile';

const ADMIN_URL = 'admin';

const CLIENTS_URL = 'clients';

const rowsPerPageOptions = [5, 25, 50, 100, 500, 1000];

const defaultRowsPerPage = 5;

// @ts-ignore
const PANEL_MANUAL_URL = `${process.env.REACT_APP_PANEL_MANUAL_URL}`;
// @ts-ignore
const IT_MANUAL_URL = `${process.env.REACT_APP_IT_MANUAL_URL}`;

const SUPPORTED_LANGUAGES: SupportedLanguage[] = ['es', 'en'];

// @ts-ignore
const NODE_ENV = `${process.env.NODE_ENV || ''}`;

const CLIENTS_DELETION_ENABLED =
	// @ts-ignore
	`${process.env.REACT_CLIENTS_DELETION_ENABLED || 'no'}`.toLocaleLowerCase().substring(0, 1) === 'y';

export {
	API_DOMAIN,
	AUTH_URL,
	ME_URL,
	USERS_URL,
	PEOPLE_URL,
	LOGIN_URL,
	FIRST_TIME_URL,
	rowsPerPageOptions,
	defaultRowsPerPage,
	PANEL_MANUAL_URL,
	IT_MANUAL_URL,
	PROFILE_URL,
	SUPPORTED_LANGUAGES,
	ADMIN_URL,
	RECOVERY_URL,
	RECOVERY_CONFIRM_URL,
	NODE_ENV,
	CLIENTS_URL,
	CLIENTS_DELETION_ENABLED,
};
