import { updateProfile } from 'lib/models/profile';
import { useCallback } from 'react';

import useStoreDispatch from 'store/useStoreDispatch';

import { updateLanguage as updateLanguageDispatch } from 'store/reducers/session';

function useUpdateLanguage() {
	const dispatch = useStoreDispatch();

	const updateLanguage = useCallback(
		async (payload: SupportedLanguage) => {
			const updatedProfile = await updateProfile({ language: payload });
			if (updatedProfile) {
				dispatch(updateLanguageDispatch(updatedProfile.language || payload));
			}
		},
		[dispatch]
	);

	return updateLanguage;
}

export default useUpdateLanguage;
