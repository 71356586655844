import { ChangeEvent, useState } from 'react';
import { Box } from '@mui/material';
import Add from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import PersonEditModal from 'components/PersonManagement/PersonEditModal';
import { nanoid } from 'nanoid';
import { addDebtorsToDebtAccount } from 'lib/models/clients';
import PersonAddModal from 'components/PersonManagement/PersonAddModal';
import moment from 'moment';
import PersonElementFields from 'components/PersonManagement/PersonElementFields';
import { useTranslation } from 'react-i18next';

type Props = {
	client: Client;
	debtAccountCode: string;
	type: PersonType;
	onPersonAdded: () => void;
	agreement: Agreement;
};

function DebtsAdd({ client, type, debtAccountCode, agreement, onPersonAdded }: Props) {
	const { t } = useTranslation();

	const [person, setPerson] = useState<Person | null>(null);
	const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
	const [addingPerson, setAddingPerson] = useState(false);
	const [alternativeEmailIsValid, setAlternativeEmailIsValid] = useState<boolean>(true);
	const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>(true);

	function updatePersonKey(key: keyof Person) {
		return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setPerson({ ...(person || {}), [key]: ev.target.value } as Person);
		};
	}

	function onClose() {
		setPerson(null);
	}

	const isButtonDisabled: () => boolean | undefined = () => {
		const hasEmptyPhoneNumber = person?.phone_number?.slice(1).some((num: string) => num === '');
		const hasEmptyAlternativeEmail = person?.alternative_email?.slice(1).some((email: string) => email === '');
		return !person?.name || !person.email || !emailIsValid || hasEmptyPhoneNumber || hasEmptyAlternativeEmail;
	};

	async function onAddPerson(debtor: Person | null) {
		if (!debtor) {
			return;
		}
		client.debtors.push(debtor);
		const debtAccount = client.debtsAccounts.find((account) => account.code === debtAccountCode);

		if (!debtAccount) {
			return;
		}
		if (!debtAccount) {
			throw new Error('an error occurred while trying to save in code');
		}

		const newDebt: Debt = {
			date: new Date(),
			documents: [],
			baseAmount: 0,
			interestRate: 0,
			otherCharges: 0,
			debtor,
			totalAmount: 0,
			//scheduledPayments: [],
			installmentCount: 0,
			agreement,
			acceptanceStatus: 'draft',
			contractSent: new Date(),
			dueDate: moment().add(30, 'days').toDate(),
			scheduledPayments: [],
			balance: 0,
		};

		try {
			if (client._id) {
				const debtAdd = await addDebtorsToDebtAccount(client._id, debtAccountCode, newDebt);
				debtAccount.debts = debtAdd.debts;
				onClose();
				onPersonAdded();
			}
		} catch (error) {
			console.warn(error);
		}
	}

	function addPerson(person: Person) {
		setAddingPerson(false);
		onAddPerson(person);
	}

	return (
		<>
			<PersonAddModal addPerson={addPerson} open={addingPerson} onClose={() => setAddingPerson(false)} type={type} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					width: '100%',
					paddingRight: 2,
				}}
			>
				<Add
					onClick={() =>
						setPerson({
							email: '',
							name: '',
							alternative_email: [''],
							phone_number: [''],
							identifier: nanoid(),
							type,
						})
					}
					sx={{ cursor: 'pointer' }}
				/>

				<SearchIcon onClick={() => setAddingPerson(true)} sx={{ cursor: 'pointer' }} />
			</Box>
			<PersonEditModal
				editPerson={person}
				onClose={onClose}
				open={!!person}
				type={type}
				updatePerson={updatePersonKey}
				emailIsValid={emailIsValid}
				setEmailIsValid={setEmailIsValid}
				isButtonDisabled={isButtonDisabled}
				onSave={onAddPerson}
			>
				<PersonElementFields
					editPerson={person}
					setEditPerson={setPerson}
					label={t('common:phoneNumber')}
					identifier="phone_number"
					setAlternativeEmailIsValid={null}
					alternativeEmailIsValid={alternativeEmailIsValid}
					setPhoneNumberIsValid={setPhoneNumberIsValid}
					phoneNumberIsValid={phoneNumberIsValid}
				/>

				<PersonElementFields
					editPerson={person}
					setEditPerson={setPerson}
					label={t('common:alternativeEmail')}
					identifier="alternative_email"
					setAlternativeEmailIsValid={setAlternativeEmailIsValid}
					alternativeEmailIsValid={alternativeEmailIsValid}
					setPhoneNumberIsValid={null}
					phoneNumberIsValid={phoneNumberIsValid}
				/>
			</PersonEditModal>
		</>
	);
}

export default DebtsAdd;
