import useIsLogged from './useIsLogged';
import useRules from './useRules';

function useLoggedAndRules() {
	const logged = useIsLogged();
	const rules = useRules();

	return { logged, rules };
}

export default useLoggedAndRules;
