import useDebtAccounts from './useDebtAccounts';
import { useEffect, useState } from 'react';

export type UpdateDebtAccount = ReturnType<typeof useDebtsAccount>['update'];

function useDebtsAccount(code: string) {
	const accountsData = useDebtAccounts();

	const [originalData, setOriginalData] = useState<DebtsAccount | undefined>();
	const [debtsAccount, setDebtsAccount] = useState<DebtsAccount | undefined>();

	useEffect(() => {
		const found = accountsData.accounts.find((account) => account.code === code);
		setDebtsAccount(found);
		setOriginalData(found);
	}, [accountsData.accounts, code]);

	function update<KEY extends keyof DebtsAccount['debts'][number]>(
		index: number,
		key: KEY,
		value: DebtsAccount['debts'][number][KEY]
	) {
		if (debtsAccount) {
			const data = {
				...debtsAccount,
				debts: debtsAccount.debts.map((debt, currentIndex) => {
					return currentIndex === index
						? {
								...debt,
								[key]: value,
						  }
						: debt;
				}),
			};
			setDebtsAccount(data);
		}
	}

	return {
		loading: accountsData.loading,
		error: accountsData.error,
		refetch: accountsData.refetch,
		debtsAccount,
		originalData,
		update,
	};
}

export default useDebtsAccount;
