import { Box, Grid, Typography, Button } from '@mui/material';
import moment, { Moment } from 'moment';

import { useTranslation } from 'react-i18next';
import PaymentsTable from './PaymentsTable';
import { useState } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';

type Props = {
	index: number;
	generate: (index: number, debt: Debt, updated: boolean) => any;
	editing: boolean;
	debt: Debt;
	disabled: boolean;
	uploadFile: (debtId: string, file: File, index: number) => any;
};

function PaymentPlan({ index, debt, generate, disabled, uploadFile }: Props) {
	const { t } = useTranslation();

	const [showWarning, setShowWarning] = useState(false);

	const payments = debt.scheduledPayments || [];

	const { allDates } = getPayments(debt);

	function onGenerate() {
		if (debt.scheduledPayments && debt.scheduledPayments.length) {
			setShowWarning(true);
		} else {
			generate(index, debt, false);
		}
	}

	function onGenerateCallback(confirmed: boolean) {
		setShowWarning(false);
		if (confirmed) {
			generate(index, debt, false);
		}
	}

	const modGenerate = (debt: Debt, updated: boolean) => generate(index, debt, true);

	return (
		<Grid container spacing={2} sx={{ mt: 2, p: 2 }}>
			{showWarning && (
				<ConfirmationDialog
					onClose={onGenerateCallback}
					title={t('clients:generatePlanTitleWarning')}
					description={t('clients:generatePlanDescriptionWarning')}
				/>
			)}
			<Grid xs={12}>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<Typography variant="h6">{t('common:paymentsAgreement')}</Typography>
				</Box>

				<Button variant="contained" sx={{ mt: 1 }} disabled={disabled} onClick={onGenerate}>
					{t(
						debt.scheduledPayments && debt.scheduledPayments.length > 0
							? 'clients:regeneratePlan'
							: 'clients:generatePlan',
					)}
				</Button>

				<Typography variant="body2" sx={{ mt: 1 }}>
					{t(
						debt.scheduledPayments && debt.scheduledPayments.length > 0
							? 'clients:regeneratePlanDescription'
							: 'clients:generatePlanDescription',
					)}
				</Typography>

				{/* FIRST SIX MONTHS */}
				{payments.length > 0 &&
					allDates.map((dates, index) => {
						return (
							<PaymentsTable
								uploadFile={uploadFile}
								debt={debt}
								dates={dates}
								allDates={allDates}
								key={`dates_${index}`}
								indexArr={index}
								generate={modGenerate}
							/>
						);
					})}
			</Grid>
		</Grid>
	);
}

export default PaymentPlan;

export function getPayments(debt: Debt) {
	const firstDate = debt.dueDate ? moment(debt.dueDate) : debt.date ? moment(debt.date).add(30, 'days') : moment();
	const allDates: Moment[][] = [];

	for (let i = 0; i < debt.installmentCount; i++) {
		const currentIndex = Math.floor(i / 4);
		if (!allDates[currentIndex]) {
			allDates[currentIndex] = [];
		}

		allDates[currentIndex].push(moment(firstDate).add(i, 'month'));
	}

	return { firstDate, allDates };
}
