import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, Fab, Input } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

type UploadInputProps = {
	fileName: string;
	onChange(event: React.ChangeEvent<HTMLInputElement>): void;
	id: string;
	name: string;
	disabled?: boolean;
	actionDisabled?: boolean;
	autoFocus?: boolean;
	accept?: string;
};

const UploadInput = ({
	id,
	name,
	fileName,
	onChange,
	disabled,
	actionDisabled,
	autoFocus,
	accept,
}: UploadInputProps) => {
	const { classes } = useStyles();
	return (
		<label htmlFor={id} className={classes.inputContainer}>
			<input
				className={classes.inputHide}
				id={id}
				name={name}
				type="file"
				onChange={onChange}
				disabled={disabled}
				autoFocus={autoFocus}
				accept={accept}
			/>
			<Box>
				<Fab
					sx={{ m: 2 }}
					color="secondary"
					size="small"
					component="span"
					aria-label="add"
					variant="circular"
					disabled={actionDisabled}
				>
					<AddIcon />
				</Fab>
				<Input className={classes.input} value={fileName} margin="dense" id="name" disabled={true} />
			</Box>
		</label>
	);
};
const useStyles = makeStyles()((theme) => ({
	inputContainer: {
		flex: 1,
		display: 'flex',
	},
	input: {
		width: '75%',
		maxWidth: theme.spacing(80),
		margin: theme.spacing(2),
		[theme.breakpoints.up('md')]: {
			minWidth: theme.spacing(50),
		},
		[theme.breakpoints.down('md')]: {
			minWidth: theme.spacing(15),
		},
	},
	inputHide: {
		display: 'none',
	},
}));

export default UploadInput;
