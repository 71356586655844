import { Box, IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { validateEmail } from 'lib/validateEmail';

import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

const MAX_EMAIL_LENGTH = 320;
const MAX_FIELD_LENGTH = 30;

const PersonElementFields = ({
	editPerson,
	setEditPerson,
	label,
	identifier,
	setAlternativeEmailIsValid,
	alternativeEmailIsValid,
	setPhoneNumberIsValid,
	phoneNumberIsValid,
}: {
	editPerson: Person | null;
	setEditPerson: Dispatch<SetStateAction<Person | null>>;
	label: string;
	identifier: keyof Person;
	setAlternativeEmailIsValid: Dispatch<SetStateAction<boolean>> | null;
	alternativeEmailIsValid: boolean;
	setPhoneNumberIsValid: Dispatch<SetStateAction<boolean>> | null;
	phoneNumberIsValid: boolean;
}) => {
	const { t } = useTranslation();
	const [emailValidities, setEmailValidities] = useState<boolean[]>(
		editPerson ? editPerson[identifier]?.map(() => true) || [] : [],
	);
	const [phoneValidities, setPhoneValidities] = useState<boolean[]>(
		editPerson ? editPerson[identifier]?.map(() => true) || [] : [],
	);

	const updateValiditiesAndCheck = (
		newElementValidities: boolean[],
		setValidities: Dispatch<SetStateAction<boolean[]>>,
		element: string,
	) => {
		setValidities([...newElementValidities]);

		if (setAlternativeEmailIsValid !== null) {
			setAlternativeEmailIsValid(
				newElementValidities.includes(false) && newElementValidities.length > 1
					? false
					: element.length < 1 || newElementValidities[0] === true
					  ? true
					  : false,
			);
		} else if (setPhoneNumberIsValid !== null) {
			setPhoneNumberIsValid(
				newElementValidities.includes(false) && newElementValidities.length > 1
					? false
					: element.length < 1 || newElementValidities[0] === true
					  ? true
					  : false,
			);
		}
	};

	const handleElementChange = (index: number, value: string) => {
		setEditPerson((prevEditPerson) => {
			if (!prevEditPerson) {
				return null;
			}

			const updatedIdentifier = [...(prevEditPerson[identifier] || [])];
			updatedIdentifier[index] = value;

			if (identifier === 'alternative_email') {
				const newEmailValidities = [...emailValidities];
				newEmailValidities[index] = validateEmail(value) && value.length <= MAX_EMAIL_LENGTH;
				updateValiditiesAndCheck(newEmailValidities, setEmailValidities, updatedIdentifier[0]);
			} else if (identifier === 'phone_number') {
				const newPhoneValidities = [...phoneValidities];
				newPhoneValidities[index] = value !== '' ? true : false;
				updateValiditiesAndCheck(newPhoneValidities, setPhoneValidities, updatedIdentifier[0]);
			}

			return {
				...prevEditPerson,
				[identifier]: updatedIdentifier,
			};
		});
	};

	const addElementField = () => {
		setEditPerson((prevEditPerson) => {
			if (!prevEditPerson) {
				return null;
			}

			const updatedIdentifier = [...(prevEditPerson[identifier] || []), ''];

			if (identifier === 'phone_number') {
				//setPhoneValidities((prevPhoneValidities) => [...prevPhoneValidities, false]);
				updateValiditiesAndCheck([...phoneValidities, false], setPhoneValidities, updatedIdentifier[0]);
			} else if (identifier === 'alternative_email') {
				//setEmailValidities((prevEmailValidities) => [...prevEmailValidities, false]);

				updateValiditiesAndCheck([...emailValidities, false], setEmailValidities, updatedIdentifier[0]);
			}

			return {
				...prevEditPerson,
				[identifier]: updatedIdentifier,
			};
		});
	};

	const removeElementField = (index: number) => {
		setEditPerson((prevEditPerson) => {
			if (!prevEditPerson) {
				return null;
			}

			const updatedIdentifier = [...(prevEditPerson[identifier] || [])];
			updatedIdentifier.splice(index, 1);

			if (identifier === 'alternative_email') {
				const newEmailValidities = [...emailValidities];
				newEmailValidities.splice(index, 1);
				updateValiditiesAndCheck(newEmailValidities, setEmailValidities, updatedIdentifier[0]);
			} else if (identifier === 'phone_number') {
				const newPhoneValidities = [...phoneValidities];
				newPhoneValidities.splice(index, 1);
				updateValiditiesAndCheck(newPhoneValidities, setPhoneValidities, updatedIdentifier[0]);
			}

			return {
				...prevEditPerson,
				[identifier]: updatedIdentifier,
			};
		});
	};

	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<IconButton color="primary" onClick={addElementField} sx={{ mb: 1, ml: 1 }}>
					<AddIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					borderWidth: 2,
					borderRadius: 4,
					minHeight: 50,
					maxHeight: 400,
					width: '100%',
					mb: 2,
					py: 2,
					px: 2,
					backgroundColor: '#f0f0f0',
					borderColor: '#d3d3d3',
					borderStyle: 'solid',
					overflowY: 'auto',
				}}
			>
				{editPerson &&
					editPerson[identifier] &&
					editPerson[identifier].map((element: string, index: number) => (
						<Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
							{identifier === 'phone_number' ? (
								<TextField
									label={`${label} ${index + 1}`}
									fullWidth
									variant="outlined"
									value={element}
									inputProps={{ maxLength: MAX_FIELD_LENGTH }}
									error={!phoneValidities[index] && !phoneNumberIsValid}
									helperText={!phoneValidities[index] && !phoneNumberIsValid ? t('system:emptyField') : ''}
									sx={{ mb: 1, backgroundColor: 'white' }}
									onChange={(e) => handleElementChange(index, e.target.value)}
								/>
							) : (
								<TextField
									label={`${label} ${index + 1}`}
									fullWidth
									variant="outlined"
									value={element}
									sx={{ mb: 1, backgroundColor: 'white' }}
									error={!emailValidities[index] && !alternativeEmailIsValid}
									helperText={!emailValidities[index] && !alternativeEmailIsValid ? t('system:errorEmail') : ''}
									onChange={(e) => handleElementChange(index, e.target.value)}
								/>
							)}
							{editPerson[identifier] && editPerson[identifier].length > 1 ? (
								<IconButton color="error" onClick={() => removeElementField(index)}>
									<DeleteIcon />
								</IconButton>
							) : (
								<IconButton color="error" onClick={() => removeElementField(index)} disabled={true}>
									<DeleteIcon />
								</IconButton>
							)}
						</Box>
					))}
			</Box>
		</>
	);
};

export default PersonElementFields;
