import { Box, Button, Dialog, DialogContent, Grid, IconButton, TableCell, TextField, Typography } from '@mui/material';
import { currency } from 'lib/helpers';
import { ChangeEvent, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';
import Edit from '@mui/icons-material/Edit';
import { fetchFile } from 'lib/models/clients';

type Props = {
	index: number;
	indexArr: number;
	lengthArr: number;
	debt: Debt;
	allDates: Moment[][];
	generate: (debt: Debt, updated: boolean) => any;
	handleFileChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => any;
};
function MonthCell({ index, debt, generate, indexArr, lengthArr, allDates, handleFileChange }: Props) {
	const { t } = useTranslation();

	const payments = debt.scheduledPayments || [];
	const positionPayments = calculateAbsolutePosition();
	const [isFileNull, setIsFileNull] = useState<string | null>(payments[positionPayments]?.file);
	const [amount, setAmount] = useState<number>(parseFloat((payments[positionPayments]?.amount ?? 0).toFixed(2)));
	const [amountPaid, setAmountPaid] = useState<number>(
		parseFloat((payments[positionPayments]?.amountPaid ?? 0).toFixed(2)),
	);

	const [open, setOpen] = useState(false);
	const [showFile, setShowFile] = useState<string | null>();
	const [fileExtension, setFileExtension] = useState<string | undefined>('unknown');

	const [isEditing, setIsEditing] = useState(false);
	const [isEditingPaid, setIsEditingPaid] = useState(false);

	const paid = amountPaid > amount;
	const exact = amountPaid === amount;
	const fileInputRef = useRef<any>(null);

	const handleClickOpen = async (file: string | null) => {
		try {
			if (file === null) {
				return;
			}
			const fileResponse = await fetchFile(file);
			setShowFile(fileResponse?.file);
			//setFileExtension(detectFileExtension(file));
			setFileExtension(fileResponse?.type);
			setOpen(true);
		} catch (error) {
			console.warn(error);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleButtonClick = () => {
		if (fileInputRef.current) fileInputRef.current.click();
	};

	const handleTypographyClick = () => setIsEditing(true);

	function calculateAbsolutePosition() {
		/*
			Calculates the appropriate position within payments, 
			taking the number of subarray taking into account that payments was divided into allDates, 
			the position of the element and the size of the subarray. 
			This is done because if payments is odd it is difficult to calculate the position and with this we solve it.
		*/
		return indexArr < 1
			? index
			: indexArr === allDates.length - 1
			  ? allDates.slice(0, indexArr).reduce((acc, curr) => acc + curr.length, 0) + index
			  : indexArr * lengthArr + index;
	}

	const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = parseFloat(event.target.value ? event.target.value : '0');
		setAmount(newValue);
	};

	const handleTextFieldBlur = () => {
		if (payments[positionPayments].amount) {
			parseFloat((payments[positionPayments].amount = amount).toFixed(2));
			generate(debt, true);
		}

		setIsEditing(false);
	};

	const handleCancelClick = () => {
		setAmount(parseFloat(payments[positionPayments].amount.toFixed(2)));
		setIsEditing(false);
	};

	const handleAmountPaidTypographyClick = () => {
		setIsEditingPaid(true);
	};

	const handleAmountPaidChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = parseFloat(event.target.value ? event.target.value : '0');
		setAmountPaid(newValue);
	};

	const handleAmountPaidTextFieldBlur = () => {
		payments[positionPayments].amountPaid = amountPaid;
		generate(debt, true);
		setIsEditingPaid(false);
	};

	const handleAmountPaidCancelClick = () => {
		setAmountPaid(parseFloat(payments[positionPayments].amountPaid.toFixed(2)));
		setIsEditingPaid(false);
	};

	const onUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
		try {
			const response = await handleFileChange(event, positionPayments);
			setIsFileNull(response.file);
			setFileExtension(response.extension);
		} catch (err) {
			console.warn(err);
		}
	};

	const renderInputBlock = () => (
		<Box sx={{ display: 'flex' }}>
			<TextField
				type="number"
				value={amountPaid}
				onChange={handleAmountPaidChange}
				sx={{
					width: '15ch',
					padding: '0px',
					mt: 1,
				}}
				inputProps={{
					max: amount,
					min: 0,
				}}
			/>
			<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 1 }}>
				<IconButton
					onClick={handleAmountPaidCancelClick}
					sx={{ padding: 0, fontSize: 16, '&:hover': { backgroundColor: 'transparent' } }}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
				<IconButton
					onClick={handleAmountPaidTextFieldBlur}
					sx={{ padding: 0, fontSize: 16, '&:hover': { backgroundColor: 'transparent' } }}
				>
					<SaveIcon fontSize="small" />
				</IconButton>
			</Box>
		</Box>
	);

	return (
		<>
			<TableCell>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					{isEditing ? (
						<Box sx={{ display: 'flex' }}>
							<TextField
								type="number"
								value={amount}
								onChange={handleTextFieldChange}
								inputProps={{
									min: 0,
								}}
								sx={{
									width: '15ch',
									padding: '0px',
								}}
							/>
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 1 }}>
								<IconButton
									onClick={handleCancelClick}
									sx={{ padding: 0, fontSize: 16, '&:hover': { backgroundColor: 'transparent' } }}
								>
									<CloseIcon fontSize="small" />
								</IconButton>
								<IconButton
									onClick={handleTextFieldBlur}
									sx={{ padding: 0, fontSize: 16, '&:hover': { backgroundColor: 'transparent' } }}
								>
									<SaveIcon fontSize="small" />
								</IconButton>
							</Box>
						</Box>
					) : (
						<Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
							{currency(amount)}
							<IconButton
								onClick={handleTypographyClick}
								sx={{ padding: 0, fontSize: 16, '&:hover': { backgroundColor: 'transparent' } }}
							>
								<Edit fontSize="small" />
							</IconButton>
						</Typography>
					)}
					<div style={{ marginTop: '8px', marginBottom: '8px' }}>
						{isEditingPaid ? (
							renderInputBlock()
						) : (
							<Typography
								variant="body2"
								color="white"
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									backgroundColor: exact || paid ? 'green' : 'red',
									padding: 1,
									borderRadius: 1,
								}}
							>
								{exact || paid
									? t('common:paid')
									: amountPaid === 0
									  ? t('common:unpaid')
									  : t('common:unpaid') +
									    `
					(${currency(amount - amountPaid)})`}
								<IconButton
									onClick={handleAmountPaidTypographyClick}
									sx={{ padding: 0, fontSize: 16, '&:hover': { backgroundColor: 'transparent' } }}
								>
									<Edit fontSize="small" sx={{ color: 'white' }} />
								</IconButton>
							</Typography>
						)}
					</div>
					<Grid container spacing={2}>
						<Grid item>
							{isFileNull === null ? (
								<Grid container spacing={1}>
									<Grid item>
										<Button variant="contained" color="primary" size="small" onClick={handleButtonClick}>
											{t('common:uploadReceipt')}
										</Button>
									</Grid>
								</Grid>
							) : (
								<Grid container spacing={1}>
									<Grid item>
										<Button variant="contained" color="secondary" size="small" onClick={handleButtonClick}>
											{t('common:replace')}
										</Button>
									</Grid>
									<Grid item>
										<Button variant="contained" size="small" onClick={() => handleClickOpen(isFileNull)}>
											{t('common:view')}
										</Button>
									</Grid>
								</Grid>
							)}
						</Grid>

						<input
							ref={fileInputRef}
							id={`fileInput_${positionPayments}`}
							type="file"
							accept=".pdf, .jpg, .png"
							style={{ display: 'none' }}
							onChange={(event) => onUploadFile(event)}
						/>
					</Grid>
				</Box>
			</TableCell>
			<Dialog open={open} onClose={handleClose} fullWidth>
				<DialogContent>
					{fileExtension === 'png' || fileExtension === 'jpg' ? (
						<img
							src={showFile !== null && showFile !== undefined ? `data:image/${fileExtension};base64,${showFile}` : ''}
							style={{ width: '100%' }}
							alt="imagen"
						/>
					) : (
						showFile !== null &&
						showFile !== undefined && (
							// eslint-disable-next-line jsx-a11y/iframe-has-title
							<iframe
								src={`data:application/${fileExtension};base64,${showFile}`}
								width="100%"
								height="800px"
								style={{ overflow: 'auto' }}
								allowFullScreen
								frameBorder="0"
							></iframe>
						)
					)}
				</DialogContent>
			</Dialog>
		</>
	);
}

export default MonthCell;
