import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, FormGroup, LinearProgress, Box } from '@mui/material';
import { Save as SaveIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { deleteClient, updateCLient } from 'lib/models/clients';
import { enqueueSnackbarError } from 'lib/helpers';

import { CLIENTS_DELETION_ENABLED } from 'config/constants';
import { useLocation } from 'react-router-dom';

import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

import ConfirmationDialog from 'components/ConfirmationDialog';
import ImportIcon from 'components/icons/ImportIcon';
import Uploader from 'components/Uploader';

import MainForm from './MainForm';
// import DebtCollectionForm from './DebtCollectionForm';

import { importData } from 'lib/models/clients';

type Props = {
	client: Client;
};

function ClientEditionForm({ client }: Props) {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();

	const [loading, setLoading] = useState<boolean>(false);
	const [data, setClientData] = useState<Client>(client);
	const [importing, toggleImport] = useState(false);

	const [confirmClientDelete, setDeleteClient] = useState(false);

	const canSave = !!data.identifier && !!data.companyName;
	const url = location.pathname;

	const onUpdate = (key: keyof Client) => {
		return (event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;

			if (data) {
				setClientData({ ...data, [key]: value });
			}
		};
	};

	const save = async () => {
		try {
			setLoading(true);
			await updateCLient(data);
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error, errorParser);
		}
		setLoading(false);
		if (url === '/clients/new') {
			navigate('/clients');
		}
	};

	function toggleDeleteUser() {
		setDeleteClient(true);
	}

	async function onDeleteConfirm(confirmed: boolean) {
		try {
			setDeleteClient(false);
			if (confirmed) {
				setLoading(true);
				await deleteClient(data);
				navigate('/clients');
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	}

	return (
		<Box>
			<ImportIcon onImport={() => toggleImport(true)} />

			<Uploader
				id="clients-file"
				name="clients-file"
				accept=".csv"
				onCancel={() => toggleImport(false)}
				onEnded={(_) => toggleImport(false)}
				openDialog={importing}
				importData={importData}
				warning={t('clients:warningUploader')}
				exampleFile={'clients-example.csv'}
				errorKey="identifier"
				errorKeyName="identifier"
			/>

			{loading && <LinearProgress sx={{ mb: 3 }} />}

			<FormGroup>
				<MainForm data={data} onUpdate={onUpdate} />

				<Button
					variant="contained"
					color="primary"
					size="large"
					sx={{ m: 1 }}
					startIcon={<SaveIcon />}
					onClick={save}
					disabled={!canSave}
				>
					{t('common:save')}
				</Button>

				{CLIENTS_DELETION_ENABLED && !!data._id && (
					<Button
						variant="contained"
						color="secondary"
						size="large"
						sx={{ m: 1 }}
						startIcon={<DeleteIcon />}
						onClick={toggleDeleteUser}
					>
						{t('common:delete')}
					</Button>
				)}
			</FormGroup>

			{confirmClientDelete && (
				<ConfirmationDialog
					title={t('common:deleteTitle')}
					description={t('common:deleteText') || ''}
					onClose={onDeleteConfirm}
					loading={loading}
				/>
			)}
		</Box>
	);
}

export default ClientEditionForm;

/**
 * Returns an error parser method that will receive a string and return a custom label if the string "the identifier received" is present
 */
function useErrorParser() {
	const { t } = useTranslation();

	const parser = useCallback(
		/**
		 * An error parser method that will receive a string and return a custom label if the string "the identifier received" is present
		 */
		(message: string) => {
			return message.includes('the identifier received ') ? t('clients:identificationErrorMessage') : message;
		},
		[t],
	);

	return parser;
}
