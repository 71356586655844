import { Box } from '@mui/material';

type Props = { phone?: string };

function Phone({ phone }: Props) {
	return phone ? (
		<Box>
			<a href={`tel:${phone}`}>{phone}</a> (
			<a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=${phone}`}>
				Whatsapp
			</a>
			)
		</Box>
	) : null;
}

export default Phone;
