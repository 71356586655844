import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';

import ClientEditionForm from 'components/ClientEditionForm';
import { CircularProgress, Tabs, Tab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useClient from 'hooks/useClient';
import ClientDebtsAccountsTable from 'components/ClientDebtsAccountsTable/ClientDebtsAccountsTable';
import ClientDebtsCollectionForm from 'components/ClientDebtsCollectionForm';

import useURLParam from 'hooks/useURLParam';

function ClientEdition() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const clientData = useClient();

	const [tab, setSearchParams] = useURLParam(isQuery, '0', 'tab');

	const [activeTab, setActiveTab] = useState(0);

	const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
		setActiveTab(newValue);
		setSearchParams({ tab: `${newValue}` });
	};

	const title =
		clientData.loading || clientData.error
			? t('common:loading')
			: t('common:clientTitle', { name: clientData.client.companyName || clientData.client.identifier });

	useEffect(() => {
		if (!!clientData.error) {
			navigate('/clients');
		}
	}, [navigate, clientData.error]);

	useEffect(() => {
		if (!!clientData.loading) {
			setActiveTab(Number(tab));
			/*!clientData.loading
			for some reason when opening the contact search accordion, this produces an error and causes it to return to the customers */
		}
	}, [clientData.loading, tab]);

	return (
		<ScreenContainer title={title} goBack={true}>
			<Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
				<Tab label={t('common:client')} />
				{clientData.client._id !== '' && <Tab label={t('clients:debtCollectionTitle')} />}
				{clientData.client._id !== '' && <Tab label={t('clients:invoiceTitle')} />}
				{clientData.client._id !== '' && <Tab label={t('clients:paymentPlanTitle')} />}
			</Tabs>

			{!!clientData.loading && <CircularProgress />}
			{!!clientData.error && <Typography>{t('common:loadError')}</Typography>}

			{!clientData.loading && !clientData.error && activeTab === 0 && <ClientEditionForm client={clientData.client} />}
			{!clientData.loading && !clientData.error && activeTab === 1 && (
				<ClientDebtsCollectionForm client={clientData.client} />
			)}

			{!clientData.loading && !clientData.error && activeTab === 2 && (
				<ClientDebtsAccountsTable
					actions={true}
					client={clientData.client}
					dashboard={false}
					refetch={clientData.refetch}
					agreement="invoice"
				/>
			)}

			{!clientData.loading && !clientData.error && activeTab === 3 && (
				<ClientDebtsAccountsTable
					actions={true}
					client={clientData.client}
					dashboard={false}
					refetch={clientData.refetch}
					agreement="payment_plan"
				/>
			)}
		</ScreenContainer>
	);
}

export default ClientEdition;

function isQuery(data: any): data is string {
	return typeof data === 'string';
}
