import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { enqueueSnackbarSuccess } from 'lib/helpers';

function useSuccessSnackbar(messageConfirmation?: boolean) {
	const { t } = useTranslation();

	const toast = useCallback(() => {
		if(messageConfirmation){
			enqueueSnackbarSuccess(t('common:confirmSuccess'));
		}else{
		enqueueSnackbarSuccess(t('common:success'));
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t]);

	return toast;
}

export default useSuccessSnackbar;
