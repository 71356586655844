import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: DrawerState = {
	open: true,
	selectedItem: 0,
};

const drawerStateSlice = createSlice({
	name: 'drawerState',
	initialState,
	reducers: {
		toggleDrawer(state) {
			state.open = !state.open;
		},
		handleDrawerOpen(state) {
			state.open = true;
		},
		handleDrawerClose(state) {
			state.open = false;
		},
		setSelectedItem(state, action: PayloadAction<number>) {
			state.selectedItem = action.payload;
		},
	},
});

export const { toggleDrawer, handleDrawerOpen, handleDrawerClose, setSelectedItem } = drawerStateSlice.actions;

export default drawerStateSlice.reducer;
