import { useTranslation } from 'react-i18next';

import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';

import Phone from 'components/Phone';
// import Email from 'components/Email';
import DebtDate from './DebtDate';

type Props = {
	client: Client;
	onEdit: (client: Client) => any;
};

function MobileItem({ client, onEdit }: Props) {
	const { t } = useTranslation();

	return (
		<Accordion defaultExpanded={false} variant="outlined">
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="DebtCollection-content"
				id="DebtCollection-header"
			>
				<Typography>{client.companyName}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: 8,
						justifyContent: 'space-between',
						alignItems: 'center',
						pt: 8,
					}}
					onClick={() => onEdit(client)}
				>
					{t('common:identifier')}: {client.identifier} <EditIcon />
				</Box>

				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('common:address')}: {client.address && `${client.address} ${client.city ? `, ${client.city}` : ''}`}
				</Box>

				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('common:city')}: {client.city && <Box>{client.city}</Box>}
				</Box>
				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('common:phone')}: <Phone phone={client.phone} />
				</Box>
				{/* <Box sx={{mt: 1, verticalAlign: 'center'}}>
					{t('clients:debtCollectionContact')}: <Email email={client.debtCollectionInformation?.contactPerson?.email} />
				</Box> */}
				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('clients:collectionDays')}: <DebtDate date={client.debtCollectionInformation?.collectionDays} />
				</Box>
				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('clients:paymentDays')}: <DebtDate date={client.debtCollectionInformation?.paymentDays} />
				</Box>
				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('common:users')}: {(client.users || []).length}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}

export default MobileItem;
