import { useTranslation } from 'react-i18next';

import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';

import Phone from 'components/Phone';
import Email from 'components/Email';

type Props = {
	person: Person;
	onEdit: (person: Person) => any;
};

function MobileItem({ person, onEdit }: Props) {
	const { t } = useTranslation();

	return (
		<Accordion defaultExpanded={false} variant="outlined">
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="DebtCollection-content"
				id="DebtCollection-header"
			>
				<Typography>{person.name}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: 8,
						justifyContent: 'space-between',
						alignItems: 'center',
						pt: 8,
					}}
					onClick={() => onEdit(person)}
				>
					<EditIcon />
				</Box>

				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('common:email')}: <Email email={person.email} />
				</Box>

				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('common:phone')}: <Phone phone={person.phone_number?.[0] ?? ''} />
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}

export default MobileItem;
