import { Box } from '@mui/material';

import ContactsAccordion, { ContactsProps } from './ContactsAccordion';
import DebtCollectionAccordion from './DebtCollectionAccordion';
import DatesAccordion from './DatesAccordion';

type Props = {
	onUpdate: (key: keyof Client['debtCollectionInformation']) => (event: any) => void;
	data: Client;
} & ContactsProps;

function DebtCollectionAccordions(props: Props) {
	return (
		<Box>
			<DebtCollectionAccordion {...props} />

			<DatesAccordion {...props} />

			<ContactsAccordion {...props} tab={'accordion'} />
		</Box>
	);
}

export default DebtCollectionAccordions;
