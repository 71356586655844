import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type Props = {
	onPersonUpdate?: (person: Person) => void;
	setShowModalNotes: Dispatch<SetStateAction<boolean>>;
	showModalNotes: boolean;
	userPerson: Person | null;
	noteIndex: number | null;
	type: string;
};

const DebtorNotes = ({ onPersonUpdate, setShowModalNotes, showModalNotes, userPerson, noteIndex, type }: Props) => {
	const { t } = useTranslation();

	const [noteToSave, setNoteToSave] = useState<string>('');

	const handleSaveEdit = () => {
		try {
			if (userPerson && userPerson.notes && noteIndex !== null && onPersonUpdate) {
				const updatedNotesList = [...userPerson.notes];
				updatedNotesList[noteIndex] = noteToSave;
				const reviewUser = { ...userPerson, notes: updatedNotesList };
				onPersonUpdate(reviewUser);
				setNoteToSave('');
			}
		} catch (err) {
			console.warn(err);
		}
	};

	const addNote = () => {
		try {
			if (userPerson && userPerson.notes && onPersonUpdate) {
				const updatedNotesList = [...userPerson.notes, noteToSave];
				const reviewUser = { ...userPerson, notes: updatedNotesList };
				onPersonUpdate(reviewUser);
				setNoteToSave('');
			}
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		if (type === 'save') {
			setNoteToSave('');
		} else if (noteIndex && userPerson?.notes) {
			setNoteToSave(userPerson.notes[noteIndex] || '');
		}
	}, [type, noteIndex, userPerson]);

	return (
		<Modal
			// onClose={() => setEditPerson(null)}
			open={showModalNotes}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '90%',
					bgcolor: 'background.paper',
					border: '2px solid lightgrey',
					boxShadow: 24,
					p: 4,
					overflowY: 'auto',
					maxHeight: '90vh',
				}}
			>
				<Typography variant="h4" gutterBottom>
					{t(`common:notes`)}
				</Typography>

				<Box
					sx={{
						borderWidth: 2,
						borderRadius: 4,
						minHeight: 50,
						maxHeight: 400,
						width: '100%',
						mb: 2,
						py: 2,
						px: 2,
						backgroundColor: '#f0f0f0',
						borderColor: '#d3d3d3',
						borderStyle: 'solid',
						overflowY: 'auto',
					}}
				>
					{onPersonUpdate && (
						<TextField
							id="note-text"
							label={t(`common:labelNotes`)}
							multiline
							fullWidth
							rows={5}
							value={noteToSave}
							onChange={(e) => setNoteToSave(e.target.value)}
							sx={{ backgroundColor: '#fff' }}
						/>
					)}
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 2 }}>
					{onPersonUpdate &&
						(type === 'save' ? (
							<Button onClick={() => addNote()} variant="outlined">
								{t(`common:save`)}
							</Button>
						) : (
							<Button onClick={handleSaveEdit} variant="outlined">
								{t(`common:edit`)}
							</Button>
						))}

					<Button
						onClick={() => {
							setShowModalNotes(false);
							setNoteToSave('');
						}}
						variant="outlined"
						color="error"
					>
						{t(`common:cancel`)}
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default DebtorNotes;
