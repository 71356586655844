import { API_DOMAIN, CLIENTS_URL } from 'config/constants';
import { api, importDataRequest } from 'lib/api';
import { checkStatus, getSearch, isPaginateSource } from 'lib/helpers';

export type GetClientsResponse = PaginateSource<Client>;

export type FetchClientSearchProps = {
	search?: string | null;
};

export type FetchClientsProps = FetchClientSearchProps & PaginationProps;

export async function fetchClients(props: FetchClientsProps): Promise<PaginateSource<Client>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'companyName']: props.order && props.order === 'asc' ? 1 : -1,
		};
		const { filter, options } = getSearch(sort, props);

		const response = await api.get<PaginateSource<Client>>(`${API_DOMAIN}${CLIENTS_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!isPaginateSource(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function fetchScheduledPayments(props: FetchClientsProps): Promise<PaginateSource<TableScheduledPayment>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'code' || 'name']: props.order && props.order === 'asc' ? 1 : -1,
		};
		const { filter, options } = getSearch(sort, props);

		const response = await api.get<PaginateSource<TableScheduledPayment>>(`${API_DOMAIN}${CLIENTS_URL}/scheduled`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!isPaginateSource(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function fetchAccountsPayable(props: FetchClientsProps): Promise<PaginateSource<TableScheduledPayment>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'code']: props.order && props.order === 'asc' ? 1 : -1,
		};
		const { filter, options } = getSearch(sort, props);

		const response = await api.get<PaginateSource<TableScheduledPayment>>(
			`${API_DOMAIN}${CLIENTS_URL}/accounts/payable`,
			{
				params: { filter, options },
			}
		);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!isPaginateSource(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

type TableScheduledPaymentAgreement = TableScheduledPayment & {
	agreement: string;
	lastNotificationSent?: Date;
};

export type MonthAccumulated = {
	_id: string;
	code: string;
	description: string;
	total: number;
	agreement: Agreement;
};

export async function fetchAccountsDueToday(): Promise<TableScheduledPaymentAgreement[]> {
	try {
		const response = await api.get<TableScheduledPaymentAgreement[]>(`${API_DOMAIN}${CLIENTS_URL}/accounts/due-today`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!Array.isArray(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchAccountsDueMonth(): Promise<MonthAccumulated[]> {
	try {
		const response = await api.get<MonthAccumulated[]>(`${API_DOMAIN}${CLIENTS_URL}/accounts/due-month`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!Array.isArray(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchClient(id: string): Promise<Client | null> {
	try {
		const response = await api.get<Client | null>(`${API_DOMAIN}${CLIENTS_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function fetchFile(fileName: string): Promise<{ file: string; type: string } | null> {
	try {
		const response = await api.get<{ file: string; type: string }>(`${API_DOMAIN}${CLIENTS_URL}/files/${fileName}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		if (response.status === 200) {
			return response.data;
		} else {
			console.error('Error getting the file:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error getting the file:', error);
		return null;
	}
}

export async function updateCLient(client: Client): Promise<Client> {
	if (!client._id) {
		delete client._id;
	}

	const url = client._id ? `${API_DOMAIN}${CLIENTS_URL}/${client._id}` : `${API_DOMAIN}${CLIENTS_URL}`;

	const response = await (client._id ? api.patch<Client>(url, { client }) : api.post<Client>(url, { client }));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || client;
}

export async function addDebtAccount(
	clientId: string,
	debtAccount: {
		code: string;
		description: string;
	}
): Promise<DebtsAccount> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/${clientId}/debts`;

	const response = await api.patch<DebtsAccount>(url, {
		...debtAccount,
		debts: [],
	});

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}

export async function updateDebt(
	clientId: string,
	accountCode: string,
	debtId: string,
	updatedDebtData: Partial<Debt>
): Promise<Debt> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/${clientId}/account/${accountCode}/debt/${debtId}`;
	const response = await api.patch(url, updatedDebtData);
	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}
	return response.data;
}

export async function updateDebtWithFile(
	clientId: string,
	accountCode: string,
	debtId: string,
	file: File,
	month: number
): Promise<{ file: string; extension: string }> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/import/${clientId}/account/${accountCode}/debt/${debtId}/${month}`;

	const formData = new FormData();
	formData.append('file', file);

	const response = await api.post(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data;
}

export async function sendDebtAgreement(clientId: string, accountCode: string, debtId: string): Promise<Debt> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/${clientId}/account/${accountCode}/debt/${debtId}/send`;
	const response = await api.patch(url);
	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}
	return response.data;
}

export async function updateDebtAccount(
	clientId: string,
	accountId: string,
	updatedDebtAccount: {
		code: string;
		description: string;
	}
): Promise<DebtsAccount> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/${clientId}/account/${accountId}`;
	const response = await api.patch(url, updatedDebtAccount);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}
	return response.data;
}

export async function deleteDebtAccount(clientId: string, accountId: string): Promise<Boolean> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/${clientId}/account/${accountId}`;

	const response = await api.delete(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return true;
}

export async function addDebtorsToDebtAccount(
	clientId: string,
	debtAccountCode: string,
	newDebt: Debt
): Promise<DebtsAccount> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/${clientId}/account/${debtAccountCode}`;

	const response = await api.patch<DebtsAccount>(url, {
		...newDebt,
	});

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}

export async function deleteClient(client: Client): Promise<boolean> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/${client.identifier}`;
	const response = await api.delete(url);
	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}
	return true;
}

export function isClient(client: any): client is Client {
	return !!client && !!client.identifier && !!client.firstName && !!client.lastName && !!client.email;
}

export function importData(file: File): Promise<ParseCsvFileResult<Client>> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/import`;
	return importDataRequest(url, file);
}

export function importAccounts(
	clientId: string,
	agreement: Agreement,
	overwrite: boolean,
	file: File
): Promise<ParseCsvFileResult<Client>> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/import/${clientId}/agreement/${agreement}?overwrite=${overwrite}`;
	return importDataRequest(url, file);
}

export function importDebtors(
	clientId: string,
	accountCode: string,
	agreement: Agreement,
	overwrite: boolean,
	file: File
): Promise<ParseCsvFileResult<Client>> {
	const url = `${API_DOMAIN}${CLIENTS_URL}/import/${clientId}/account/${accountCode}/agreement/${agreement}?overwrite=${overwrite}`;
	return importDataRequest(url, file);
}
