import { Close, Edit, ReadMore, Save, FileCopy } from '@mui/icons-material';
import { Box, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { UpdateDebtAccount } from 'hooks/useDebtsAccount';
import { currency } from 'lib/helpers';
import { addDebtorsToDebtAccount } from 'lib/models/clients';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	debt: Debt;
	index: number;
	saving: boolean;
	editing: boolean;
	setEditing: Dispatch<SetStateAction<string | null>>;
	onUpdateDebt: (index: number, updatedDebt: Debt) => Promise<void>;
	update: UpdateDebtAccount;
	toggleDebtor: (current: Debt['debtor'] | null) => void;
	debtAccountCode: string;
	client: Client;
	onPersonAdded: () => void;
	handleClick: (message: string, color: string) => void;
};

function DebtData({
	debt,
	index,
	saving,
	editing,
	setEditing,
	onUpdateDebt,
	update,
	toggleDebtor,
	client,
	onPersonAdded,
	debtAccountCode,
	handleClick,
}: Props) {
	const { t } = useTranslation();

	async function onAddPerson() {
		if (!debt) {
			return;
		}
		client.debtors.push(debt.debtor);
		const debtAccount = client.debtsAccounts.find((account) => account.code === debtAccountCode);

		if (!debtAccount) {
			return;
		}
		if (!debtAccount) {
			throw new Error('an error occurred while trying to save in code');
		}

		const { _id, ...newDebt } = debt;

		try {
			if (client._id) {
				const debtAdd = await addDebtorsToDebtAccount(client._id, debtAccountCode, newDebt);
				debtAccount.debts = debtAdd.debts;
				onPersonAdded();
				handleClick(t('clients:debtAccountDuplicate'), 'success');
			}
		} catch (error) {
			console.warn(error);
			handleClick(t('system:requestError'), 'error');
		}
	}

	return (
		<Grid container spacing={2} sx={{ p: 2 }}>
			<Grid xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<Typography variant="h6">{t('common:debtData')}</Typography>
				{!editing && (
					<Box display="flex" flexDirection="column">
						<Tooltip title={t('common:duplicate')} arrow placement="top">
							<FileCopy sx={{ cursor: 'pointer', mb: 2 }} onClick={() => onAddPerson()} />
						</Tooltip>
						<Tooltip title={t('common:edit')} arrow>
							<Edit sx={{ cursor: 'pointer' }} onClick={() => setEditing(debt._id || null)} />
						</Tooltip>
					</Box>
				)}
				{editing && (
					<Box display="flex" flexDirection="column">
						<Tooltip title={t('common:close')} arrow placement="top">
							<Close sx={{ cursor: 'pointer', mb: 2 }} onClick={() => setEditing(null)} />
						</Tooltip>
						<Tooltip title={t('common:save')} arrow>
							<Save sx={{ cursor: 'pointer' }} onClick={() => onUpdateDebt(index, debt)} />
						</Tooltip>
					</Box>
				)}
			</Grid>

			{!editing && debt.agreement !== 'invoice' && (
				<>
					<Grid xs={2} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:name')}</Typography>
					</Grid>
					<Grid xs={2} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:capital')}</Typography>
					</Grid>
					<Grid xs={2} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:other')}</Typography>
					</Grid>
					<Grid xs={2} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:total')}</Typography>
					</Grid>
					<Grid xs={4} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:interest')}</Typography>
					</Grid>
					<Grid xs={2} onClick={() => toggleDebtor(debt.debtor)}>
						<Box
							sx={{
								cursor: 'pointer',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-start',
								gap: 1,
							}}
						>
							{debt.debtor.name} <ReadMore />
						</Box>
					</Grid>
					<Grid xs={2}>{currency(debt.baseAmount)}</Grid>
					<Grid xs={2}>{currency(debt.otherCharges)}</Grid>
					<Grid xs={2}>{currency(debt.totalAmount)}</Grid>
					<Grid xs={4}>{currency(debt.totalAmount * (debt.interestRate / 100))}</Grid>
				</>
			)}

			{!editing && debt.agreement === 'invoice' && (
				<>
					<Grid xs={4} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:name')}</Typography>
					</Grid>
					<Grid xs={4} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:capital')}</Typography>
					</Grid>
					<Grid xs={4} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:other')}</Typography>
					</Grid>

					<Grid xs={4} onClick={() => toggleDebtor(debt.debtor)}>
						<Box
							sx={{
								cursor: 'pointer',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-start',
								gap: 1,
							}}
						>
							{debt.debtor.name} <ReadMore />
						</Box>
					</Grid>
					<Grid xs={4}>{currency(debt.baseAmount)}</Grid>
					<Grid xs={4}>{currency(debt.otherCharges)}</Grid>
				</>
			)}

			{!editing && debt.agreement === 'invoice' && (
				<>
					<Grid xs={4} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:total')}</Typography>
					</Grid>
					<Grid xs={4} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:interest')}</Typography>
					</Grid>

					<Grid xs={4} sx={{ mt: 2 }}>
						<Typography variant="caption">{t('common:unpaid')}</Typography>
					</Grid>

					<Grid xs={4}>{currency(debt.totalAmount)}</Grid>
					<Grid xs={4}>{currency(debt.totalAmount * (debt.interestRate / 100))}</Grid>
					<Grid xs={4}>{currency(debt.balance || 0)}</Grid>
				</>
			)}

			{editing && (
				<>
					<Grid xs={3}>
						<TextField
							id="baseAmount"
							label={t('common:capital')}
							variant="outlined"
							inputProps={{ type: 'number', min: 1 }}
							value={debt.baseAmount}
							disabled={!editing || saving}
							onChange={(ev) => {
								update(index, 'baseAmount', Number(ev.currentTarget.value));
							}}
						/>
					</Grid>

					<Grid xs={3}>
						<TextField
							id="otherCharges"
							label={t('common:other')}
							variant="outlined"
							inputProps={{ type: 'number', min: 1 }}
							value={debt.otherCharges}
							disabled={!editing || saving}
							onChange={(ev) => {
								update(index, 'otherCharges', Number(ev.currentTarget.value));
							}}
						/>
					</Grid>

					<Grid xs={3}>
						<TextField
							id="totalAmount"
							label={t('common:total').toUpperCase()}
							variant="outlined"
							inputProps={{ type: 'number', min: 1 }}
							value={debt.totalAmount}
							disabled={!editing || saving}
							onChange={(ev) => {
								update(index, 'totalAmount', Number(ev.currentTarget.value));
							}}
						/>
					</Grid>

					{debt.agreement === 'invoice' && (
						<Grid xs={3}>
							<TextField
								id="balance"
								label={t('common:balance').toUpperCase()}
								variant="outlined"
								inputProps={{ type: 'number', min: 1 }}
								value={debt.balance}
								disabled={!editing || saving}
								onChange={(ev) => {
									update(index, 'balance', Number(ev.currentTarget.value));
								}}
							/>
						</Grid>
					)}
				</>
			)}
		</Grid>
	);
}

export default DebtData;
