import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import DebtDate from 'components/DebtDate';

type Props = {
	onUpdate: (key: keyof Client['debtCollectionInformation']) => (event: any) => void;
	data: Client;
};

function DatesAccordion(props: Props) {
	const { onUpdate, data } = props;

	const { t } = useTranslation();

	return (
		<Accordion>
			<AccordionSummary aria-controls="Contact-content" id="DebtCollection-dates-header">
				<Typography>{t('common:dates')}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<DebtDate
					date={data.debtCollectionInformation.collectionDays}
					onChange={onUpdate('collectionDays')}
					label={t('clients:collectionDays')}
				/>
				<DebtDate
					date={data.debtCollectionInformation.paymentDays}
					onChange={onUpdate('paymentDays')}
					label={t('clients:paymentDays')}
				/>
			</AccordionDetails>
		</Accordion>
	);
}

export default DatesAccordion;
