import { configureStore } from '@reduxjs/toolkit';

import drawer from './reducers/drawer';
import session from './reducers/session';

const Store = configureStore({
	reducer: {
		drawer,
		session,
	},
});

export type RootState = ReturnType<typeof Store.getState>;

export type AppDispatch = typeof Store.dispatch;

export default Store;
