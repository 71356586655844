import { useTranslation } from 'react-i18next';

import { Box, Button, ButtonGroup } from '@mui/material';
import { FindInPage as DocIcon } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

//import ClientDebtsAccountsTable from 'components/ClientDebtsAccountsTable/ClientDebtsAccountsTable';
import PersonDebtTable from 'components/PersonTable/PersonDebtTable';
import FluidContainer from 'components/containers/FluidContainer';
import EmailsAccountsTable from 'components/PersonTable/EmailsAccountsTable';
import MonthTable from 'components/MonthTable';

function Dashboard() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<FluidContainer
			title={t('common:dashboard')}
			sx={{
				width: '100%',
			}}
		>
			<ButtonGroup sx={{ mb: 1 }}>
				<Button
					onClick={() => {
						navigate(`/documentation`);
					}}
					type="button"
					variant="outlined"
					startIcon={<DocIcon />}
				>
					{t('common:documentation')}
				</Button>
			</ButtonGroup>

			{/* First Row */}
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
					<EmailsAccountsTable />
					<MonthTable />
				</Box>
				<PersonDebtTable />
			</Box>
		</FluidContainer>
	);
}

export default Dashboard;
