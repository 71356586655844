import { useTranslation } from 'react-i18next';

import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useNavigate } from 'react-router-dom';
import { MonthAccumulated } from 'lib/models/clients';
import { currency } from 'lib/helpers';

type Props = {
	tableScheduledPayment: MonthAccumulated;
	agreement: Agreement;
};

function MobileItemDebt({ tableScheduledPayment, agreement }: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<Accordion defaultExpanded={false} variant="outlined">
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="DebtCollection-content"
				id="DebtCollection-header"
			>
				<Typography>{tableScheduledPayment.code}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: 8,
						justifyContent: 'space-between',
						alignItems: 'center',
						pt: 8,
					}}
				>
					{t('common:code')}: {tableScheduledPayment.code}
				</Box>

				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('common:code')}: {tableScheduledPayment.code}
				</Box>

				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('common:description')}: {tableScheduledPayment.description}
				</Box>
				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('people:debt')}: {currency(tableScheduledPayment.total)})
				</Box>
				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					<Button
						onClick={() =>
							navigate(
								`/clients/${tableScheduledPayment._id}/agreement/${agreement}/account/${tableScheduledPayment.code}`
							)
						}
						variant="outlined"
					>
						{t(`common:go`)}
					</Button>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}

export default MobileItemDebt;
