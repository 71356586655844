import { useMemo } from 'react';

import useStoreSelector from 'store/useStoreSelector';

function useLoggedUser() {
	const session = useStoreSelector(({ session }) => session);

	const loggedUser = session.user;

	const { _id, username, first_name, last_name, type } = loggedUser || {
		_id: '',
		username: '',
		first_name: '',
		last_name: '',
		type: '' as UserType,
	};

	const user = useMemo(() => {
		if (_id) {
			return { _id, username, first_name, last_name, type };
		}
		return null;
	}, [_id, username, first_name, last_name, type]);

	return user;
}

export default useLoggedUser;
