import { AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';

export function getStringFromData(data: any): string {
	try {
		if (data && (typeof data === 'object' || Array.isArray(data))) {
			const dataString = JSON.stringify(data);
			return dataString;
		}
		throw Error(`type (${typeof data}) can't be converted into JSON`);
	} catch (error) {
		console.warn(error);
	}
	return '';
}

/**
 * Receives a JSON as string and returns the object
 *
 * @param dataString a valid json string
 * @param isT method that validates that the output is the desired
 */
export function getDataFromString<T>(dataString: string, isT: (data: any) => data is T): T | null {
	try {
		if (!!dataString && typeof dataString === 'string' && dataString.length > 0) {
			const data = JSON.parse(dataString);
			return isT(data) ? data : null;
		}
		if (!!dataString && dataString.length > 0) {
			throw Error(`type (${typeof dataString}) is not a valid string`);
		}
	} catch (error) {
		console.warn(error);
	}
	return null;
}

/**
 * Sames as getDataFromString but DOES NOT validates the output
 *
 * @param dataString a valid json string
 */
export function UNSAFE_getDataFromString<T>(dataString: string) {
	const data = getDataFromString(dataString, (data: any): data is T => true);
	if (isNotNull(data)) {
		return data;
	}
	throw new Error('String received is not a valid JSON');
}

export function objectValues<T extends {}>(object: T) {
	return Object.keys(object).map((objectKey) => object[objectKey as keyof T]);
}

export function objectKeys<T extends {}>(object: T) {
	return Object.keys(object).map((objectKey) => objectKey as keyof T);
}

/**
 * Checks if the request response is valid
 *
 * @param {object} response
 */
export const checkStatus = (response: AxiosResponse<any>) => {
	// raises an error in case response status is not a success
	if (response.status >= 200 && response.status < 300) {
		// Success status lies between 200 to 300
		return response;
	}
	throw new Error(response.statusText);
};

const logWarningForInvalidKeys = (path: string, invalidKeys: Array<string>) => {
	const invalidKeysString = invalidKeys.join(', ');
	console.warn(`[lib/helpers, isRulesDictionary] keys (${invalidKeysString}) are invalid for path (${path})`);
};

const invalidSelf = (self: any) => {
	return !self || (typeof self !== 'string' && (typeof self !== 'object' || typeof self.param !== 'string'));
};

const invalidRule = (rule: any) => {
	return (
		(typeof rule !== 'boolean' && !rule) ||
		(typeof rule !== 'boolean' && (typeof rule !== 'object' || invalidSelf(rule.self)))
	);
};

export const isServerRules = (path: string = '') => {
	return (data: any): data is ServerRules => {
		const keys = Object.keys(data || {});
		const invalidKeys = keys.filter((key: string) => {
			return (
				!data[key] ||
				Object.keys(data[key]).find((ruleKey) => {
					const rule = data[key][ruleKey];
					const invalid = invalidRule(rule);
					return invalid;
				})
			);
		});

		if (!!path && invalidKeys.length > 0) {
			logWarningForInvalidKeys(path, invalidKeys);
		}

		return invalidKeys.length === 0;
	};
};

export function sanitizedInput(value?: string | null): string {
	return `${value || ''}`
		.trim()
		.toLowerCase()
		.split('.')
		.join('')
		.split('-')
		.join('')
		.split('_')
		.join('')
		.split('\\')
		.join('')
		.split('/')
		.join('')
		.split('á')
		.join('a')
		.split('é')
		.join('e')
		.split('í')
		.join('i')
		.split('ó')
		.join('o')
		.split('ú')
		.join('u');
}

export function isDocumentNumber(documentNumber: string) {
	if (!documentNumber) {
		return true;
	}

	const parsedNumber = documentNumber.split('-').join('');

	if (parsedNumber.length !== 11) {
		return false;
	}

	const [checkDigit, ...rest] = parsedNumber.split('').map(Number).reverse();

	const total = rest.reduce((acc, cur, index) => acc + cur * (2 + (index % 6)), 0);

	const mod11 = 11 - (total % 11);

	if (mod11 === 11) {
		if (checkDigit === 0) {
			return true;
		}
	}

	if (mod11 === 10) {
		return false;
	}

	if (checkDigit === mod11) {
		return true;
	}

	return false;
}
/**
 * Returns true if at least one element of the array A is found on array B
 * @param arrayA @type Array<string> to get elements from
 * @param arrayB @type Array<string> to find elements from
 * @returns @type boolean
 */
export function compareArrays(arrayA: Array<string>, arrayB: Array<string>): boolean {
	return typeof arrayA.find((element) => arrayB.includes(element)) != 'undefined';
}

export function capitalize(text: string): string {
	return text[0].toUpperCase() + text.slice(1);
}

export function sort<T>(desc: boolean = false) {
	return (prev: T, next: T) => {
		if (prev < next) {
			return desc ? 1 : -1;
		}
		if (prev > next) {
			return desc ? -1 : 1;
		}
		return 0;
	};
}

export function sortBy<T>(key: keyof T, desc: boolean = false) {
	return (prev: T, next: T) => {
		if (prev[key] < next[key]) {
			return desc ? 1 : -1;
		}
		if (prev[key] > next[key]) {
			return desc ? -1 : 1;
		}
		return 0;
	};
}

/**
 * Returns a function that check if the clicked item is already selected or not and returns a new array with the updated selected array
 *
 * @param key The key of the object to compare if it is selected or not
 * @param selected The array of selected elements
 * @param callback [Optional] If received, it will be called with the updated selected array, if no callback is received the array is return instead
 */
export function getSelector<T>(key: keyof T, selected: T[]) {
	return (el: T) => {
		let elements: T[] = selected;
		const selectedIndex = selected.findIndex((current) => current[key] === el[key]);
		if (selectedIndex === -1) {
			elements = [...selected, el];
		} else if (selectedIndex === 0) {
			elements = selected.slice(1);
		} else if (selectedIndex === selected.length - 1) {
			elements = selected.slice(0, -1);
		} else if (selectedIndex > 0) {
			elements = [...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1)];
		}
		return elements;
	};
}

export function paginateResponse<T>(response: PaginateSource<T>, primaryKey: keyof T, isMobile: boolean) {
	type Response = PaginateResponse<T>;
	const { total, elements: requestElements } = response;
	const requestTotal = requestElements.length;
	return (current: Response): Response => {
		if (!isMobile) {
			return { total, requestTotal, elements: requestElements };
		}
		const currentElements = current.elements;
		const currentKeys = currentElements.map((el) => el[primaryKey]);
		const newElements = requestElements.filter((el) => !currentKeys.includes(el[primaryKey]));
		const elements = [...currentElements, ...newElements];
		return { total, requestTotal, elements };
	};
}

export function isNumber(value: any): value is number {
	return !isNaN(value) && value >= 0;
}

export type CachedProps<T extends PaginationProps> =
	| {
			mustRefetch: true;
			props: T;
	  }
	| {
			mustRefetch: false;
			props: null;
	  };

export function getNewProps<T extends PaginationProps>(lastProps: T | null, nextProps: T): CachedProps<T> {
	const keys = Object.keys(nextProps) as (keyof T)[];
	return lastProps && !keys.find((key) => lastProps[key] !== nextProps[key])
		? { mustRefetch: false, props: null }
		: { mustRefetch: true, props: nextProps };
}

export function isNotNull<T>(data: T | null): data is T {
	return !!data;
}

type Fetch<T, P> = (data: T) => Promise<PaginateSource<P>>;

/**
 * Fetch data and paginate or concatenate depending if isMobile is in true or not.
 *
 * If isMobile is true, an infinite list is displayed, response must be concatenated into the previous response.
 *
 * If isMobile is false, a table is displayed, response must include only the elements of the current page.
 *
 * @param pagination The pagination properties (page and rowsPerPage) plus any value that can be used by "fetch"
 * @param isMobile boolean indicating if the media query is mobile or not
 * @param primaryKey The primaryKey of the fetched component
 * @param fetch A function that will receive paginationProp and must return a valid "PaginateResponse" with an array of elements to be displayed in the list or table
 */
export async function getPaginatedData<T extends PaginationProps, P>(
	props: T,
	isMobile: boolean,
	primaryKey: keyof P,
	fetch: Fetch<T, P>
) {
	const requestResponse = await fetch({ isMobile, ...props });
	return paginateResponse(requestResponse, primaryKey, isMobile);
}

/**
 * Toast a success message with the notistack library
 *
 * @param message The string to be shown
 */
export function enqueueSnackbarSuccess(message: string) {
	enqueueSnackbar({ message, variant: 'success' });
}

/**
 * Toast an error message with the notistack library and logs the error
 *
 * @param error An error object
 * @param parser [optional] a method that receives the detected error message and returns a string, if received the returned string will be used in the snakbar
 */
export function enqueueSnackbarError(error: any, parser?: (message: string) => string) {
	const message = getErrorMessage(error);
	console.error(message);
	enqueueSnackbar({ message: parser ? parser(message) : message, variant: 'error' });
}

/**
 * Tries to obtain an error message from the received object or string (usually used with an AxiosErrorResponse)
 *
 * @param res An error object or string
 *
 * @returns A string if the error message was found or "unknown error" if no message was detected
 */
export function getErrorMessage(res: any = {}): string {
	if (isObject(res)) {
		// If it is a Axios Response error
		if (isObject(res.response)) {
			const message = getError(res.response.data);
			if (message) {
				return message;
			}
		}
	}
	const error = getError(res) || 'unknown error';
	return error;
}

function getError(data: any): string | null {
	if (isString(data)) {
		return data;
	}
	if (isObject(data)) {
		return isString(data.message) ? data.message : isString(data.error) ? data.error : null;
	}
	return null;
}

function isString(data: any): data is string {
	return typeof data === 'string' && data.length > 0;
}

function isObject(data: any): data is Object {
	return !!data && typeof data === 'object';
}

/**
 * Format the received number as a currency
 *
 * @param value The number to be formatted
 * @param currency The currency (DEFAULT ARS)
 *
 * @returns The received number formatted with the received currency
 */
export function currency(value: number, currency: string = 'ARS') {
	const formatter = new Intl.NumberFormat('es-AR', {
		style: 'currency',
		currency,
	});
	return formatter.format(value);
}

/**
 * Calculates the debt total amount
 */
export function getDebtsTotalAmount(debt: DebtDocument['body'] | Debt) {
	const totalAmount = debt.baseAmount + debt.interestRate + debt.otherCharges;

	return totalAmount;
}

/**
 * Checks if the response has a valid paginated response
 */
export function isPaginateSource<T>(data: any): data is PaginateSource<T> {
	return !!data && !isNaN(data.total) && Array.isArray(data.elements);
}

/**
 * Returns a valid object for a paginated request
 */
export function getSearch<T extends PaginationProps & { [key in string]: any }>(sort: Sort, props: T) {
	const { order, orderBy, page, rowsPerPage, search = '', ...rest } = props;

	const filter = { search: search || undefined, ...rest };

	let options: { limit?: number; skip?: number; sort: Sort } = { sort };

	if (isNumber(page)) {
		const limit = isNumber(rowsPerPage) ? rowsPerPage : 15;
		options.limit = limit;
		options.skip = limit * page;
	}

	return { filter, options };
}
