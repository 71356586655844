import { useCallback } from 'react';

import { removeSession } from 'lib/storage';
import useStoreDispatch from 'store/useStoreDispatch';

import { tokenExpired } from 'store/reducers/session';

function useSetTokenExpired() {
	const dispatch = useStoreDispatch();

	const setExpired = useCallback(() => {
		dispatch(tokenExpired());
		removeSession();
	}, [dispatch]);

	return setExpired;
}

export default useSetTokenExpired;
