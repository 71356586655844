import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Moment } from 'moment';
import MonthCell from './MonthCell';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

type Props = {
	dates: Moment[];
	allDates: Moment[][];
	debt: Debt;
	key: string;
	indexArr: number;
	generate: (debt: Debt, updated: boolean) => any;
	uploadFile: (debtId: string, file: File, index: number) => any;
};

function PaymentsTable({ dates, debt, key, indexArr, generate, uploadFile, allDates }: Props) {
	const { t } = useTranslation();
	const [selectedFiles, setSelectedFiles] = useState(Array(dates.length).fill(null));

	const title =
		indexArr > 0
			? dates.reduce((date, current) => {
					return date > current.get('year') ? date : current.get('year');
			  }, 0)
			: dates[0].get('year');

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
		if (event.target.files === null || !debt._id) return;
		const file = event.target.files[0];
		if (file) {
			const newSelectedFiles = [...selectedFiles];
			newSelectedFiles[index] = file;
			setSelectedFiles(newSelectedFiles);

			const response = await uploadFile(debt._id, file, index);
			return response;
		}
	};

	return (
		<>
			<Typography variant="body2" sx={{ mt: 2 }}>
				<strong>{title}</strong>
			</Typography>
			<Table>
				<TableHead>
					<TableRow>
						{dates.map((date, index) => {
							const year =
								index > 0 && date.get('year') - dates[index - 1].get('year') === 1 ? `(${date.get('year')}) ` : '';
							const month = date.get('month');
							return (
								<TableCell key={`${key}_${month}`}>
									{year}
									{t(`common:month_${month}`).substring(0, 3)}
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						{dates.map((_, index) => {
							return (
								<MonthCell
									key={`${key}_value_${index}`}
									debt={debt}
									allDates={allDates}
									index={index}
									indexArr={indexArr}
									lengthArr={dates.length}
									generate={generate}
									handleFileChange={handleFileChange}
								/>
							);
						})}
					</TableRow>
				</TableBody>
			</Table>
		</>
	);
}

export default PaymentsTable;
