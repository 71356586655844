import { Close } from '@mui/icons-material';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { addDebtAccount, updateDebtAccount } from 'lib/models/clients';
import { ChangeEvent, useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

type ClientDebtAccountModalProps = {
	clientId: string;
	open: boolean;
	onClose: () => void;
	setDebtsAccount: Dispatch<SetStateAction<DebtsAccount[]>>;
	debtsAccounts: DebtsAccount[];
	clientDebtsAccount: DebtsAccount[];
	updatedAccount: DebtsAccount | null;
};

type DebtAccountProps = {
	code: string;
	description: string;
};

const ClientDebtAccountModal = ({
	clientId,
	open,
	onClose,
	setDebtsAccount,
	debtsAccounts,
	clientDebtsAccount,
	updatedAccount,
}: ClientDebtAccountModalProps) => {
	const { t } = useTranslation();
	const [debtAccount, setDebtAccount] = useState<DebtAccountProps>({
		code: '',
		description: '',
	});
	const [codeError, setCodeError] = useState<boolean>(false);

	function updatePersonKey(key: keyof DebtAccountProps) {
		return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setDebtAccount({ ...(debtAccount || {}), [key]: ev.target.value } as DebtAccountProps);
			if (codeError && key === 'code' && debtAccount !== null) {
				debtAccount.code.length > 0 && setCodeError(false);
			}
		};
	}

	async function updated() {
		if (debtAccount !== null && debtAccount.code === '') {
			setCodeError(true);
			return;
		}

		try {
			if (clientDebtsAccount && updatedAccount) {
				const updated = await updateDebtAccount(clientId, updatedAccount.code, debtAccount);

				const indexToUpdate = clientDebtsAccount.findIndex((account) => account.code === updatedAccount.code);

				if (indexToUpdate !== -1) {
					clientDebtsAccount.findIndex((account) => account.code === updatedAccount.code);
					clientDebtsAccount[indexToUpdate] = {
						...debtAccount,
						debts: updated.debts,
						date: updated.date,
						code: updated.code,
					};

					setDebtsAccount(clientDebtsAccount);
				}

				setDebtAccount({
					code: '',
					description: '',
				});
				onClose();
			}
		} catch (error) {
			console.warn(error);
			alert(`TODO: error not catch`);
		}
	}

	async function save() {
		if (debtAccount !== null && debtAccount.code === '') {
			setCodeError(true);
			return;
		}

		try {
			if (debtAccount) {
				const saved = await addDebtAccount(clientId, debtAccount);
				clientDebtsAccount.push(saved);
				setDebtsAccount(clientDebtsAccount);
				setDebtAccount({
					code: '',
					description: '',
				});
				onClose();
			}
		} catch (error) {
			console.warn(error);
			alert(`TODO: error not catch`);
		}
	}

	useEffect(() => {
		if (updatedAccount !== null) {
			setDebtAccount({
				code: updatedAccount.code,
				description: updatedAccount.description || '',
			});
		}
	}, [updatedAccount]);

	return (
		<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '90%',
					bgcolor: 'background.paper',
					border: '2px solid lightgrey',
					boxShadow: 24,
					p: 4,
					overflowY: 'auto',
					maxHeight: '90vh',
					display: 'block',
				}}
			>
				<Button
					onClick={() => {
						onClose();
						setDebtAccount({ code: '', description: '' });
					}}
					variant="outlined"
					color="error"
					sx={{ position: 'absolute', top: 2, right: 2 }}
				>
					<Close />
				</Button>

				<Typography id="modal-modal-title" variant="h6" component="h2">
					{t(`people:debtorCreation`)}
				</Typography>

				<Typography id="modal-modal-description" variant="body1" component="p">
					{t('people:editModalDescription', {
						type: t('common:debtor').toLowerCase(),
						source: t('common:account').toLowerCase(),
					})}
				</Typography>

				<Box sx={{ mt: 2 }}>
					<Box sx={{ mt: 2 }}>
						<TextField
							id="code"
							label={t('common:code')}
							fullWidth={true}
							value={debtAccount?.code}
							variant="outlined"
							sx={{ mb: 3 }}
							error={codeError}
							helperText={codeError ? t('system:emptyField') : ''}
							onChange={updatePersonKey('code')}
						/>
						<TextField
							id="description"
							label={t('common:description')}
							value={debtAccount?.description}
							variant="outlined"
							multiline
							fullWidth
							rows={4}
							sx={{ mb: 3 }}
							onChange={updatePersonKey('description')}
						/>

						<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
							{updatedAccount !== null && updatedAccount.code !== null ? (
								<Button variant="outlined" onClick={() => updated()}>
									{t(`common:edit`)}
								</Button>
							) : (
								<Button variant="outlined" onClick={() => save()}>
									{t(`common:edit`)}
								</Button>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default ClientDebtAccountModal;
