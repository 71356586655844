import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { useNavigate } from 'react-router';

import { getStringFromData } from 'lib/helpers';

import useURLQuery from 'hooks/useURLQuery';

import useQuery, { Query, defaultQuery, isQuery } from 'hooks/useQuery';
import { reducer } from 'lib/queryReducer';

import FullLayout from 'components/layouts/FullLayout/FullLayout';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import SearchToolbar from 'components/SearchToolbar/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable/ResponsiveTable';
import MobileItem from './MobileItem';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import { rowsPerPageOptions } from 'config/constants';
import moment from 'moment';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import usePaginatedData from 'hooks/usePaginatedData';
import { fetchFile, fetchScheduledPayments } from 'lib/models/clients';
//import { detectFileExtension } from 'lib/detectFileExtension';

function DocumentTable() {
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useURLQuery(isQuery, defaultQuery);

	//const navigate = useNavigate();

	const [{ order, page, rowsPerPage, search: searchValue }, dispatch] = useReducer(reducer, searchParams);

	const query = useQuery(searchValue, order, page, rowsPerPage);

	/**
	 * Checks if the search value was updated, if so it clears the mobile state
	 */
	const resetMobileState = useCallback((prev: Query | null) => !prev || prev.search !== searchValue, [searchValue]);

	const {
		loading,
		elements: scheduledPayments,
		total,
		requestTotal,
	} = usePaginatedData(fetchScheduledPayments, query, 'index', resetMobileState);

	const [open, setOpen] = useState(false);
	const [showFile, setShowFile] = useState<string | null>();
	const [fileExtension, setFileExtension] = useState<string | undefined>('unknown');

	const handleClickOpen = async (file: string | null) => {
		try {
			if (file === null) {
				return;
			}
			const fileResponse = await fetchFile(file);
			setShowFile(fileResponse?.file);
			//setFileExtension(detectFileExtension(file));
			setFileExtension(fileResponse?.type);
			setOpen(true);
		} catch (error) {
			console.warn(error);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const search = useCallback(
		(payload: string) => {
			dispatch({ type: 'SetSearch', payload });
		},
		[dispatch],
	);

	useEffect(() => {
		const queryString = `query=${getStringFromData(query)}`;
		setSearchParams(queryString);
	}, [query, setSearchParams]);

	return (
		//{/*<CreateIcon onCreate={() => navigate('/DocumentTable/new')} />*/}
		//{/*<CreateIcon onCreate={() => navigate('/bills/new')} />*/}
		<FullLayout>
			{loading && <LinearProgress />}

			<SearchToolbar onSearch={(value) => search(value)} live={false} initialValue={query.search} />

			<Dialog open={open} onClose={handleClose} fullWidth>
				<DialogContent>
					{fileExtension === 'png' || fileExtension === 'jpg' ? (
						<img
							src={showFile !== null && showFile !== undefined ? `data:image/${fileExtension};base64,${showFile}` : ''}
							style={{ width: '100%' }}
							alt="imagen"
						/>
					) : (
						showFile !== null &&
						showFile !== undefined && (
							// eslint-disable-next-line jsx-a11y/iframe-has-title
							<iframe
								src={`data:application/${fileExtension};base64,${showFile}`}
								width="100%"
								height="800px"
								style={{ overflow: 'auto' }}
								allowFullScreen
								frameBorder="0"
							></iframe>
						)
					)}
				</DialogContent>
			</Dialog>

			<ResponsiveTable
				elements={scheduledPayments}
				list={{
					primaryKey: 'index',
					infiniteScroll: {
						endOfList: requestTotal < rowsPerPage,
						onEndReached: () => dispatch({ type: 'SetPage', payload: page + 1 }),
					},
					renderListItemText: (infoScheduledPayment) => (
						<MobileItem
							key={infoScheduledPayment.scheduledPayment._id}
							tableScheduledPayment={infoScheduledPayment}
							handleClickOpen={handleClickOpen}
							//onEdit={onEdit}
						/>
					),
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:code')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:name')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:month')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:actions')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (infoScheduledPayment) => (
						<>
							<TableRow tabIndex={-1} key={infoScheduledPayment.scheduledPayment._id}>
								<TableCell>{infoScheduledPayment.code}</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{infoScheduledPayment.debtor.name}
									</Box>
								</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										({moment(infoScheduledPayment.scheduledPayment.date).get('year')}){' '}
										{t(`common:month_${moment(infoScheduledPayment.scheduledPayment.date).get('month')}`).substring(
											0,
											3,
										)}
									</Box>
								</TableCell>
								<TableCell>
									<Box>
										<Button
											onClick={() => handleClickOpen(infoScheduledPayment.scheduledPayment.file)}
											variant="outlined"
										>
											{t(`common:view`)}
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						</>
					),
					pagination: {
						count: total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: (_, payload) => dispatch({ type: 'SetPage', payload }),
						onRowsPerPageChange: ({ target }) => {
							return dispatch({ type: 'SetRowsPerPage', payload: parseInt(target.value, 10) });
						},
					},
				}}
			/>
		</FullLayout>
	);
}

export default DocumentTable;
