import StoreProvider from 'store/StoreProvider';

import { SnackbarProvider } from 'notistack';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import MomentUtils from '@date-io/moment';

import Language from './Language';

import Router from 'components/Router';
import ServerDown from 'components/ServerDown';

/**
 * This component is used to configure the app before it's rendered
 */
function Config() {
	return (
		<LocalizationProvider adapterLocale={MomentUtils}>
			<StoreProvider>
				<SnackbarProvider>
					<Language>
						<ServerDown>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<Router />
							</LocalizationProvider>
						</ServerDown>
					</Language>
				</SnackbarProvider>
			</StoreProvider>
		</LocalizationProvider>
	);
}

export default Config;
