import { useParams, Outlet } from 'react-router-dom';
import { validateComponent } from 'lib/session';

import useLoggedAndRules from 'hooks/useLoggedWithRules';

import Login from 'components/screens/system/Login';
import { Unauthorized } from 'components/screens/system';

function Auth<T>({ element: Element, mode, ...rest }: RoutesProps<T>) {
	const { logged, rules } = useLoggedAndRules();

	const params = useParams();

	const validated = validateComponent(rules, { ...params, ...rest }, mode);

	if (!logged) {
		return <Login />;
	}

	if (!validated) {
		return <Unauthorized />;
	}

	return Element ? <Element /> : <Outlet />;
}

export default Auth;
