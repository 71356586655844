import { useTranslation } from 'react-i18next';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import moment from 'moment';

type Props = {
	tableScheduledPayment: TableScheduledPayment;
	handleClickOpen: (file: string | null) => void;
	//onEdit: (client: ScheduledPayment) => any;
};

function MobileItem({ tableScheduledPayment, handleClickOpen /*, onEdit*/ }: Props) {
	const { t } = useTranslation();

	return (
		<Accordion defaultExpanded={false} variant="outlined">
			{
				<>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="scheduledPayment-content"
						id="scheduledPayment-header"
					>
						<Typography>{tableScheduledPayment.code}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 8,
								justifyContent: 'space-between',
								alignItems: 'center',
								pt: 8,
							}}
							//onClick={() => onEdit(client)}
						>
							{t('common:code')}: {tableScheduledPayment.code}
						</Box>

						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:name')}: {tableScheduledPayment.debtor.name}
						</Box>

						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:month')}: ({moment(tableScheduledPayment.scheduledPayment.date).get('year')}){' '}
							{t(`common:month_${moment(tableScheduledPayment.scheduledPayment.date).get('month')}`).substring(0, 3)}
						</Box>
						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							<Button onClick={() => handleClickOpen(tableScheduledPayment.scheduledPayment.file)} variant="outlined">
								{t(`common:view`)}
							</Button>
						</Box>
					</AccordionDetails>
				</>
			}
		</Accordion>
	);
}

export default MobileItem;
