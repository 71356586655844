import { LinearProgress, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';

import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ResponsiveTable from 'components/ResponsiveTable';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FullLayout from 'components/layouts/FullLayout';
import moment from 'moment';
import MobileItemDebt from './MobileItemDebt';

import { fetchAccountsDueToday } from 'lib/models/clients';
import useData from 'hooks/useData';
import { currency } from 'lib/helpers';

function EmailsAccountsTable() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { loading, elements: accountsPayable } = useData(fetchAccountsDueToday);

	return (
		<FullLayout sx={{ width: '100%', maxHeight: '45vh', overflow: 'auto' }}>
			{loading && <LinearProgress />}
			<Toolbar
				sx={{
					pl: { sm: 2 },
					pr: { xs: 1, sm: 1 },
					bgcolor: (theme) => theme.palette.primary.main,
					color: 'white',
				}}
			>
				<Typography sx={{ flex: '1 1 100%', textTransform: 'capitalize' }} variant="h6" id="tableTitle" component="div">
					{t('common:todayDueDates')}
				</Typography>
			</Toolbar>
			{!accountsPayable.length && (
				<Typography sx={{ mt: 1 }} variant="h6" color="error">
					{t('common:withoutNotifications')}
				</Typography>
			)}
			{!!accountsPayable.length && (
				<ResponsiveTable
					elements={accountsPayable}
					list={{
						primaryKey: 'index',
						renderListItemText: (accountPayable) => (
							<MobileItemDebt key={accountPayable.scheduledPayment._id} tableScheduledPayment={accountPayable} />
						),
					}}
					table={{
						renderHead: () => (
							<TableHead>
								<TableRow>
									<TableCell align="left" padding="normal">
										{t('people:name')}
									</TableCell>
									{/* <TableCell align="left" padding="normal">
										{t('common:email')}
									</TableCell> */}
									<TableCell align="left" padding="normal">
										{t('people:debt')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:date')}
									</TableCell>
									<TableCell align="left" padding="normal">
										{t('common:lastNotification')}
									</TableCell>
								</TableRow>
							</TableHead>
						),
						renderRow: (accountPayable) => (
							<>
								<TableRow
									sx={{
										'&:hover': {
											backgroundColor: 'lightgray',
											cursor: 'pointer',
										},
									}}
									tabIndex={-1}
									key={accountPayable.scheduledPayment._id}
									onClick={() =>
										navigate(
											`/clients/${accountPayable._id}/agreement/${accountPayable.agreement}/account/${accountPayable.code}/debt/${accountPayable.debt._id}`
										)
									}
								>
									<TableCell sx={{ mb: 1 }}>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{accountPayable.debtor.name}
										</Box>
									</TableCell>
									{/* <TableCell sx={{ mb: 1 }}>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{accountPayable.debtor.email}
										</Box>
									</TableCell> */}
									<TableCell sx={{ mb: 1 }}>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{currency(accountPayable.scheduledPayment.amount - accountPayable.scheduledPayment.amountPaid)}
										</Box>
									</TableCell>
									<TableCell>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{t(`common:month_${moment(accountPayable.scheduledPayment.date).get('month')}`).substring(0, 3)}{' '}
											{moment(accountPayable.scheduledPayment.date).get('date')}
										</Box>
									</TableCell>
									<TableCell>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{accountPayable.lastNotificationSent &&
												t(`common:month_${moment(accountPayable.lastNotificationSent).get('month')}`).substring(
													0,
													3
												)}{' '}
											{accountPayable.lastNotificationSent
												? moment(accountPayable.lastNotificationSent).get('date')
												: ' - - -'}
										</Box>
									</TableCell>
								</TableRow>
							</>
						),
					}}
				/>
			)}
		</FullLayout>
	);
}

export default EmailsAccountsTable;
