import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LinearProgress, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ResponsiveTable from 'components/ResponsiveTable';

import useData from 'hooks/useData';

import { currency } from 'lib/helpers';
import { fetchAccountsDueMonth } from 'lib/models/clients';

import FullLayout from 'components/layouts/FullLayout';

import MobileItemDebt from './MobileItemDebt';

function MonthTable() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { loading, elements: accountsPayable } = useData(fetchAccountsDueMonth);

	return (
		<FullLayout sx={{ width: '100%', maxHeight: '45vh', overflow: 'auto' }}>
			{loading && <LinearProgress />}
			<Toolbar
				sx={{
					pl: { sm: 2 },
					pr: { xs: 1, sm: 1 },
					bgcolor: (theme) => theme.palette.primary.main,
					color: 'white',
				}}
			>
				<Typography sx={{ flex: '1 1 100%', textTransform: 'capitalize' }} variant="h6" id="tableTitle" component="div">
					{t('common:monthlyDueDates')}
				</Typography>
			</Toolbar>
			<ResponsiveTable
				elements={accountsPayable}
				list={{
					primaryKey: 'code',
					renderListItemText: (accountPayable) => (
						<MobileItemDebt
							key={accountPayable.code}
							tableScheduledPayment={accountPayable}
							agreement={accountPayable.agreement}
						/>
					),
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:description')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('people:debt')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (accountPayable) => (
						<>
							<TableRow
								tabIndex={-1}
								key={accountPayable._id}
								onClick={() =>
									navigate(
										`/clients/${accountPayable._id}/agreement/${accountPayable.agreement}/account/${accountPayable.code}`
									)
								}
								sx={{
									'&:hover': {
										backgroundColor: 'lightgray',
										cursor: 'pointer',
									},
								}}
							>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{accountPayable.description}
									</Box>
								</TableCell>
								<TableCell>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{currency(accountPayable.total)}
									</Box>
								</TableCell>
							</TableRow>
						</>
					),
				}}
			/>
		</FullLayout>
	);
}

export default MonthTable;
