import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';
import { FindInPage as DocIcon } from '@mui/icons-material';

import ScreenContainer from 'components/containers/ScreenContainer';
import IconCard from 'components/IconCard';
import useLoggedUser from 'hooks/useLoggedUser';
import { PANEL_MANUAL_URL, IT_MANUAL_URL } from 'config/constants';
import { UsersTypes } from 'types/global';

function Dashboard() {
	const { t } = useTranslation();

	const { classes } = useStyles();

	const user = useLoggedUser();

	return (
		<ScreenContainer title={t('common:dashboard')} sx={{ display: 'flex', flexDirection: 'column' }}>
			<Box>
				{user?.type !== UsersTypes.user && <h2>{t('common:welcome', { name: user?.first_name || '' })}</h2>}

				{user?.type === UsersTypes.user && (
					<img src={require('../../../assets/images/main.png')} className={classes.image} alt="A documentation icon" />
				)}

				{user?.type === UsersTypes.user && <h2>{t('common:welcomeClient', { name: user?.first_name || '' })}</h2>}
			</Box>

			{(user?.type === UsersTypes.admin || user?.type === UsersTypes.panel) && (
				<Box sx={{ display: 'flex' }}>
					<a href={PANEL_MANUAL_URL} target="_blank" rel="noreferrer" className={classes.link}>
						<IconCard
							title={t('common:backOfficeDocumentationTitle')}
							description={t('common:backOfficeDocumentationDescription')}
							icon={<DocIcon />}
						/>
					</a>
					<a href={IT_MANUAL_URL} target="_blank" rel="noreferrer" className={classes.link}>
						<IconCard
							title={t('common:itDocumentationTitle')}
							description={t('common:itDocumentationDescription')}
							icon={<DocIcon />}
						/>
					</a>
				</Box>
			)}
		</ScreenContainer>
	);
}

export default Dashboard;

const useStyles = makeStyles()((theme) => ({
	link: {
		textDecoration: 'none',
	},
	image: {
		width: '100%',
	},
}));
