import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Box, Toolbar, lighten, Paper, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import clsx from 'clsx';

import DeletionTypography from './DeletionTypography';
import SearchTypography, { SearchProps } from './SearchTypography';
import DeleteTooltip from 'components/tooltips/DeleteTooltip';
import ToggleFilterTooltip from 'components/tooltips/ToggleFilterTooltip';
import RefetchTooltip from 'components/tooltips/RefetchTooltip';
import ExportTooltip from 'components/tooltips/ExportTooltip';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import spacing from 'lib/spacing';

/**
 * Customizable table search toolbar that shows a search input and can receive an advance search form as children.
 *
 * @param {function} onSearch [Optional] If received an input field is shown and it will be called every time the user presses "ENTER" in the search field
 * @param {function} onRefetch [Optional] Called every time the user clicks the "reload" icon
 * @param {boolean} disabled [Optional] If true will disable the search input (default: false)
 * @param {boolean} live [Optional] If true will call onSearch on every inputChange (default: false)
 * @param {number} deletion [Optional] Configuration object for elements deletion
 * @param {object} advanceSearch [Optional] An object used to configure an advance search form.
 * @param {object} values {advanceSearch property} Each value to be presented as a chip</html>
 * @param {function} remove {advanceSearch property} Method that received in the parameters the value to be removed from the list of chips
 * @param {function} iconPosition {advanceSearch property} [optional] [default "right"] the position of the advance search icon}
 */
function SearchToolbar<T>(props: React.PropsWithChildren<SearchProps<T>>) {
	const { classes } = useStyles();

	const { t } = useTranslation();

	const { deletion, disabled = false, advanceSearch: search, children } = props;

	const withDeletion = !!deletion && deletion.amount > 0;
	const isCollapsed = withDeletion || !search || !search.visible;
	const iconPosition = search && search.iconPosition === 'left' ? 'left' : 'right';

	return (
		<Box sx={{ position: 'relative', flexGrow: 1 }}>
			<Toolbar
				sx={{ display: 'flex', justifyContent: 'flex-end' }}
				className={clsx({ [classes.highlight]: withDeletion })}
			>
				{!withDeletion && !!search && iconPosition === 'left' && (
					<ToggleFilterTooltip collapsed={isCollapsed} disabled={disabled} toggleFilter={search.toggle} />
				)}
				{withDeletion ? <DeletionTypography amount={deletion!.amount} /> : <SearchTypography {...props} />}
				{withDeletion && <DeleteTooltip {...deletion!} />}
				{!withDeletion && !!search && iconPosition === 'right' && (
					<ToggleFilterTooltip collapsed={isCollapsed} disabled={disabled} toggleFilter={search.toggle} />
				)}
				{!!props.onExport && <ExportTooltip onExport={props.onExport} />}
				{!!props.onRefetch && <RefetchTooltip onClick={props.onRefetch} />}
				{!!props.customAction && <CustomTooltip disabled={props.disabled} {...props.customAction} />}
			</Toolbar>
			{!!search && !isCollapsed && (
				<Paper className={classes.searchForm}>
					{children}
					<IconButton
						aria-label={t('common:filter') || ''}
						disabled={disabled}
						onClick={search.toggle}
						sx={{ position: 'absolute', top: 0, right: 1, cursor: 'pointer' }}
					>
						<CloseIcon />
					</IconButton>
				</Paper>
			)}
		</Box>
	);
}

export default SearchToolbar;

const useStyles = makeStyles()((theme) => ({
	searchForm: {
		position: 'absolute',
		minHeight: '120px',
		zIndex: 1,
		top: spacing(theme, 2) * -1,
		left: spacing(theme, 2) * -1,
		right: spacing(theme, 2) * -1,
	},
	highlight:
		theme.palette.mode === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
}));
