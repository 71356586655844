import Config from 'components/Config';
// import Theme from 'components/Theme';
import './App.css';
import 'react-day-picker/dist/style.css';

function App() {
	return <Config />;
}

export default App;
