import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redirect } from 'react-router-dom';

import { Formik, FormikErrors } from 'formik';

import { Avatar, Box, CircularProgress, CssBaseline, Grid, Paper, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import wallpaper from 'assets/images/main.png';

import { setSession } from 'lib/storage';
import { login, setToken as setTokenApi } from 'lib/api';

import LoginForm from 'components/forms/Login';

import useTokenHasExpired from 'hooks/useTokenHasExpired';
import useIsLogged from 'hooks/useIsLogged';
import useLoggedUser from 'hooks/useLoggedUser';

import useLogin from './hooks/useLogin';
import useIsFirstTime from './hooks/useIsFirstTime';

import { enqueueSnackbarError } from 'lib/helpers';

function Login() {
	const { t } = useTranslation();

	const tokenExpired = useTokenHasExpired();

	const isFirstTime = useIsFirstTime();
	const logged = useIsLogged();
	const user = useLoggedUser();

	const [isSubmitting, setSubmitting] = useState<boolean>(false);
	const loginSession = useLogin();

	const initialValues: LoginFormValues = { username: '', password: '' };

	const handleSubmit = async (values: { username: string; password: string }) => {
		setSubmitting(true);
		const { username, password } = values;
		try {
			const responseAPI = await login({ username, password });

			const response: Session = { ...responseAPI, logged: true, expired: false, serverDown: false };
			setTokenApi(responseAPI.token);

			// Save in local storage
			setSession(response);

			if (!response) {
				enqueueSnackbarError(t('system:loginError'));

				return;
			}

			setSubmitting(false);

			loginSession(response);
		} catch (error) {
			enqueueSnackbarError(error, () => t('system:loginError'));
			setSubmitting(false);
		}
	};

	const validate = (values: LoginFormValues) => {
		console.warn('values: ', values);
		let errors: FormikErrors<LoginFormValues> = {};
		const { username, password } = values;
		if (username === '') {
			errors.username = t('system:errorsUsername') || 'invalid username';
		}
		if (password === '') {
			errors.password = t('system:errorsPassword') || 'invalid password';
		}
		return errors;
	};

	const loginValidated = !!logged && !!user;

	if (redirect) {
		redirect('/dashboard');
	}

	return loginValidated ? (
		<CircularProgress />
	) : (
		<Grid container component="main" sx={{ height: '100vh', textTransform: 'capitalize' }}>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				sx={{ position: 'relative', background: 'linear-gradient(#586fa2, #003979)' }}
			/>
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<Box
					sx={{
						margin: (theme) => theme.spacing(8, 4),
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<img src={wallpaper} alt="Auriga" width="80%" />
					{!isFirstTime && (
						<Avatar>
							<LockOutlinedIcon />
						</Avatar>
					)}
					<Box sx={{ mt: 2, mb: 2 }}>
						<Typography component="h1" variant="h5">
							{t(!isFirstTime ? 'system:login' : 'system:firstTimeTitle').toUpperCase()}
						</Typography>
						{isFirstTime && (
							<Typography variant="body1" color="textSecondary" component="p" sx={{ textTransform: 'none' }}>
								{t('system:firstTimeDescription')}
							</Typography>
						)}
					</Box>
					<Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
						{(props) => <LoginForm {...props} isSubmitting={isSubmitting} />}
					</Formik>
					{tokenExpired && (
						<Typography
							variant="caption"
							sx={{
								color: 'red',
								mt: 1,
								textTransform: 'none',
							}}
						>
							{t('common:tokenExpired')}
						</Typography>
					)}
				</Box>
			</Grid>
		</Grid>
	);
}

export default Login;
