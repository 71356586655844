import { paginateResponse } from 'lib/helpers';
import { useCallback, useEffect, useState } from 'react';
import useIsMobile from './useIsMobile';

type Fetch<T, P> = (data: T) => Promise<PaginateSource<P>>;

/**
 * Fetch data and paginate or concatenate depending if isMobile is in true or not.
 *
 * If isMobile is true, an infinite list is displayed, response must be concatenated into the previous response.
 *
 * If isMobile is false, a table is displayed, response must include only the elements of the current page.
 *
 * @param fetch A function that will receive paginationProp and must return a valid "PaginateResponse" with an array of elements to be displayed in the list or table
 * @param props extends PaginationProps, query search object
 * @param primaryKey The primaryKey of the fetched component
 * @param resetMobileState An optional method called only on mobile, that must return true if the state must be reset before request
 * @param cache If true, the state will not be updated
 */
function usePaginatedData<T extends PaginationProps, P>(
	fetch: Fetch<T, P>,
	props: T,
	primaryKey: keyof P,
	resetMobileState?: (props: T | null) => boolean,
	cache: boolean = false
) {
	const isMobile = useIsMobile();

	const [previousProps, setPreviousProps] = useState<T | null>(null);

	const [loading, setLoading] = useState(false);
	const [data, setCurrentAPIResponse] = useState<PaginateResponse<P>>({ elements: [], requestTotal: 0, total: 0 });

	const getData = useCallback(async () => {
		if (cache) return;
		setLoading(true);
		if (isMobile && resetMobileState?.(previousProps)) {
			setCurrentAPIResponse({ elements: [], requestTotal: 0, total: 0 });
			setPreviousProps(props);
		}
		const requestResponse = await fetch(props);
		const paginator = paginateResponse(requestResponse, primaryKey, isMobile);
		setCurrentAPIResponse(paginator);
		setLoading(false);
	}, [previousProps, cache, isMobile, resetMobileState, fetch, props, primaryKey]);

	const refetch = useCallback(
		(reset: boolean = false) => {
			if (reset) {
				setCurrentAPIResponse({
					elements: [],
					requestTotal: 0,
					total: 0,
				});
			}
			getData();
		},
		[getData]
	);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { ...data, loading, refetch };
}

export default usePaginatedData;
