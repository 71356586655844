import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { UpdateDebtAccount } from 'hooks/useDebtsAccount';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { acceptanceStatus, agreements } from 'types/client.struct';

type Props = {
	editing: boolean;
	saving: boolean;
	debt: Debt;
	sendContract: (debtId: string) => Promise<void>;
	index: number;
	update: UpdateDebtAccount;
};

function AdditionalData({ editing, saving, debt, sendContract, index, update }: Props) {
	const { t } = useTranslation();

	return (
		<Grid container spacing={2} sx={{ mt: 2, p: 2 }}>
			<Grid xs={10} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<Typography variant="h6">{t('common:additionalData')}</Typography>
			</Grid>

			{!editing && (
				<>
					<Grid xs={3}>
						<Typography variant="caption">{t('common:agreement')}</Typography>
					</Grid>

					<Grid xs={3}>
						<Typography variant="caption">{t('common:status')}</Typography>
					</Grid>

					<Grid xs={4}>
						<Typography variant="caption">{t('common:contractSent')}</Typography>
					</Grid>

					<Grid xs={3}>{t(debt.agreement)}</Grid>

					<Grid xs={3}>{t(debt.acceptanceStatus)}</Grid>

					<Grid xs={4}>
						{debt.contractSent && (
							<Typography variant="body2">
								<strong>{moment(debt.contractSent).format('DD/MM/YYYY hh:mm')}</strong>
								{debt.acceptanceStatus === 'pending' && (
									<Button onClick={() => sendContract(debt._id || '')} variant="contained" sx={{ ml: 1 }}>
										{t('common:resendContract')}
									</Button>
								)}
							</Typography>
						)}
						{debt.acceptanceStatus === 'draft' && (
							<Button
								variant="contained"
								onClick={() => sendContract(debt._id || '')}
								disabled={
									saving ||
									(debt.agreement === 'payment_plan' &&
										(!debt.scheduledPayments || debt.scheduledPayments.length === 0))
								}
							>
								{t('common:sendContract')}
							</Button>
						)}
					</Grid>
				</>
			)}

			{!editing && debt.agreement === 'payment_plan' && (
				<>
					<Grid xs={3}>
						<Typography variant="caption">{t('common:installmentCount')}</Typography>
					</Grid>

					<Grid xs={3}>
						<Typography variant="caption">{t('common:monthlyInterest')}</Typography>
					</Grid>

					<Grid xs={4}>
						<Typography variant="caption">{t('common:firstPaymentDate')}</Typography>
					</Grid>

					<Grid xs={3}>
						{debt.installmentCount} {t('common:installments')}
					</Grid>

					<Grid xs={3}>{debt.interestRate} %</Grid>

					<Grid xs={2}>{moment(debt.dueDate).format('DD/MM/YYYY')}</Grid>
				</>
			)}

			{!editing && debt.agreement !== 'payment_plan' && (
				<>
					<Grid xs={12}>
						<Typography variant="caption">{t('common:dueDate')}</Typography>
					</Grid>

					<Grid xs={2}>{moment(debt.dueDate).format('DD/MM/YYYY')}</Grid>
				</>
			)}

			{editing && (
				<>
					<Grid xs={5} sx={{ mt: 2 }}>
						<FormControl fullWidth>
							<InputLabel id="agreement-label">{t('common:agreement')}</InputLabel>
							<Select
								labelId="agreement-label"
								id="agreement-select"
								variant="outlined"
								value={debt.agreement}
								label={t('common:agreement')}
								disabled={!editing || saving}
								onChange={(ev) => {
									update(index, 'agreement', ev.target.value as 'payment_plan' | 'invoice');
								}}
							>
								{agreements.map((agreement) => {
									return (
										<MenuItem value={agreement} key={agreement}>
											{t(`common:${agreement}`)}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid xs={5} sx={{ ml: 2, mt: 2 }}>
						<FormControl fullWidth>
							<InputLabel id="acceptanceStatus-label">{t('common:status')}</InputLabel>
							<Select
								variant="outlined"
								labelId="acceptanceStatus-label"
								id="acceptanceStatus-select"
								value={debt.acceptanceStatus}
								label={t('common:status')}
								disabled={!editing || saving}
								onChange={(ev) => {
									update(index, 'acceptanceStatus', ev.target.value as 'pending' | 'accepted' | 'rejected');
								}}
							>
								{acceptanceStatus.map((status) => {
									return (
										<MenuItem value={status} key={status}>
											{t(`common:${status}`)}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>

					{debt.agreement === 'payment_plan' && (
						<Grid xs={3} sx={{ mt: 2 }}>
							<TextField
								id="installmentCount"
								label={t('common:installmentCount')}
								variant="outlined"
								inputProps={{ type: 'number', min: 1, step: 1 }}
								value={debt.installmentCount}
								disabled={!editing || saving}
								onChange={(ev) => {
									update(index, 'installmentCount', Number(ev.currentTarget.value));
								}}
							/>
						</Grid>
					)}
					{debt.agreement === 'payment_plan' && (
						<Grid xs={3} sx={{ mt: 2 }}>
							<TextField
								id="interestRate"
								label={t('common:interest')}
								variant="outlined"
								inputProps={{ type: 'number', min: 1 }}
								value={debt.interestRate}
								disabled={!editing || saving}
								onChange={(ev) => {
									update(index, 'interestRate', Number(ev.currentTarget.value));
								}}
							/>
						</Grid>
					)}
					<Grid xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
						<DatePicker
							sx={{ mt: 2 }}
							format="DD/MM/YYYY"
							label={t(debt.agreement === 'payment_plan' ? 'firstPaymentDate' : 'dueDate')}
							value={debt.dueDate ? moment(debt.dueDate) : moment()}
							onChange={(dueDate) => {
								update(index, 'dueDate', dueDate?.toDate() ?? new Date());
							}}
							disabled={!editing || saving}
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
}

export default AdditionalData;
