import { Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { UpdateDebtAccount } from 'hooks/useDebtsAccount';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
	editing: boolean;
	saving: boolean;
	debt: Debt;
	index: number;
	update: UpdateDebtAccount;
};

function Document({ editing, saving, debt, index, update }: Props) {
	const { t } = useTranslation();

	return (
		<Grid container spacing={2} sx={{ mt: 2, p: 2 }}>
			<Grid xs={10} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<Typography variant="h6">{t('common:documentTitle')}</Typography>
			</Grid>

			{!editing && (
				<>
					<Grid xs={3}>
						<Typography variant="caption">{t('common:documentType')}</Typography>
					</Grid>

					<Grid xs={3}>
						<Typography variant="caption">{t('common:invoiceNumber')}</Typography>
					</Grid>

					<Grid xs={3}>
						<Typography variant="caption">{t('common:receipt')}</Typography>
					</Grid>

					<Grid xs={3}>
						<Typography variant="caption">{t('common:documentDate')}</Typography>
					</Grid>

					<Grid xs={3}>{debt.document?.documentType ?? ' - '}</Grid>

					<Grid xs={3}>{debt.document?.invoiceNumber ?? ' - '}</Grid>

					<Grid xs={3}>{debt.document?.receipt ?? ' - '}</Grid>

					<Grid xs={3}>
						{!!debt.document && !!debt.document.date ? moment(debt.document?.date).format('DD/MM/YYYY') : ' - '}
					</Grid>
				</>
			)}

			{editing && (
				<>
					<Grid xs={3} sx={{ mt: 2 }}>
						<TextField
							id="documentType"
							label={t('common:documentType')}
							variant="outlined"
							value={debt.document?.documentType || ''}
							disabled={!editing || saving}
							onChange={(ev) => {
								update(index, 'document', {
									...(debt.document || {}),
									documentType: ev.target.value,
								} as Debt['document']);
							}}
						/>
					</Grid>

					<Grid xs={3} sx={{ mt: 2 }}>
						<TextField
							id="invoiceNumber"
							label={t('common:invoiceNumber')}
							variant="outlined"
							value={debt.document?.invoiceNumber || ''}
							disabled={!editing || saving}
							onChange={(ev) => {
								update(index, 'document', {
									...(debt.document || {}),
									invoiceNumber: ev.target.value,
								} as Debt['document']);
							}}
						/>
					</Grid>

					<Grid xs={3} sx={{ mt: 2 }}>
						<TextField
							id="receipt"
							label={t('common:receipt')}
							variant="outlined"
							value={debt.document?.receipt || ''}
							disabled={!editing || saving}
							onChange={(ev) => {
								update(index, 'document', {
									...(debt.document || {}),
									receipt: ev.target.value,
								} as Debt['document']);
							}}
						/>
					</Grid>

					<Grid xs={3} sx={{ display: 'flex', flexDirection: 'column' }}>
						<DatePicker
							sx={{ mt: 2 }}
							format="DD/MM/YYYY"
							label={t('documentDate')}
							value={debt.document?.date ? moment(debt.document.date) : moment()}
							onChange={(date) => {
								update(index, 'document', {
									...(debt.document || {}),
									date: date?.toDate() ?? new Date(),
								} as Debt['document']);
							}}
							disabled={!editing || saving}
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
}

export default Document;
