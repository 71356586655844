import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemText, TableHead, TableRow, TableCell, Button, Box, LinearProgress, IconButton } from '@mui/material';

import { rowsPerPageOptions } from 'config/constants';

import { fetchPeople } from 'lib/models/person';
import { getStringFromData } from 'lib/helpers';
import { reducer } from 'lib/queryReducer';

import usePaginatedData from 'hooks/usePaginatedData';
import useURLQuery from 'hooks/useURLQuery';
import useQuery, { Query, defaultQuery, isQuery } from 'hooks/useQuery';

import SearchToolbar from 'components/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable';
import FullLayout from 'components/layouts/FullLayout';
import Phone from 'components/Phone';
import Email from 'components/Email';

import MobileItem from './MobileItem';
import { Add } from '@mui/icons-material';

type Props = {
	type: PersonType;
	refetchKey?: string;
	showPersonAlternativeEmail?: (person: Person | null) => void;
	showPersonNumber?: (person: Person | null) => void;
} & (
	| {
			onAddPerson: (person: Person) => void;
			onSelected?: Dispatch<SetStateAction<Person | null>>;
	  }
	| {
			onAddPerson?: (person: Person) => void;
			onSelected: Dispatch<SetStateAction<Person | null>>;
	  }
);

function PeopleTable({
	type,
	onAddPerson,
	onSelected,
	refetchKey,
	showPersonAlternativeEmail,
	showPersonNumber,
}: Props) {
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useURLQuery(isQuery, defaultQuery);

	const [{ order, page, rowsPerPage, search: searchValue }, dispatch] = useReducer(reducer, searchParams);

	const query = useQuery(searchValue, order, page, rowsPerPage);

	/**
	 * Checks if the search value was updated, if so it clears the mobile state
	 */
	const resetMobileState = useCallback((prev: Query | null) => !prev || prev.search !== searchValue, [searchValue]);

	const searchObject = useMemo(() => ({ ...query, type }), [query, type]);

	const {
		loading,
		elements: people,
		total,
		requestTotal,
		refetch,
	} = usePaginatedData(fetchPeople, searchObject, '_id', resetMobileState);

	useEffect(() => {
		refetch();
	}, [refetch, refetchKey]);

	const onEdit = useCallback(
		(selected: Person) => {
			if (!onAddPerson) {
				onSelected!(selected);
			} else {
				onAddPerson(selected);
			}
		},
		[onAddPerson, onSelected],
	);

	const search = useCallback(
		(payload: string) => {
			dispatch({ type: 'SetSearch', payload });
		},
		[dispatch],
	);

	useEffect(() => {
		const queryString = `query=${getStringFromData(query)}`;
		setSearchParams(queryString);
	}, [query, setSearchParams]);

	return (
		<FullLayout>
			{loading && <LinearProgress />}

			<SearchToolbar onSearch={(value) => search(value)} live={false} initialValue={query.search} />

			<ResponsiveTable
				elements={people}
				list={{
					primaryKey: '_id',
					infiniteScroll: {
						endOfList: requestTotal < rowsPerPage,
						onEndReached: () => dispatch({ type: 'SetPage', payload: page + 1 }),
					},
					renderListItemText: (person) => {
						return <ListItemText primary={<MobileItem person={person} onEdit={onEdit} />} />;
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:name')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:email')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:phone')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:actions')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (person) => {
						return (
							<TableRow tabIndex={-1} key={person._id}>
								<TableCell>{person.name}</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										<Email email={person.email} />
										{person.alternative_email &&
											person.alternative_email.length > 0 &&
											person.alternative_email[0] !== '' &&
											showPersonAlternativeEmail && (
												<IconButton
													onClick={() => showPersonAlternativeEmail(person)}
													sx={{
														padding: '2px',
														color: 'inherit',
														'&:hover': {
															borderRadius: '0',
														},
													}}
												>
													<Add />
												</IconButton>
											)}
									</Box>
								</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										<Phone phone={person.phone_number?.[0] ?? ''} />
										{person.phone_number &&
											person.phone_number.length > 1 &&
											person.phone_number[0] !== '' &&
											showPersonNumber && (
												<IconButton
													onClick={() => showPersonNumber(person)}
													sx={{
														padding: '2px',
														color: 'inherit',
														'&:hover': {
															borderRadius: '0',
														},
													}}
												>
													<Add />
												</IconButton>
											)}
									</Box>
								</TableCell>
								<TableCell>
									<Box>
										<Button onClick={() => onEdit(person)} variant="outlined">
											{t(onAddPerson ? 'common:add' : 'common:edit')}
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: (_, payload) => dispatch({ type: 'SetPage', payload }),
						onRowsPerPageChange: ({ target }) => {
							return dispatch({ type: 'SetRowsPerPage', payload: parseInt(target.value, 10) });
						},
					},
				}}
			/>
		</FullLayout>
	);
}

export default PeopleTable;
