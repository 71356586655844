import { fetchUserById } from 'lib/models/users';
import { useEffect, useMemo, useState } from 'react';

import useLoggedUserDocument from 'hooks/useLoggedUserDocument';

import useUserId from './useUserId';

type UseUserResponseType =
	| {
			loading: false;
			user: Omit<User, '_id'> & { _id: string; fullName: string };
			fromParams: boolean;
			error: false;
	  }
	| {
			loading: true;
			user: null;
			fromParams: boolean;
			error: false;
	  }
	| {
			loading: false;
			user: null;
			fromParams: boolean;
			error: true;
	  };

/**
 * Returns the users associated with the id received in parameter or the logged user
 */
function useUser() {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [user, setUser] = useState<User | null>();
	const { id: userId, fromParams } = useUserId();
	const loggedUser = useLoggedUserDocument();

	useEffect(() => {
		async function getUser() {
			if (userId && userId !== 'new' && fromParams) {
				const response = await fetchUserById(userId);
				if (response) {
					setUser(response);
				} else {
					setError(true);
				}
			} else {
				setUser(loggedUser);
			}
			setLoading(false);
		}

		getUser();
	}, [userId, loggedUser, fromParams]);

	const fullName = `${(user?.last_name ?? '').toUpperCase() ?? ''} ${(user?.first_name ?? '').toUpperCase()}`;

	const data: UseUserResponseType = useMemo(() => {
		const { _id = '' } = user || {};
		return !loading && !!user
			? {
					loading: false,
					error: false,
					user: { ...user, _id, fullName },
					fromParams,
			  }
			: error
			? { loading: false, error: true, user: null, fromParams }
			: { loading: true, error: false, user: null, fromParams };
	}, [user, loading, fullName, fromParams, error]);

	return data;
}

export default useUser;
