import { Accordion, AccordionDetails, Typography, Paper, Toolbar, Box, LinearProgress, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import useDebtsAccount from 'hooks/useDebtsAccount';
import { currency, getErrorMessage } from 'lib/helpers';
import { useState } from 'react';
import DebtsAdd from 'components/DebtsAdd/DebtsAdd';
import { importDebtors, sendDebtAgreement, updateDebt, updateDebtWithFile } from 'lib/models/clients';
import ImportIcon from 'components/icons/ImportIcon';
import Uploader from 'components/Uploader';

import PaymentPlan, { getPayments } from './PaymentPlan';
import useClient from 'hooks/useClient';
import { updatePerson } from 'lib/models/person';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar';
import DebtorNotes from './DebtorNotes';
import Summary from './Summary';
import DebtData from './DebtData';
import AdditionalData from './AdditionalData';
import Notes from './Notes';
import Document from './Document';
import DebtorModal from './DebtorModal';
import DebtorDaySendEmail from './DebtorDaySendEmail';
import DebtorDayResendEmail from './DebtorDayResendEmail';

type Props = {
	code: string;
	debt?: string;
	clientData: Client;
	agreement: Agreement;
};

type StateSnackbar = {
	openSnackbar: boolean;
	message: string;
	color: string;
};

function DebtsAccountTable({ code, debt, agreement }: Props) {
	const [saving, setSaving] = useState(false);
	const [importing, toggleImport] = useState(false);
	const [editingId, setEditing] = useState<null | string>(null);
	const [showDebtor, setShowDebtor] = useState<Debt['debtor'] | null>(null);

	const { debtsAccount, update, loading, error, refetch } = useDebtsAccount(code);
	const clientData = useClient();
	const [saveIndex, setSaveIndex] = useState<number>(0);
	const [saveNoteIndex, setSaveNoteIndex] = useState<number | null>(null);
	const [noteType, setNoteType] = useState<string>('save');
	const [showModalNotes, setShowModalNotes] = useState<boolean>(false);
	const [typeErrorNote, setTypeErrorNote] = useState<boolean>(true);

	const type = 'debtor';
	const { t } = useTranslation();
	const [detailsSnackbar, setDetailsSnackbar] = useState<StateSnackbar>({
		openSnackbar: false,
		message: '',
		color: 'success',
	});

	const { openSnackbar, message, color } = detailsSnackbar;

	const [debtId, setDebtId] = useState<string | undefined | null>(debt);

	const handleClick = (message: string, color: string) => {
		setDetailsSnackbar({ openSnackbar: true, message, color });
	};

	const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setDetailsSnackbar({ ...detailsSnackbar, openSnackbar: false });
	};

	const showPersonNotes = (newTypeNote: string, newIndexNote: number | null) => {
		setSaveNoteIndex(newIndexNote);
		setNoteType(newTypeNote);
		setShowModalNotes(true);
	};

	const total = (debtsAccount?.debts ?? []).reduce((acc, current) => {
		return acc + (current.acceptanceStatus === 'accepted' ? current.totalAmount : 0);
	}, 0);

	const totalWithInterest = (debtsAccount?.debts ?? []).reduce((acc, current) => {
		return acc + (current.acceptanceStatus === 'accepted' ? current.totalAmount * (current.interestRate / 100) : 0);
	}, 0);

	const totalPaid = (debtsAccount?.debts ?? []).reduce((acc, current) => {
		return (
			acc +
			(current.agreement === 'invoice'
				? current.totalAmount - (current.balance || 0)
				: current.acceptanceStatus === 'accepted'
				? current.scheduledPayments.reduce((paymentAcc, payment) => {
						return paymentAcc + payment.amountPaid;
				  }, 0)
				: 0)
		);
	}, 0);

	const totalDue = (debtsAccount?.debts ?? []).reduce((acc, current) => {
		return (
			acc +
			(current.acceptanceStatus === 'accepted'
				? current.agreement === 'invoice'
					? current.balance || current.totalAmount
					: current.totalAmount -
					  current.scheduledPayments.reduce((paymentAcc, payment) => {
							return paymentAcc + payment.amountPaid;
					  }, 0)
				: 0)
		);
	}, 0);

	const onUpdateDebtor = async (debtor: Person) => {
		setTypeErrorNote(true);
		try {
			await updatePerson(debtor);
			handleClick(t('clients:notesSuccess'), 'success');
		} catch (error) {
			console.warn(error);
			handleClick(t('system:requestError'), 'success');
		}
	};

	async function onUpdateDebt(index: number, updatedDebt: Debt) {
		const clientId = clientData.client?._id;
		const accountCode = debtsAccount?.code;
		const debtId = debtsAccount?.debts[index]?._id;
		setTypeErrorNote(false);
		if (clientId && debtId && accountCode) {
			try {
				await updateDebt(clientId, accountCode, debtId, updatedDebt);
				setEditing(null);
				refetch();
				handleClick(t('clients:debtsAccountSuccess'), 'success');
			} catch (error) {
				console.warn(error);
				handleClick(t('system:requestError'), 'error');
			}
		}
		setSaving(false);
	}

	async function importFile(file: File, overwrite: boolean = false): Promise<ParseCsvFileResult<Client>> {
		try {
			setSaving(true);
			const result = await importDebtors(clientData.client._id!, code, agreement, overwrite, file);
			setSaving(false);
			setTimeout(() => refetch());
			return result;
		} catch (error) {
			console.warn(error);
			setSaving(false);
			return {
				elements: [],
				errors: [],
				error: getErrorMessage(error),
				success: false,
				warnings: [],
			};
		}
	}

	const updatedNotesList = async (debt: Person) => {
		try {
			await onUpdateDebtor(debt);
			update(saveIndex, 'debtor', debt);
			setShowModalNotes(false);
		} catch (error) {
			console.warn(error);
		}
	};

	const uploadFile = async (debtId: string, file: File, index: number) => {
		try {
			if (!clientData.client._id) return;
			const response = await updateDebtWithFile(clientData.client._id, code, debtId, file, index);
			return response;
		} catch (err) {
			console.warn(error);
			return { file: null, extension: null };
		}
	};

	/**
	 * Generates the scheduled payments for the received dates and saves it into the database
	 */

	async function updated(index: number, debt: Debt) {
		try {
			setSaving(true);
			const scheduledPayments = debt.scheduledPayments;
			const clientId = clientData.client?._id;
			const accountCode = debtsAccount?.code;
			const debtId = debtsAccount?.debts[index]?._id;
			if (clientId && debtId && accountCode) {
				await updateDebt(clientId, accountCode, debtId, { scheduledPayments });
				setEditing(null);
				refetch();
			}
		} catch (error) {
			console.warn(error);
		}
		setSaving(false);
	}

	async function generate(index: number, debt: Debt, updatedScheduledPayments: boolean) {
		if (updatedScheduledPayments) {
			return updated(index, debt);
		}
		try {
			setSaving(true);
			const { allDates } = getPayments(debt);
			const paymentTotal = debt.totalAmount / debt.installmentCount;
			const amount = paymentTotal + paymentTotal * (debt.interestRate / 100);
			const scheduledPayments = allDates.reduce((payments, current) => {
				return [
					...payments,
					...current.map((el, index) => {
						const payment: ScheduledPayment = {
							amount,
							amountPaid: 0,
							file:
								payments[index] && payments[index].file !== undefined && payments[index].file !== null
									? payments[index].file
									: null,
							date: el.toDate(),
						};
						return payment;
					}),
				];
			}, [] as ScheduledPayment[]);
			const clientId = clientData.client?._id;
			const accountCode = debtsAccount?.code;
			const debtId = debtsAccount?.debts[index]?._id;
			if (clientId && debtId && accountCode) {
				await updateDebt(clientId, accountCode, debtId, { scheduledPayments });
				setEditing(null);
				refetch();
			}
		} catch (error) {
			console.warn(error);
		}
		setSaving(false);
	}

	async function sendContract(debtId: string) {
		try {
			setSaving(true);
			const clientId = clientData.client?._id ?? '';
			await sendDebtAgreement(clientId, code, debtId);
			refetch();
		} catch (error) {
			console.warn(error);
		}
		setSaving(false);
	}

	const handleDeleteClick = (index: number, debt: Person) => {
		if (!debt || !debt.notes) {
			return;
		}
		const notesList = [...debt.notes];
		notesList.splice(index, 1);
		const reviewUser = { ...debt, notes: notesList };
		return updatedNotesList(reviewUser);
	};

	const now = moment();

	/**
	 * Finds the nearest due date
	 */
	const nextDueDate = (debtsAccount?.debts ?? []).reduce((debtDate, currentDebt) => {
		const currentDate =
			currentDebt.agreement === 'payment_plan' &&
			Array.isArray(currentDebt.scheduledPayments) &&
			currentDebt.scheduledPayments.length > 0
				? getNearestPaymentDate(currentDebt)
				: moment(currentDebt.dueDate);
		return debtDate.isBefore(now) ? currentDate : debtDate.isBefore(currentDate) ? debtDate : currentDate;
	}, moment());

	/**
	 * Finds the nearest payment date
	 */
	function getNearestPaymentDate(currentDebt: Debt) {
		return currentDebt.scheduledPayments.reduce((paymentDate, currentPayment) => {
			const currentPaymentDate = moment(currentPayment.date);
			return paymentDate.isBefore(now)
				? currentPaymentDate
				: paymentDate.isBefore(currentPaymentDate)
				? paymentDate
				: currentPaymentDate;
		}, moment());
	}

	function toggleDebtor(current: Debt['debtor'] | null) {
		if (showDebtor && current && showDebtor.email === current.email) {
			setShowDebtor(null);
		} else {
			setShowDebtor(current);
		}
	}

	let lastDebtor: Person | null = null;

	const warning =
		agreement === 'payment_plan' ||
		!!debtsAccount?.debts.find((debt) => {
			const found = !!lastDebtor && debt.debtor._id !== lastDebtor;
			lastDebtor = debt.debtor._id;
			return found;
		});

	function onEnded() {
		toggleImport(false);
		refetch?.();
	}

	return (
		<Paper elevation={1}>
			<ImportIcon onImport={() => toggleImport(true)} title={t('clients:importDebtors')} />

			{(loading || saving) && <LinearProgress />}

			<Uploader
				id="debtors-file"
				name="debtors-file"
				accept=".csv"
				onCancel={onEnded}
				onEnded={onEnded}
				openDialog={importing}
				importData={importFile}
				warning={t('clients:warningDebtorsUploader')}
				exampleFile={
					'https://docs.google.com/spreadsheets/d/1ruUjGudKawI1A8WRzgAdqnqq_5NvP4MxC3lzHj5zgGE/edit?usp=drive_link'
				}
				errorKey="code"
				errorKeyName="code"
				showOverwrite={agreement === 'invoice'}
			/>

			<Box style={{ position: 'relative', top: typeErrorNote ? '200px' : '' }}>
				<CustomSnackbar
					openSnackbar={openSnackbar}
					handleClose={handleClose}
					message={message}
					color={color}
					positionVertical="top"
				/>
			</Box>

			{!loading && !error && !!debtsAccount && (
				<Box>
					<Toolbar
						sx={{
							pl: { sm: 2 },
							pr: { xs: 1, sm: 1 },
							bgcolor: (theme) => theme.palette.primary.main,
							color: 'white',
						}}
					>
						<Typography
							sx={{ flex: '1 1 100%', textTransform: 'capitalize' }}
							variant="h6"
							id="tableTitle"
							component="div"
						>
							{debtsAccount.code}
							<DebtsAdd
								type={type}
								client={clientData.client}
								debtAccountCode={debtsAccount.code}
								onPersonAdded={() => refetch()}
								agreement={agreement}
							/>
						</Typography>
					</Toolbar>

					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ p: 2, pb: 4 }}>
						<Grid xs={6}>
							<Typography variant="h6">{debtsAccount.description}</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								{t('common:nextDueDate')}: {nextDueDate.format('DD/MM/YYYY')}
							</Typography>
						</Grid>
						<Grid xs={6}>
							<Typography variant="body1" sx={{ mt: 2 }}>
								<strong>{t('common:capitalValue', { value: currency(total) })}</strong>
								<strong>{` (${t('common:capitalInterestValue', { value: currency(totalWithInterest) })})`}</strong>
							</Typography>
							{/* @TODO: Add paid and unpaid values */}
							<Typography variant="body1">
								<strong>
									{t('common:paid').toUpperCase()}: {currency(totalPaid)}
								</strong>
							</Typography>
							<Typography variant="body1">
								<strong>
									{t('common:unpaid').toUpperCase()}: {currency(totalDue)}
								</strong>
							</Typography>
						</Grid>
						<Grid xs={12} sx={{ p: 2 }}>
							{warning && agreement === 'invoice' && (
								<Typography variant="body1" color="error">
									{t('clients:differentPersons')}
								</Typography>
							)}
							{warning && agreement === 'payment_plan' && (
								<Typography variant="body1" color="error">
									{t('clients:paymentPlantWarning')}
								</Typography>
							)}
						</Grid>

						{debtsAccount.debts.map((debt: Debt, index) => {
							const editing = !!editingId && debt._id === editingId;

							return (
								<Grid xs={12} key={index}>
									<Accordion
										expanded={debtId ? debt._id === debtId : undefined}
										onChange={() => {
											if (debtId && debt._id === debtId) setDebtId(undefined);
											else setDebtId(debt._id);

											return setSaveIndex(index);
										}}
										sx={{ position: 'relative' }}
									>
										<Summary debt={debt} />

										<AccordionDetails>
											<DebtData
												toggleDebtor={toggleDebtor}
												debt={debt}
												editing={editing}
												index={index}
												onUpdateDebt={onUpdateDebt}
												saving={saving}
												setEditing={setEditing}
												update={update}
												client={clientData.client}
												debtAccountCode={debtsAccount.code}
												onPersonAdded={() => refetch()}
												handleClick={handleClick}
											/>

											<AdditionalData
												debt={debt}
												index={index}
												editing={editing}
												saving={saving}
												sendContract={sendContract}
												update={update}
											/>

											<Document debt={debt} editing={editing} index={index} saving={saving} update={update} />
											<DebtorDaySendEmail debt={debt} updated={(debt) => onUpdateDebt(index, debt)} />
											<DebtorDayResendEmail debt={debt} updated={(debt) => onUpdateDebt(index, debt)} />
											{debt.agreement === 'payment_plan' && (
												<PaymentPlan
													index={index}
													debt={debt}
													editing={editing}
													generate={generate}
													disabled={editing || saving}
													uploadFile={uploadFile}
												/>
											)}

											<Divider sx={{ my: 2, width: '100%' }} />

											<Notes
												editing={editing}
												handleDeleteClick={handleDeleteClick}
												showPersonNotes={showPersonNotes}
												debt={debt}
											/>

											{!!showDebtor && showDebtor.email === debt.debtor.email && (
												<DebtorModal debtor={showDebtor} onClose={() => setShowDebtor(null)} />
											)}
										</AccordionDetails>
									</Accordion>
								</Grid>
							);
						})}
					</Grid>
				</Box>
			)}
			{!loading && !error && !debtsAccount && <Typography>NOT FOUND</Typography>}
			{!loading && !!error && <Typography>ERROR</Typography>}

			<DebtorNotes
				onPersonUpdate={updatedNotesList}
				setShowModalNotes={setShowModalNotes}
				showModalNotes={showModalNotes}
				userPerson={debtsAccount?.debts[saveIndex]?.debtor || null}
				noteIndex={saveNoteIndex}
				type={noteType}
			/>
		</Paper>
	);
}

export default DebtsAccountTable;
