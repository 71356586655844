import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type DebtorDaySendEmailProps = {
	debt: Debt;
	updated: (updatedDebt: Debt) => void;
};

const DebtorDaySendEmail = ({ debt, updated }: DebtorDaySendEmailProps) => {
	const [changeDayEdit, setChangeDayEdit] = useState<boolean>(false);
	const [daySendEmail, setDaySendEmail] = useState<number>(
		debt.emailSendingDate === undefined || debt.emailSendingDate === null || Number.isNaN(debt.emailSendingDate)
			? 1
			: debt.emailSendingDate,
	);

	const { t } = useTranslation();

	const handleDaySendEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newValue = parseInt(event.target.value);
		debt.emailSendingDate = newValue;
		setDaySendEmail(newValue);
	};

	const onUpdated = () => {
		debt.emailSendingDate = daySendEmail;
		updated(debt);
		setChangeDayEdit(false);
	};
	return (
		<Grid container spacing={2} sx={{ mt: 2, p: 2 }}>
			<Grid xs={12}>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<Typography variant="h6">{t('common:dateSendEmail')}</Typography>
				</Box>
				{changeDayEdit ? (
					<Box sx={{ display: 'flex' }}>
						<TextField
							type="number"
							value={daySendEmail}
							onChange={handleDaySendEmail}
							sx={{
								width: '15ch',
								padding: '0px',
								mt: 1,
							}}
							inputProps={{
								max: Number(moment(debt.dueDate).format('D')) - 1,
								min: 0,
							}}
						/>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 1 }}>
							<IconButton
								onClick={() => setChangeDayEdit(false)}
								sx={{ padding: 0, fontSize: 16, '&:hover': { backgroundColor: 'transparent' } }}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
							<IconButton
								onClick={onUpdated}
								sx={{ padding: 0, fontSize: 16, '&:hover': { backgroundColor: 'transparent' } }}
							>
								<SaveIcon fontSize="small" />
							</IconButton>
						</Box>
					</Box>
				) : (
					<Box sx={{ display: 'flex' }}>
						{!isNaN(Number(debt.emailSendingDate)) && (
							<TextField
								type="number"
								value={daySendEmail}
								disabled={true}
								sx={{
									width: '15ch',
									padding: '0px',
									mt: 1,
									mr: 1,
								}}
							/>
						)}
						<Button variant="contained" sx={{ mt: 1 }} onClick={() => setChangeDayEdit(true)}>
							{t(!isNaN(Number(debt.emailSendingDate)) ? 'common:changeDay' : 'common:assingDay')}
						</Button>
					</Box>
				)}
				<Typography variant="body2" sx={{ mt: 1 }}>
					{t('clients:dateEmailSendDescription')}
				</Typography>
				<Typography variant="body2" sx={{ mt: 1 }}>
					<strong>
						{debt.agreement === 'payment_plan' && t('clients:dateEmailSchedulePayments')}
						{debt.agreement !== 'payment_plan' && t('clients:dateEmailScheduleCash')}
					</strong>
				</Typography>
			</Grid>
		</Grid>
	);
};

export default DebtorDaySendEmail;
