import { NODE_ENV } from 'config/constants';
import { useMemo } from 'react';

function useInDev() {
	// @ts-ignore
	const _inDEV = NODE_ENV === 'development';

	const inDev = useMemo(() => _inDEV, [_inDEV]);

	return inDev;
}

export default useInDev;
