import { useTranslation } from 'react-i18next';

import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import Email from './Email';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

type Props = {
	tableScheduledPayment: TableScheduledPayment;
};

function MobileItemDebt({ tableScheduledPayment }: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<Accordion defaultExpanded={false} variant="outlined">
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="DebtCollection-content"
				id="DebtCollection-header"
			>
				<Typography>{tableScheduledPayment.code}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: 8,
						justifyContent: 'space-between',
						alignItems: 'center',
						pt: 8,
					}}
				>
					{t('common:code')}: {tableScheduledPayment.code}
				</Box>

				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('people:name')}: {tableScheduledPayment.debtor.name}
				</Box>

				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('people:email')}: {tableScheduledPayment.debtor.email}
				</Box>
				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					{t('people:debt')}:{' '}
					{t(`common:month_${moment(tableScheduledPayment.scheduledPayment.date).get('month')}`).substring(0, 3)} ($
					{(tableScheduledPayment.scheduledPayment.amount - tableScheduledPayment.scheduledPayment.amountPaid).toFixed(
						2
					)}
					)
				</Box>
				<Box sx={{ mt: 1, verticalAlign: 'center' }}>
					<Button
						onClick={() =>
							navigate(
								`/clients/${tableScheduledPayment._id}/agreement/${tableScheduledPayment.debt.agreement}/account/${tableScheduledPayment.code}`
							)
						}
						variant="outlined"
					>
						{t(`common:go`)}
					</Button>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}

export default MobileItemDebt;
