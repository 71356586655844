import { Add, Edit, ExpandMore, Remove } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, List, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
	editing: boolean;
	debt?: Debt;
	showPersonNotes: (newTypeNote: string, newIndexNote: number | null) => void;
	handleDeleteClick: (index: number, debt: Person) => Promise<void> | undefined;
};
function Notes({ editing, showPersonNotes, debt, handleDeleteClick }: Props) {
	const { t } = useTranslation();

	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<div>{t('common:notes')}</div>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				{!editing && (
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<IconButton onClick={() => showPersonNotes('save', null)} color="primary">
							<Add />
						</IconButton>
					</div>
				)}
				<List>
					{debt?.debtor?.notes?.map((element: string, noteIndex: number) => (
						<Stack spacing={2}>
							<Box key={noteIndex}>
								<div>
									<Box
										sx={{
											display: 'flex',
											borderRadius: '1em',
											border: '2px solid gray',
											padding: '16px',
											mt: 1,
											mb: 1,
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<Typography>{element}</Typography>
										<div>
											<IconButton color="error" onClick={() => handleDeleteClick(noteIndex, debt.debtor)}>
												<Remove />
											</IconButton>
											<IconButton color="primary" onClick={() => showPersonNotes('edit', noteIndex)}>
												<Edit />
											</IconButton>
										</div>
									</Box>
								</div>
							</Box>
						</Stack>
					))}
				</List>
			</AccordionDetails>
		</Accordion>
	);
}

export default Notes;
