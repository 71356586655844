import { Accordion, AccordionSummary, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import PersonManagement from 'components/PersonManagement';

export type ContactsProps = {
	onRemoveContact: (identifier: string) => void;
	onContactUpdate: (contact: Person) => void;
	data: Client;
	tab?: string;
};

function ContactsAccordion({ onContactUpdate, onRemoveContact, data, tab }: ContactsProps) {
	const { t } = useTranslation();

	return (
		<Accordion>
			<AccordionSummary aria-controls="Contact-content" id="Contact-header">
				<Typography>{t('common:contacts')}</Typography>
			</AccordionSummary>

			<PersonManagement
				people={data.debtCollectionInformation.contacts}
				onPersonUpdate={onContactUpdate}
				onAddPerson={onContactUpdate}
				onRemovePerson={onRemoveContact}
				type="contact"
				tab={tab}
			/>
		</Accordion>
	);
}

export default ContactsAccordion;
