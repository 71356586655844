import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import moment from 'moment';

import { getDebtCollectionDate, isDebtRangeDate } from 'components/DebtDate/DebtDate';

type Props = { date?: DebtCollectionDate | string };

function DebtDate({ date }: Props) {
	const { t } = useTranslation();

	const value = date ? getDebtCollectionDate(date) : null;
	if (!value) {
		return value;
	}
	if (isDebtRangeDate(value)) {
		return (
			<Typography>
				{value.from}
				{` `}
				{t('common:to2').toLowerCase()} {value.to}
			</Typography>
		);
	}
	return <Typography>{moment(value).format('DD/MM/YYYY')}</Typography>;
}

export default DebtDate;
