import { TextField, Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

type Props = {
	onUpdate: (key: keyof Client) => (event: any) => void;
	data: Client;
};

function MainForm({ onUpdate, data }: Props) {
	const { t } = useTranslation();

	return (
		<Box sx={{ pt: 2 }}>
			<TextField
				id="identifier"
				label={t('common:identifier')}
				fullWidth={true}
				value={data.identifier}
				variant="outlined"
				sx={{ mb: 3 }}
				onChange={onUpdate('identifier')}
			/>
			<TextField
				id="companyName"
				label={t('common:companyName')}
				fullWidth={true}
				value={data.companyName}
				variant="outlined"
				sx={{ mb: 3 }}
				onChange={onUpdate('companyName')}
			/>
			<TextField
				id="city"
				label={t('common:city')}
				fullWidth={true}
				value={data.city}
				variant="outlined"
				sx={{ mb: 3 }}
				onChange={onUpdate('city')}
			/>
			<TextField
				id="address"
				label={t('common:address')}
				fullWidth={true}
				value={data.address}
				variant="outlined"
				sx={{ mb: 3 }}
				onChange={onUpdate('address')}
			/>
			<TextField
				id="address"
				label={t('common:phone')}
				fullWidth={true}
				value={data.phone}
				variant="outlined"
				sx={{ mb: 3 }}
				onChange={onUpdate('phone')}
			/>
			<TextField
				id="website"
				label={t('common:website')}
				fullWidth={true}
				value={data.website}
				variant="outlined"
				sx={{ mb: 3 }}
				onChange={onUpdate('website')}
			/>
		</Box>
	);
}

export default MainForm;
