import React from 'react';

import { validateComponent } from 'lib/session';

import useLoggedAndRules from 'hooks/useLoggedWithRules';

type Props = AuthorizedComponentProps;

const AuthorizedComponent: React.FunctionComponent<Props> = ({ element: Component, mode, children, ...rest }) => {
	const { logged, rules } = useLoggedAndRules();
	if (logged && validateComponent(rules, { ...rest }, mode)) {
		return Component ? <Component {...rest} /> : children ? <>{children}</> : null;
	}
	return null;
};

export default AuthorizedComponent;
