import { Close } from '@mui/icons-material';
import { Box, Button, Modal } from '@mui/material';
import PeopleTable from 'components/PeopleTable';

type PersonEditModalProps = {
	open: boolean;
	onClose: () => void;
	addPerson: (person: Person) => void;

	type: PersonType;
};

function PersonAddModal({ addPerson, onClose, open, type }: PersonEditModalProps) {
	return (
		<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '90%',
					bgcolor: 'background.paper',
					border: '2px solid lightgrey',
					boxShadow: 24,
					p: 4,
					overflowY: 'auto',
					maxHeight: '90vh',
					display: 'block',
				}}
			>
				<Button onClick={onClose} variant="outlined" color="error" sx={{ position: 'absolute', top: 2, right: 2 }}>
					<Close />
				</Button>

				<Box sx={{ mt: 2 }}>
					<PeopleTable type={type} onAddPerson={addPerson} />
				</Box>
			</Box>
		</Modal>
	);
}

export default PersonAddModal;
