import { fetchClient } from 'lib/models/clients';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function useClient() {
	const { id } = useParams<{ id: string }>();

	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	const [client, setClient] = useState<Client>({
		_id: '',
		identifier: '',
		address: '',
		city: '',
		phone: '',
		companyName: '',
		debtCollectionInformation: {
			supplierPaymentDepartment: '',
			documentsToSubmit: {},
			collectionDays: null,
			paymentDays: null,
			contacts: [],
		},
		debtsAccounts: [],
		debtors: [],
		users: [],
	});

	const refetch = useCallback(async () => {
		if (id && id !== 'new') {
			const response = await fetchClient(id);

			if (!response) {
				setError(`client with id (${id}) not found`);
				setLoading(false);
				return;
			}
			setClient(response);
			setLoading(false);
			return;
		}

		setLoading(false);
	}, [id]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, error, client, refetch };
}

export default useClient;
