import { Dispatch, SetStateAction } from 'react';
import { Box, IconButton, Button, TableHead, TableRow, TableCell, AccordionDetails, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ResponsiveTable from 'components/ResponsiveTable';

import Add from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

import Email from 'components/Email';
import Phone from 'components/Phone';

type Props = {
	data: Person[];
	onRemovePerson?: (identifier: string) => void;
	showPersonAlternativeEmail: (person: Person | null) => void;
	showPersonNumber: (person: Person | null) => void;
	showPersonNotes: (person: Person | null) => void;
	setEditPerson?: Dispatch<SetStateAction<Person | null>>;
	editPerson?: Person | null;
	newPerson: () => void;
	searchPerson: Dispatch<SetStateAction<boolean>>;
};

function PersonTable({
	data,
	onRemovePerson,
	showPersonAlternativeEmail,
	showPersonNumber,
	showPersonNotes,
	setEditPerson,
	editPerson,
	newPerson,
	searchPerson,
}: Props) {
	const { t } = useTranslation();

	return (
		<AccordionDetails>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					width: '100%',
					paddingRight: 2,
				}}
			>
				{editPerson === null && <Add onClick={newPerson} sx={{ cursor: 'pointer' }} />}
				{editPerson === null && <SearchIcon onClick={() => searchPerson(true)} sx={{ cursor: 'pointer' }} />}
			</Box>
			<ResponsiveTable
				elements={data}
				list={{
					primaryKey: 'identifier',
					renderListItemText: (_) => {
						return <ListItemText primary={null} />;
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:name')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:email')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:phone')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:notes')}
								</TableCell>
								{(setEditPerson || onRemovePerson) && (
									<TableCell align="left" padding="normal">
										{t('common:actions')}
									</TableCell>
								)}
							</TableRow>
						</TableHead>
					),
					renderRow: (person) => {
						return (
							<TableRow tabIndex={-1} key={person.identifier}>
								<TableCell sx={{ mb: 1 }}>{person.name}</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										<Email email={person.email} />
										{person.alternative_email &&
											person.alternative_email.length > 0 &&
											person.alternative_email[0] !== '' && (
												<IconButton
													onClick={() => showPersonAlternativeEmail(person)}
													sx={{
														padding: '2px',
														color: 'inherit',
														'&:hover': {
															borderRadius: '0',
														},
													}}
												>
													<Add />
												</IconButton>
											)}
									</Box>
								</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										<Phone phone={person.phone_number?.[0] ?? ''} />
										{person.phone_number && person.phone_number.length > 1 && person.phone_number[0] !== '' && (
											<IconButton
												onClick={() => showPersonNumber(person)}
												sx={{
													padding: '2px',
													color: 'inherit',
													'&:hover': {
														borderRadius: '0',
													},
												}}
											>
												<Add />
											</IconButton>
										)}
									</Box>
								</TableCell>
								<TableCell>
									<Button onClick={() => showPersonNotes(person)} variant="outlined" sx={{ mr: 1, mb: 1 }}>
										{t(`common:open`)}
									</Button>
								</TableCell>
								<TableCell>
									{setEditPerson && (
										<Button onClick={() => setEditPerson(person)} variant="outlined" sx={{ mr: 1, mb: 1 }}>
											{t(`common:edit`)}
										</Button>
									)}
									{onRemovePerson && (
										<Button
											onClick={() => onRemovePerson(person.identifier)}
											variant="outlined"
											color="error"
											sx={{ mb: 1 }}
										>
											{t(`common:remove`)}
										</Button>
									)}
								</TableCell>
							</TableRow>
						);
					},
				}}
			/>
		</AccordionDetails>
	);
}

export default PersonTable;
