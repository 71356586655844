const common = {
	// Common
	name: 'Name',
	username: 'Username',
	password: 'Password',
	firstName: 'First name',
	lastName: 'Last name',
	saveNewNotes: 'Save new notes',
	email: 'Email',
	accept: 'Accept',
	cancel: 'Cancel',
	close: 'Close',
	create: 'Create',
	delete: 'Delete',
	edit: 'Edit',
	duplicate: 'Duplicate',
	upcomingMaturities: 'Upcoming maturities',
	filter: 'Filter',
	add: 'Add',
	notes: 'Notes',
	labelNotes: 'Write your note',
	general: 'General',
	go: 'Go',
	open: 'Open',
	reload: 'Reload',
	save: 'Save',
	search: 'Search',
	watch: 'Watch',
	selected: 'selected',
	upload: 'Upload',
	view: 'View',
	uploadReceipt: 'Upload receipt',
	success: 'Saved',
	confirmSuccess: 'Was saved correctly, please confirm by pressing the save button',
	download: 'Download',
	densePadding: 'Dense row',
	rowsPerPage: 'Rows per page',
	findUser: 'Find user ...',
	import: 'Import',
	importingPleaseWait: 'Please wait, importing...',
	more: 'More',
	code: 'Code',
	loading: 'Loading, please wait...',
	role: 'Role',
	role_admin: 'Administrator',
	role_panel: 'Panel user',
	role_user: 'Client',
	active: 'Active',
	inactive: 'Inactive',
	selectFile: 'Select file',
	fileError: 'File format not supported',
	importSuccess: 'File imported successfully',
	language: 'language',
	spanish: 'Spanish',
	english: 'English',
	valueTo: 'Up to',
	$lte: 'Less than or equal to',
	$gte: 'Greater than or equal to',
	backOfficeDocumentationTitle: 'Panel documentation',
	backOfficeDocumentationDescription: 'Panel usage documentation link',
	itDocumentationTitle: 'IT documentation',
	itDocumentationDescription: 'Server configuration documentation link',
	welcome:
		'Welcome {{name}}, if you need help with the panel or some server configuration you can use the links below.',
	welcomeClient: 'Welcome {{name}}',
	export: 'Export',
	tokenExpired: 'Your session has expired, please login again',
	serverIsDown: 'The API server is down, please contact IT team for more info',
	followingElementsWithError:
		'The following elements with column "{{key}}" ({{elements}}) could not be created or updated, error: {{error}}',
	status: 'Status',
	deleteTitle: 'Deletion',
	deleteText: 'Are you sure you want to delete the selected element?',
	cancelDescription: "Are you sure you wan't to return to login screen?",
	exampleFile: 'Download an example .csv file',
	account: 'account',
	account_plural: 'accounts',
	dashboard: 'dashboard',
	favorite: 'favorite',
	favorite_plural: 'favorites',
	inbox: 'inbox',
	personal_area: 'My section',
	profile: 'profile',
	user: 'user',
	user_plural: 'users',
	user_me: 'my data',
	userTitle: '{{name}}',
	resetPassword: 'Clear password',
	resetPasswordTitle: 'Clear password',
	resetPasswordText: 'Clear password of selected user?',
	creationPasswordText:
		'The first time the user logs in it will create his password which will be used in subsequent accesses.',
	client: 'client',
	client_plural: 'clients',
	loginDate: 'Last login',
	loadError: 'There was a problem trying to load the data',
	clientTitle: 'Clients',
	identifier: 'Identifier',
	companyName: 'Company name',
	company: 'Company',
	area: 'Area',
	position: 'Position',
	city: 'City',
	address: 'Address',
	phone: 'Phone',
	phoneNumer: 'Phone Number',
	alternativeEmail: 'Alternartive Email',
	associationLabel: 'Association Label',
	actions: 'Actions',
	theseAreAllEmails: 'List of email addresses',
	theseAreAllPhoneNumbers: 'List of telephone numbers',
	data: 'Data',
	contact: 'Contact',
	mode: 'Mode',
	date: 'Date',
	range: 'Range',
	from: 'From',
	to: 'To',
	to2: 'to',
	website: 'Website',
	users: 'Users',
	crm: 'CRM',
	bill: 'bill',
	bills: 'bills',
	person: 'person',
	person_plural: 'people',
	document: 'document',
	document_plural: 'documents',
	main: 'main',
	documentation: 'documentation',
	dates: 'dates',
	contacts: 'contacts',
	remove: 'remove',
	total: 'Total',
	action: 'Action',
	debtsAccount: 'Debts account',
	payment_plan: 'Payment plan',
	dateSendEmail: 'Date e-mail sent',
	changeDay: 'Change day',
	assingDay: 'Assign day',
	maturitiesOfTheDay: 'Maturities of the day',
	transfer: 'Transfer',
	invoice: 'Invoice',
	draft: 'Draft',
	pending: 'Sent',
	accepted: 'Accepted',
	rejected: 'Rejected',
	deleted: 'Deleted',
	copy: 'Copy',
	copied: 'Copied',
	copy_all: 'Copy all',
	contact_plural: 'Contacts',
	debtor_plural: 'Debtors',
	capital: 'Capital',
	capitalValue: 'CAPITAL: {{value}}',
	capitalInterestValue: 'INTEREST: {{value}}',
	debtData: 'Debt data',
	other: 'Others',
	additionalData: 'Additional data',
	agreement: 'Agreement',
	replace: 'Replace',
	acceptance: 'Agreement acceptance',
	contractSent: 'Contract sent',
	installmentCount: 'Installment count',
	interest: 'Interest',
	monthlyInterest: 'Monthly interest',
	paymentsAgreement: 'Payments agreement',
	acceptanceStatus: 'Acceptance status',
	installments: 'installments',
	description: 'Description',
	firstPaymentDate: '1st payment date',
	paymentDate: 'Payment date',
	dueDate: 'Due date',
	nextDueDates: 'Next due dates',
	month: 'Month',
	month_0: 'January',
	month_1: 'February',
	month_2: 'March',
	month_3: 'April',
	month_4: 'May',
	month_5: 'June',
	month_6: 'July',
	month_7: 'August',
	month_8: 'September',
	month_9: 'October',
	month_10: 'November',
	month_11: 'December',
	sendContract: 'Send contract',
	resendContract: 'Resend contract',
	unpaid: 'unpaid',
	paid: 'paid',
	nextDate: 'Next date',
	nextDueDate: 'Next due date',
	documentTitle: 'Document',
	documentType: 'Type',
	invoiceNumber: 'Invoice number',
	receipt: 'Receipt',
	documentDate: 'Date',
	todayDueDates: "Today's Due Dates notifications",
	lastNotification: 'Last notification',
	monthlyDueDates: 'Accumulated month-end deadlines',
	withoutNotifications: 'There are no pending notifications for today',
	importOverWriteText: 'Overwrite existing values?',
	importOverWriteWarning:
		'Warning: All elements present in database will be overwrite, and elements not present in the csv will be deleted from database',
	changeFrequency: 'Change frequency',
	assignFrequency: 'Assign frequency',
	dateResendEmail: 'Reminder frequency',
} as const;

export default common;
