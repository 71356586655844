import { Menu } from '@mui/material';

// import AccountItem from './ProfileItems/Account';
import LogoutItem from './ProfileItems/Logout';

import useLogout from 'hooks/useLogout';

type ProfileMenuProps = {
	open: boolean;
	onClose: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
};

function ProfileMenu({ open, onClose }: ProfileMenuProps) {
	const logout = useLogout();

	return (
		<Menu
			id="menu-appbar"
			sx={{ textTransform: 'capitalize' }}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={open}
			onClose={onClose}
		>
			{/* <AccountItem onClick={onClick} /> */}
			<LogoutItem onClick={logout} />
		</Menu>
	);
}

export default ProfileMenu;
