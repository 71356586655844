import { useMemo } from 'react';

export type Query = {
	search: string;
	order: 'asc' | 'desc';
	orderBy: string;
	page: number;
	rowsPerPage: number;
};

function useQuery(search: string, order: 'asc' | 'desc', page: number, rowsPerPage: number): Query {
	const data = useMemo(() => {
		const props = {
			search,
			order,
			orderBy: '_id',
			page,
			rowsPerPage,
		};
		return props;
	}, [search, order, page, rowsPerPage]);

	return data;
}

export default useQuery;

export function isQuery(data: Query): data is Query {
	return (
		!!data &&
		typeof data === 'object' &&
		typeof data.search === 'string' &&
		typeof data.order === 'string' &&
		!isNaN(data.page) &&
		!isNaN(data.rowsPerPage)
	);
}

export const defaultQuery: Query = {
	order: 'desc',
	orderBy: '_id',
	page: 0,
	rowsPerPage: 25,
	search: '',
};
