import { useReducer, useCallback, useState } from 'react';

import {
	Box,
	DialogActions,
	FormHelperText,
	IconButton,
	LinearProgress,
	Typography,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Theme,
	SxProps,
	Checkbox,
	FormGroup,
	FormControlLabel,
} from '@mui/material';

import {
	CloudUploadRounded as ImportIcon,
	CheckCircleOutline as CheckCircleOutlineIcon,
	HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';

import { useTranslation } from 'react-i18next';

import { reducer, defaultState, isImportErrors } from './reducer';
import UploadInput from 'components/UploadInput';
import { getStringFromData } from 'lib/helpers';

type UploadProps<T extends Object = any> = {
	openDialog: boolean;
	onCancel(): void;
	onEnded(response: ParseCsvFileResult<T> | null): void;
	importData: (file: File, overwrite?: boolean) => Promise<ParseCsvFileResult<T>>;
	id: string;
	name: string;
	accept: string;
	warning?: string;
	exampleFile?: string;
	errorKey?: string;
	errorKeyName?: string;
	showOverwrite?: boolean;
	overwriteDefaultTrue?: boolean;
	overwriteTitle?: string;
	overwriteDescription?: string;
};

function ItemsUpload({
	openDialog,
	importData,
	onCancel,
	onEnded,
	id,
	name,
	accept,
	warning: warningMessage,
	exampleFile,
	errorKeyName,
	errorKey = '_id',
	showOverwrite,
	overwriteDefaultTrue,
	overwriteTitle,
	overwriteDescription,
}: UploadProps) {
	const { t } = useTranslation();

	const [overwrite, setOverwrite] = useState(overwriteDefaultTrue || false);

	const [{ importing, error, errors, warning, file, imported, response }, dispatch] = useReducer(reducer, defaultState);

	const reset = useCallback(function cancel() {
		dispatch({ type: 'Reset' });
		dispatch({ type: 'SetFile', payload: null });
	}, []);

	const cancel = useCallback(
		function cancel() {
			reset();
			onCancel();
		},
		[reset, onCancel]
	);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { files } = event.target;
			if (files && files[0] && files[0].name && (files[0].name.includes('zip') || files[0].name.includes('csv'))) {
				dispatch({ type: 'SetFile', payload: files[0] });
			} else {
				dispatch({ type: 'SetError', payload: t('common:fileError') });
			}
		},
		[t]
	);

	const onAccept = useCallback(async () => {
		if (file) {
			try {
				dispatch({ type: 'SetImporting', payload: true });
				console.debug('overwrite: ', overwrite);
				const importRes = await importData(file, overwrite);
				if (importRes.success) {
					dispatch({ type: 'SetResponse', payload: importRes });
					dispatch({ type: 'SetImported', payload: true });
				} else {
					dispatch({ type: 'SetImported', payload: true });
					if (isImportErrors(importRes.errors)) {
						dispatch({ type: 'SetErrors', payload: importRes.errors });
					}
					dispatch({
						type: 'SetError',
						payload: !!importRes.error
							? importRes.error
							: t('common:followingElementsWithError', {
									key: '',
									elements: importRes.errors
										.map((error) => (typeof error === 'string' ? error : error._id || getStringFromData(error)))
										.join(','),
									error: importRes.error || 'unknown error',
							  }),
					});
				}
				dispatch({ type: 'SetWarning', payload: importRes.warning });
			} catch (err) {
				const error = err as unknown as any;
				console.warn(error);
				dispatch({ type: 'SetImported', payload: true });
				dispatch({ type: 'SetError', payload: error && error.message ? error.message : `${error || 'unknown error'}` });
			}
		}
	}, [file, importData, overwrite, t]);

	const ended = useCallback(() => {
		dispatch({ type: 'Reset' });
		onEnded(response);
	}, [onEnded, response]);

	return (
		<Dialog open={openDialog} onClose={() => cancel()} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">{t('common:import')}</DialogTitle>

			<DialogContent>
				{!!warning && <FormHelperText sx={{ ...sx, color: 'warning.main' }}>{warning}</FormHelperText>}
				{!!error && <FormHelperText sx={{ ...sx, color: 'error.main' }}>{error}</FormHelperText>}
				{errors.map((error, index) => (
					<FormHelperText key={`error-${index}`} sx={{ ...sx, color: 'error.main' }}>
						{`${errorKeyName ? `(${errorKeyName}) ` : ''}`}
						{error.element[errorKey] || `position  (${index + 1})`}: {error.error}
					</FormHelperText>
				))}
				{imported && <FormHelperText sx={{ ...sx, color: 'success.main' }}>{t('common:importSuccess')}</FormHelperText>}

				{importing && <LinearProgress variant={'indeterminate'} />}

				<Box>
					<DialogContentText>{t('common:selectFile')}</DialogContentText>
					<UploadInput
						id={id}
						name={name}
						accept={accept}
						fileName={file?.name || ''}
						onChange={onChange}
						disabled={importing || !!error || imported}
						actionDisabled={importing || imported}
					/>
					{showOverwrite && (
						<FormGroup>
							<FormControlLabel
								control={<Checkbox defaultChecked />}
								label={overwriteTitle || t('common:importOverWriteText')}
								checked={overwrite}
								onChange={() => setOverwrite(!overwrite)}
							/>
						</FormGroup>
					)}
					{overwrite && (
						<Typography variant="body1" color="error" sx={{ mb: 1 }}>
							{overwriteDescription || t('common:importOverWriteWarning')}
						</Typography>
					)}
					{importing && <Typography variant="h6">{t('common:importingPleaseWait')}</Typography>}
				</Box>

				{!!warningMessage && <FormHelperText sx={{ ...sx, color: 'warning.main' }}>{warningMessage}</FormHelperText>}

				{!!exampleFile && (
					<a href={exampleFile} target="_blank" rel="noreferrer">
						{t('common:exampleFile')}
					</a>
				)}
			</DialogContent>

			<DialogActions>
				{!imported && (
					<IconButton onClick={cancel} disabled={imported || importing}>
						<HighlightOffIcon fontSize="large" />
					</IconButton>
				)}
				{!imported && !error && errors.length === 0 && (
					<IconButton onClick={onAccept} disabled={!file || importing}>
						<ImportIcon fontSize="large" />
					</IconButton>
				)}
				{imported && (
					<IconButton onClick={ended}>
						<CheckCircleOutlineIcon fontSize="large" />
					</IconButton>
				)}
			</DialogActions>
		</Dialog>
	);
}

export default ItemsUpload;

const sx: SxProps<Theme> = {
	alignItems: 'center',
	display: 'flex',
	color: 'error.main',
	fontFamily: (theme) => theme.typography.fontFamily,
	fontSize: (theme) => theme.typography.fontSize,
	marginBottom: 1,
};
