export enum UsersTypes {
	admin = 'admin',
	panel = 'panel',
	user = 'user',
}

export enum MenuItem {
	Dashboard = 0,
	Inbox = 1,
	Starred = 2,
	Users = 3,
	User = 4,
	Clients = 5,
	Client = 6,
	Document = 7,
	Documents = 8,
	Person = 9,
	People = 10,
	Documentation = 11,
	Contacts = 12,
	Debtors = 13,
}
