import { Snackbar, Stack } from '@mui/material';
import { forwardRef } from 'react';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type CustomSnackbarType = {
	openSnackbar: boolean;
	handleClose: () => void;
	message: string;
	color: string;
	positionVertical: string;
};

const CustomSnackbar = ({ openSnackbar, handleClose, message, color, positionVertical }: CustomSnackbarType) => {
	return (
		<Stack spacing={2} sx={{ width: '100%', textAlign: 'center', position: 'relative', marginTop: '20px' }}>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={2000}
				onClose={handleClose}
				anchorOrigin={{ vertical: positionVertical === 'top' ? 'top' : 'bottom', horizontal: 'center' }}
				sx={{ position: 'absolute', zIndex: 9999 }}
			>
				<Alert onClose={handleClose} severity={color as AlertColor} sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
		</Stack>
	);
};

export default CustomSnackbar;
