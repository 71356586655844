const people = {
	id: 'Documento',
	email: 'Correo electrónico',
	name: 'Nombre',
	phone_number: 'Teléfono',
	document: 'Documento',
	debt: 'Deuda',
	debts: 'Deudas',
	createPerson: 'Crear persona',
	editPerson: 'Editar persona',
	contactCreation: 'Crear contacto',
	contactEdition: 'Editar contacto',
	debtorCreation: 'Crear deudor',
	debtorEdition: 'Editar deudor',
	editModalDescription:
		'Este formulario edita únicamente los datos del {{type}}, recuerda guardar al {{source}} para agregar al {{type}}',
} as const;

export default people;
