import { Box } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { ChangeEvent, useState } from 'react';
import { nanoid } from 'nanoid';

import PersonElementFields from 'components/PersonManagement/PersonElementFields';
import PersonNotes from 'components/PersonManagement/PersonNotes';
import PersonInfoDisplay from 'components/PersonManagement/PersonInfoDisplay';
import PersonEditModal from 'components/PersonManagement/PersonEditModal';
import PersonTable from 'components/PersonManagement/PersonTable';

import PersonAddModal from './PersonAddModal';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';

export type PersonManagementProps = {
	onAddPerson?: (person: Person) => void;
	onRemovePerson?: (identifier: string) => void;
	onPersonUpdate?: (person: Person) => void;
	people: Person[];
	type: PersonType;
	tab?: string;
};

function PersonManagement({ onAddPerson, onPersonUpdate, onRemovePerson, people, type, tab }: PersonManagementProps) {
	const { t } = useTranslation();

	const [addingPerson, setAddingPerson] = useState(false);
	const [editPerson, setEditPerson] = useState<Person | null>(null);
	const [userPerson, setUserPerson] = useState<Person | null>(null);
	const [showModalNotes, setShowModalNotes] = useState<boolean>(false);
	const [showModalNumbers, setShowModalNumbers] = useState<boolean>(false);
	const [showModalAlternativeEmails, setShowModalAlternativeEmails] = useState<boolean>(false);
	const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
	const [alternativeEmailIsValid, setAlternativeEmailIsValid] = useState<boolean>(true);
	const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>(true);

	const successSnackbar = useSuccessSnackbar(true);

	function newPerson() {
		setEditPerson({
			email: '',
			name: '',
			identifier: nanoid(),
			address: '',
			alternative_email: [''],
			area: '',
			association_label: '',
			company: '',
			notes: [],
			position: '',
			phone_number: [''],
			type,
		});
	}

	function addPerson(person: Person) {
		onAddPerson?.(person);
		setAddingPerson(false);
	}

	function updatePersonKey(key: keyof Person) {
		return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setEditPerson({ ...(editPerson || {}), [key]: ev.target.value } as Person);
		};
	}

	function save(person?: Person | null) {
		if (!person) return;

		const filteredAlternativeEmails =
			(person.alternative_email?.length ?? 0) > 1
				? person.alternative_email?.filter((email: string) => email.trim() !== '') || ['']
				: person.alternative_email;

		const filteredPhoneNumbers =
			(person.phone_number?.length ?? 0) > 1
				? person.phone_number?.filter((phone: string) => phone.trim() !== '') || ['']
				: person.phone_number;

		const filteredPerson = {
			...person,
			alternative_email: filteredAlternativeEmails,
			phone_number: filteredPhoneNumbers,
		};

		onPersonUpdate?.(filteredPerson);

		setEditPerson(null);
		if (tab === 'accordion') {
			successSnackbar();
		}
	}

	const showPersonNotes = (person: Person | null) => {
		setShowModalNotes(true);
		setUserPerson(person);
	};

	const showPersonNumber = (person: Person | null) => {
		setUserPerson(person);
		setShowModalNumbers(true);
	};

	const showPersonAlternativeEmail = (person: Person | null) => {
		setUserPerson(person);
		setShowModalAlternativeEmails(true);
	};

	const isButtonDisabled: () => boolean | undefined = () => {
		const hasEmptyPhoneNumber = editPerson?.phone_number?.slice(1).some((num: string) => num === '');
		const hasEmptyAlternativeEmail = editPerson?.alternative_email?.slice(1).some((email: string) => email === '');
		return (
			!editPerson?.name ||
			!editPerson.email ||
			!emailIsValid ||
			!alternativeEmailIsValid ||
			!phoneNumberIsValid ||
			hasEmptyPhoneNumber ||
			hasEmptyAlternativeEmail
		);
	};

	return (
		<Box>
			<PersonTable
				data={people}
				onRemovePerson={onRemovePerson}
				showPersonAlternativeEmail={showPersonAlternativeEmail}
				showPersonNumber={showPersonNumber}
				showPersonNotes={showPersonNotes}
				setEditPerson={setEditPerson}
				editPerson={editPerson}
				newPerson={newPerson}
				searchPerson={setAddingPerson}
			/>

			<PersonNotes
				onPersonUpdate={onPersonUpdate}
				setShowModalNotes={setShowModalNotes}
				showModalNotes={showModalNotes}
				setUserPerson={setUserPerson}
				userPerson={userPerson}
			/>

			<PersonInfoDisplay
				userPerson={userPerson}
				keyName="phone_number"
				openModal={showModalNumbers}
				setOpenModal={setShowModalNumbers}
				title={t(`common:theseAreAllPhoneNumbers`)}
			/>

			<PersonInfoDisplay
				userPerson={userPerson}
				keyName="alternative_email"
				openModal={showModalAlternativeEmails}
				setOpenModal={setShowModalAlternativeEmails}
				title={t(`common:theseAreAllEmails`)}
			/>

			<PersonAddModal addPerson={addPerson} open={addingPerson} onClose={() => setAddingPerson(false)} type={type} />

			<PersonEditModal
				open={editPerson !== null && !showModalNotes}
				onClose={() => setEditPerson(null)}
				onSave={(person: Person | null) => save(person)}
				editPerson={editPerson}
				updatePerson={updatePersonKey}
				emailIsValid={emailIsValid}
				setEmailIsValid={setEmailIsValid}
				isButtonDisabled={isButtonDisabled}
				type={type}
				tab={tab}
			>
				<PersonElementFields
					editPerson={editPerson}
					setEditPerson={setEditPerson}
					label={t('common:phoneNumber')}
					identifier="phone_number"
					setAlternativeEmailIsValid={null}
					alternativeEmailIsValid={alternativeEmailIsValid}
					setPhoneNumberIsValid={setPhoneNumberIsValid}
					phoneNumberIsValid={phoneNumberIsValid}
				/>

				<PersonElementFields
					editPerson={editPerson}
					setEditPerson={setEditPerson}
					label={t('common:alternativeEmail')}
					identifier="alternative_email"
					setAlternativeEmailIsValid={setAlternativeEmailIsValid}
					alternativeEmailIsValid={alternativeEmailIsValid}
					setPhoneNumberIsValid={null}
					phoneNumberIsValid={phoneNumberIsValid}
				/>
			</PersonEditModal>
		</Box>
	);
}

export default PersonManagement;
