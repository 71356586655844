import { Box, Button, IconButton, List, Modal, Paper, Stack, TextField, Typography, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';

type Props = {
	onPersonUpdate?: (person: Person) => void;
	setShowModalNotes: Dispatch<SetStateAction<boolean>>;
	showModalNotes: boolean;
	setUserPerson: Dispatch<SetStateAction<Person | null>> | null;
	userPerson: Person | null;
};

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'start',
	marginTop: '20px',
	color: theme.palette.text.secondary,
}));

const PersonNotes = ({ onPersonUpdate, setShowModalNotes, showModalNotes, setUserPerson, userPerson }: Props) => {
	const { t } = useTranslation();

	const [noteToEdit, setNoteToEdit] = useState<string>('');
	const [noteToSave, setNoteToSave] = useState<string>('');
	const [editMode, setEditMode] = useState(false);
	const [showTextField, setShowTextField] = useState<boolean>(true);
	const [saveIndex, setSaveIndex] = useState<number | null>(null);

	const toggleTextField = () => {
		setShowTextField(!showTextField);
		closeEditMode();
	};

	const handleEditClick = (index: number) => {
		if (userPerson && userPerson.notes) {
			setNoteToEdit(userPerson.notes[index]);
			setSaveIndex(index);
			setEditMode(true);
			setShowTextField(false);
		}
	};

	const handleDeleteClick = (index: number) => {
		if (!userPerson || !userPerson.notes) {
			return;
		}

		const updatedNotesList = [...userPerson.notes];
		updatedNotesList.splice(index, 1);
		setUserPerson && setUserPerson({ ...userPerson, notes: updatedNotesList });
		const reviewUser = { ...userPerson, notes: updatedNotesList };
		onPersonUpdate?.(reviewUser);

		if (userPerson.notes.length < 2) {
			setShowTextField(true);
		}
	};

	const addNote = () => {
		if (userPerson && userPerson.notes) {
			const updatedNotesList = [...userPerson.notes, noteToSave];

			setUserPerson && setUserPerson({ ...userPerson, notes: updatedNotesList });

			const reviewUser = { ...userPerson, notes: updatedNotesList };
			if (onPersonUpdate) onPersonUpdate(reviewUser);
			setNoteToSave('');
		}
	};

	const handleSaveEdit = () => {
		try {
			if (userPerson && userPerson.notes && saveIndex != null) {
				const updatedNotesList = [...userPerson.notes];
				updatedNotesList[saveIndex] = noteToEdit;
				setUserPerson && setUserPerson({ ...userPerson, notes: updatedNotesList });
				const reviewUser = { ...userPerson, notes: updatedNotesList };
				onPersonUpdate?.(reviewUser);
				setNoteToEdit('');
				setEditMode(false);
				setSaveIndex(null);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const closeEditMode = () => {
		setEditMode(false);
		setSaveIndex(null);
		setNoteToEdit('');
	};

	return (
		<Modal
			// onClose={() => setEditPerson(null)}
			open={showModalNotes}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '90%',
					bgcolor: 'background.paper',
					border: '2px solid lightgrey',
					boxShadow: 24,
					p: 4,
					overflowY: 'auto',
					maxHeight: '90vh',
				}}
			>
				<Typography variant="h4" gutterBottom>
					{t(`common:notes`)}
				</Typography>

				{onPersonUpdate && (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						{!showTextField ? (
							<IconButton color="primary" onClick={() => toggleTextField()} sx={{ mb: 1, ml: 1 }}>
								<AddIcon />
							</IconButton>
						) : (
							<IconButton
								color="error"
								disabled={(userPerson?.notes?.length ?? 0) < 1}
								onClick={() => toggleTextField()}
								sx={{ mb: 1, ml: 1 }}
							>
								<RemoveIcon />
							</IconButton>
						)}
					</Box>
				)}

				<Box
					sx={{
						borderWidth: 2,
						borderRadius: 4,
						minHeight: 50,
						maxHeight: 400,
						width: '100%',
						mb: 2,
						py: 2,
						px: 2,
						backgroundColor: '#f0f0f0',
						borderColor: '#d3d3d3',
						borderStyle: 'solid',
						overflowY: 'auto',
					}}
				>
					{onPersonUpdate && (
						<Collapse in={showTextField && !editMode}>
							<TextField
								id="note-text"
								label={t(`common:labelNotes`)}
								multiline
								fullWidth
								rows={5}
								value={noteToSave}
								onChange={(e) => setNoteToSave(e.target.value)}
								sx={{ backgroundColor: '#fff' }}
							/>
						</Collapse>
					)}
					<List>
						{userPerson?.notes?.map((element: string, index: number) => (
							<Stack spacing={2}>
								<Item key={index}>
									<div>
										{onPersonUpdate && (
											<Collapse in={editMode && saveIndex === index}>
												<TextField
													id={`note-text-${index}`}
													label={t(`common:labelNotes`)}
													multiline
													fullWidth
													rows={5}
													value={noteToEdit}
													onChange={(e) => setNoteToEdit(e.target.value)}
													sx={{ backgroundColor: '#fff' }}
												/>

												<IconButton color="primary" onClick={handleSaveEdit}>
													<SaveIcon />
												</IconButton>

												<IconButton color="error" onClick={closeEditMode}>
													<CloseIcon />
												</IconButton>
											</Collapse>
										)}

										<Collapse in={!editMode || saveIndex !== index}>
											<Typography>{element}</Typography>

											{onPersonUpdate && (
												<IconButton color="primary" onClick={() => handleEditClick(index)}>
													<EditIcon />
												</IconButton>
											)}
											{onPersonUpdate && (
												<IconButton color="error" onClick={() => handleDeleteClick(index)}>
													<DeleteIcon />
												</IconButton>
											)}
										</Collapse>
									</div>
								</Item>
							</Stack>
						))}
					</List>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 2 }}>
					{onPersonUpdate && (
						<Button
							onClick={() => addNote()}
							disabled={editMode || !showTextField || noteToSave.length < 1 ? true : false}
							variant="outlined"
						>
							{t(`common:save`)}
						</Button>
					)}

					<Button onClick={() => setShowModalNotes(false)} variant="outlined" color="error">
						{t(`common:cancel`)}
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default PersonNotes;
