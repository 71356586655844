import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import ClientDebtAccountModal from 'components/ClientDebtAccountModal/ClientDebtAccountModal';
import { useEffect, useMemo, useState } from 'react';

import CreateIcon from 'components/icons/CreateIcon/CreateIcon';
import { Button, ButtonGroup, LinearProgress, Table, TableBody, TableContainer } from '@mui/material';
import PaperContainer from 'components/containers/PaperContainer';
import { useNavigate } from 'react-router-dom';
import { deleteDebtAccount } from 'lib/models/clients';
import { currency, getErrorMessage } from 'lib/helpers';
import ImportIcon from 'components/icons/ImportIcon';
import Uploader from 'components/Uploader';
import { importAccounts } from 'lib/models/clients';
import ConfirmationDialog from 'components/ConfirmationDialog';

type Props = {
	toolbar?: boolean;
	actions?: boolean;
	dashboard: boolean;
	client: Client | null;
	refetch: () => any;
	agreement: Agreement;
};

function ClientDebtsAccountsTable({ refetch, client, toolbar = false, dashboard, agreement }: Props) {
	const [deleteAccountId, setDeleteAccountId] = useState<string | null>(null);
	const agreementAccounts = useMemo(() => {
		return client?.debtsAccounts.filter((account) => account.debts.every((debt) => debt.agreement === agreement));
	}, [agreement, client?.debtsAccounts]);

	console.debug('agreementAccounts: ', agreementAccounts);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [saving, setSaving] = useState(false);
	const [importing, toggleImport] = useState(false);
	const [updatedAccount, setUpdatedAccount] = useState<DebtsAccount | null>(null);
	const [debtsAccount, setDebtsAccount] = useState<DebtsAccount[]>(agreementAccounts || []);

	const { t } = useTranslation();
	const navigate = useNavigate();

	useEffect(() => {
		if (agreementAccounts) {
			setDebtsAccount(agreementAccounts);
		}
	}, [agreementAccounts]);

	function onClose() {
		setUpdatedAccount(null);
		setOpenModal(false);
	}

	async function deleteAccount(confirmed: boolean) {
		try {
			if (confirmed && client?._id && deleteAccountId) {
				await deleteDebtAccount(client._id, deleteAccountId);
			}
		} catch (error) {
			console.error('Error deleting debt account', error);
		}
		setDeleteAccountId(null);
		setTimeout(() => refetch());
	}

	async function importFile(file: File, overwrite: boolean = false): Promise<ParseCsvFileResult<Client>> {
		try {
			setSaving(true);
			const result = await importAccounts(client!._id!, agreement, overwrite, file);
			setSaving(false);
			setTimeout(() => refetch());
			return result;
		} catch (error) {
			console.warn(error);
			setSaving(false);
			return {
				elements: [],
				errors: [],
				error: getErrorMessage(error),
				success: false,
				warnings: [],
			};
		}
	}

	function onEnded() {
		toggleImport(false);
		setTimeout(() => refetch());
	}

	return (
		<PaperContainer sx={{ overflow: 'auto', maxHeight: '68vh' }}>
			{saving && <LinearProgress />}
			{!dashboard && <CreateIcon onCreate={() => setOpenModal(true)} />}
			{!dashboard && !!client && (
				<ImportIcon onImport={() => toggleImport(true)} secondIcon={true} title={t(`clients:import_${agreement}`)} />
			)}

			<Uploader
				id="debtors-file"
				name="debtors-file"
				accept=".csv"
				onCancel={onEnded}
				onEnded={onEnded}
				openDialog={importing}
				importData={importFile}
				warning={t('clients:warningDebtorsAccountsUploader')}
				exampleFile={
					'https://docs.google.com/spreadsheets/d/1ruUjGudKawI1A8WRzgAdqnqq_5NvP4MxC3lzHj5zgGE/edit?usp=drive_link'
				}
				errorKey="code"
				errorKeyName="code"
				showOverwrite={agreement === 'invoice'}
			/>

			{toolbar && (
				<Toolbar
					sx={{
						pl: { sm: 2 },
						pr: { xs: 1, sm: 1 },
						bgcolor: (theme) => theme.palette.primary.main,
						color: 'white',
					}}
				>
					<Typography
						sx={{ flex: '1 1 100%', textTransform: 'capitalize' }}
						variant="h6"
						id="tableTitle"
						component="div"
					>
						{t('common:upcomingMaturities')} ({t('common:account_plural')})
					</Typography>
				</Toolbar>
			)}

			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t('common:code')}</TableCell>
							<TableCell>{t('common:description')}</TableCell>
							<TableCell>{t('common:date')}</TableCell>
							<TableCell>{t('common:total')}</TableCell>
							<TableCell>{t('common:action')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{client &&
							debtsAccount &&
							debtsAccount.map((debtAccount) => {
								return (
									<TableRow key={debtAccount.code}>
										<TableCell>{debtAccount.code}</TableCell>
										<TableCell>{debtAccount.description}</TableCell>
										<TableCell>{`${moment(debtAccount.date).format('DD/MM/YYYY')}`}</TableCell>
										<TableCell>
											{currency(debtAccount.debts.reduce((total, current) => total + current.totalAmount, 0))}
										</TableCell>
										<TableCell>
											<ButtonGroup variant="outlined" aria-label="outlined button group">
												<Button
													onClick={(event) => {
														event.stopPropagation();
														setUpdatedAccount(debtAccount);
														setOpenModal(true);
													}}
												>
													{t('common:edit')}
												</Button>
												<Button
													color="success"
													onClick={() =>
														navigate(`/clients/${client._id}/agreement/${agreement}/account/${debtAccount.code}`)
													}
												>
													{t('common:debtor_plural')}
												</Button>
												<Button color="error" onClick={() => setDeleteAccountId(debtAccount.code)}>
													{t('common:delete')}
												</Button>
											</ButtonGroup>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
				{client !== null && client._id && (
					<ClientDebtAccountModal
						setDebtsAccount={setDebtsAccount}
						debtsAccounts={client.debtsAccounts}
						clientDebtsAccount={client.debtsAccounts}
						clientId={client._id}
						open={openModal}
						updatedAccount={updatedAccount}
						onClose={onClose}
					/>
				)}
			</TableContainer>
			{!!deleteAccountId && (
				<ConfirmationDialog
					title={t('clients:deleteDebtAccountTitle')}
					description={t('clients:deleteDebtAccountDescription')}
					onClose={deleteAccount}
				/>
			)}
		</PaperContainer>
	);
}

export default ClientDebtsAccountsTable;
