import { ExpandMore } from '@mui/icons-material';
import { AccordionSummary, Grid } from '@mui/material';

import { currency } from 'lib/helpers';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

type Props = {
	debt: Debt;
};

function Summary({ debt }: Props) {
	const { t } = useTranslation();

	const totalPaid =
		debt.agreement === 'invoice'
			? debt.totalAmount - (debt.balance || 0)
			: debt.scheduledPayments.reduce((acc, payment) => acc + payment.amountPaid, 0);

	const totalDue = debt.agreement === 'invoice' ? debt.balance || 0 : debt.totalAmount - totalPaid;

	return (
		<AccordionSummary expandIcon={<ExpandMore />}>
			<Grid container>
				<Grid xs={4}>{`${debt.debtor.name}`} </Grid>
				<Grid xs={2}>{moment(debt.date).format('DD/MM/YYYY')}</Grid>
				<Grid xs={2}>
					<strong>{currency(debt.totalAmount)}</strong>
				</Grid>
				<Grid xs={2}>
					({t('common:paid')} {currency(totalPaid)})
				</Grid>
				<Grid xs={2}>
					({t('common:unpaid')} {currency(totalDue)})
				</Grid>
			</Grid>
		</AccordionSummary>
	);
}

export default Summary;
