import { API_DOMAIN, PEOPLE_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch, isPaginateSource } from 'lib/helpers';

export type FetchPeopleSearchProps = {
	search?: string | null;
	type?: PersonType;
};

export type FetchPeopleProps = FetchPeopleSearchProps & PaginationProps;

export async function fetchPeople(props: FetchPeopleProps): Promise<PaginateSource<Person>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'companyName']: props.order && props.order === 'asc' ? 1 : -1,
		};
		const { filter, options } = getSearch(sort, props);

		const response = await api.get<PaginateSource<Person>>(`${API_DOMAIN}${PEOPLE_URL}`, {
			params: { filter, options },
		});

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!isPaginateSource(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function fetchPerson(_id: string): Promise<Person | null> {
	try {
		const response = await api.get<Person | null>(`${API_DOMAIN}${PEOPLE_URL}/${_id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updatePerson(person: Person): Promise<Person> {
	if (!person._id) {
		delete person._id;
	}

	const url = person._id ? `${API_DOMAIN}${PEOPLE_URL}/${person._id}` : `${API_DOMAIN}${PEOPLE_URL}`;

	const response = await (person._id ? api.patch<Person>(url, { person }) : api.post<Person>(url, { person }));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || person;
}

export async function deletePerson(person: Person): Promise<Person> {
	const url = `${API_DOMAIN}${PEOPLE_URL}/${person._id}`;

	const response = await api.delete<Person>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || person;
}

export function isPerson(person: any): person is Person {
	return !!person && !!person._id;
}
