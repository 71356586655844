import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DebtsAccountTable from 'components/DebtsAccount';
import FluidContainer from 'components/containers/FluidContainer';

import useClient from 'hooks/useClient';

function Debts() {
	const { t } = useTranslation();
	const clientData = useClient();

	const { code, debt, agreement } = useParams<{ code: string; agreement: Agreement; debt?: string }>();

	return (
		<FluidContainer title={t('common:debtsAccount')} goBack={true}>
			{code && agreement && (
				<DebtsAccountTable code={code} debt={debt} clientData={clientData.client} agreement={agreement} />
			)}
		</FluidContainer>
	);
}

export default Debts;
