const clients = {
	debtCollectionContact: 'Contacto cobranzas',
	identificationErrorMessage: 'El identificador recibido ya está siendo utilizado en otro cliente',
	debtCollectionTitle: 'Gestión de cobranzas',
	supplierPaymentDepartment: 'Departamento de cobranzas',
	collectionDays: 'Días de cobro',
	paymentDays: 'Días de pago',
	openClientDetail: 'Editar',
	warningUploader:
		'ADVERTENCIA: si el archivo que estás subiendo contiene clientes que ya están presentes en la base de datos, los sobrescribirá con los nuevos valores y se perderán todos los cambios que hayas realizado en los mismos.',
	debtsAccount: 'cuenta de deudores',
	invoiceTitle: 'Deudas (facturas)',
	paymentPlanTitle: 'Planes de pago',
	debtsAccountSuccess: 'Cuenta actualizada correctamente',
	debtAccountDuplicate: 'Cuenta duplicada correctamente',
	notesSuccess: 'Las notas fueron actualizadas',
	debt: 'deuda',
	debt_plural: 'deudas',
	debtor: 'deudor',
	debtor_plural: 'deudores',
	debtsAccountDescription: 'Cuenta A0001 de deudores de servicios',
	actionCollect: 'cobrar',
	actionDelete: 'eliminar',
	actionManage: 'gestionar',
	contactCreation: 'Creación de contacto',
	contactEdition: 'Edición de contacto',
	editContactDescription:
		'Recuerda guardar al cliente una vez que se cierre el modal, este formulario edita únicamente los datos en tu navegador, pero debes guardar al cliente para que estos cambios hagan efecto',
	importDebtors: 'Importar deudores',
	warningDebtorsUploader:
		'ADVERTENCIA: Estás importando nuevos deudores, el sistema de importación creará nuevas deudas en modo "borrador" por cada deudor encontrado en el archivo (sin importar si el mismo ya se encuentra en el listado)',
	warningDebtorsAccountsUploader:
		'ADVERTENCIA: Estás importando nuevas cuentas de deudores por cada deudor econtrado, el sistema de importación creará las deudas en modo "borrador" (se creará siempre una nueva cuenta, sin importar si el deudor ya existe)',
	generatePlan: 'Generar plan',
	regeneratePlan: 'Regenerar plan',
	generatePlanDescription:
		'Al generar un plan, se sumarán todos los cargos y se divirá por la cantidad de cuotas seleccionadas, luego se agregará el % mensual. Tener en cuenta que luego estos valores podrán ser modificados individualmente pero será responsabilidad del usuario que el total siga siendo válido.',
	regeneratePlanDescription:
		'Al regenerar un plan, se sumarán todos los cargos y se divirá por la cantidad de cuotas seleccionadas, luego se agregará el % mensual. Tener en cuenta que luego estos valores podrán ser modificados individualmente pero será responsabilidad del usuario que el total siga siendo válido. Por favor considerar que el plan actual será reemplazado.',
	generatePlanTitleWarning: 'Reemplazo del plan ACTUAL',
	generatePlanDescriptionWarning:
		'¿Está seguro que desea generar un nuevo plan? Tener en cuenta que el plan actual será eliminado (esto significa que tanto las cuotas como los pagos ya realizados se perderán)',
	dateEmailSendDescription:
		'Seleccionar cuantos días previo a la fecha de vencimiento se enviará un correo electrónico al deudor (asignar 0 si se desea envíar el mismo día del vencimiento).',
	dateEmailResendDescription:
		'Especificar la frecuencia, en días, para enviar recordatorios por correo electrónico al deudor después de la fecha de vencimiento (mínimo de un día).',
	dateEmailSchedulePayments: 'Se utiliza como día de vencimiento el día declarado en el campo "Fecha de la 1.ª cuota"',
	dateEmailScheduleCash: 'Se utiliza como día de vencimiento el día declarado en el campo "Fecha de vencimiento"',
	importAccounts: 'Importar deudas',
	import_invoice: 'Importar deudas',
	import_payment_plan: 'Importar planes de pago',
	differentPersons:
		'Advertencia: Distintos deudores encontrados dentro de la misma cuenta, la misma no podrá ser actualizada automaticamente mediante la subida de un archivo ".csv"',
	paymentPlantWarning:
		'Advertencia: los planes de pagos no permiten su actualización a través de la subida de archivos ".csv"',
	deleteDebtAccountTitle: 'Eliminar cuenta',
	deleteDebtAccountDescription:
		'¿Estás seguro de que deseas continuar? Esta acción eliminará todas las deudas asociadas a esta cuenta.',
};

export default clients;
