import { useTranslation } from 'react-i18next';
//import { useNavigate } from 'react-router';
import ScreenContainer from 'components/containers/ScreenContainer/ScreenContainer';
//import CreateIcon from 'components/icons/CreateIcon/CreateIcon';
import DocumentTable from 'components/DocumentTable/DocumentTable';

function Bills() {
	const { t } = useTranslation();

	//const navigate = useNavigate();

	return (
		<ScreenContainer title={t('common:document_plural')}>
			{/*<CreateIcon onCreate={() => navigate('/bills/new')} />*/}
			<DocumentTable />
		</ScreenContainer>
	);
}

export default Bills;
