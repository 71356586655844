import { Query } from 'hooks/useQuery';

type State = Query;

type SetPage = {
	type: 'SetPage';
	payload: number;
};

type SetRowsPerPage = {
	type: 'SetRowsPerPage';
	payload: number;
};

type SetSearch = {
	type: 'SetSearch';
	payload: string;
};

type Action = SetPage | SetRowsPerPage | SetSearch;

export function reducer(state: State, action: Action): State {
	switch (action.type) {
		case 'SetPage':
			return { ...state, page: action.payload };
		case 'SetRowsPerPage':
			return { ...state, page: 0, rowsPerPage: action.payload };
		case 'SetSearch':
			return { ...state, page: 0, search: action.payload };
		default:
			return state;
	}
}
