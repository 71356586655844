const common = {
	// Common
	name: 'Nombre',
	username: 'Nombre de usuario',
	password: 'Contraseña',
	firstName: 'Nombre',
	lastName: 'Apellido',
	saveNewNotes: 'Guardar las nuevas notas',
	email: 'Email',
	accept: 'Aceptar',
	cancel: 'Cancelar',
	close: 'Cerrar',
	create: 'Crear',
	delete: 'Eliminar',
	edit: 'Editar',
	duplicate: 'Duplicar',
	upcomingMaturities: 'Próximos vencimientos',
	filter: 'Filtrar',
	add: 'Agregar',
	notes: 'Notas',
	labelNotes: 'Escribe tu nota',
	general: 'General',
	go: 'Ir',
	open: 'Abrir',
	reload: 'Recargar',
	save: 'Guardar',
	search: 'Buscar',
	watch: 'Ver',
	selected: 'seleccionados',
	upload: 'Subir',
	uploadReceipt: 'Subir recibo',
	view: 'Ver',
	success: 'Se guardó correctamente',
	confirmSuccess: 'Se guardo correctamente, por favor confírmelo pulsando el botón Guardar',
	download: 'Descargar',
	densePadding: 'Fila densa',
	rowsPerPage: 'Líneas por página',
	findUser: 'Busque un usuario ...',
	import: 'Importar',
	importingPleaseWait: 'Por favor espere, importando',
	more: 'Más',
	code: 'Código',
	loading: 'Cargando, por favor espere...',
	role: 'rol',
	role_admin: 'Administrador',
	role_panel: 'Usuario de panel',
	role_user: 'Cliente',
	active: 'Activo',
	inactive: 'Inactivo',
	selectFile: 'Seleccionar archivo',
	fileError: 'Formato de archivo no soportado',
	importSuccess: 'Archivo importado correctamente',
	language: 'Idioma',
	spanish: 'español',
	english: 'inglés',
	valueTo: 'Hasta',
	$lte: 'Menor o igual a',
	$gte: 'Mayor o igual a',
	backOfficeDocumentationTitle: 'Documentación del panel',
	backOfficeDocumentationDescription: 'Link a documentación sobre el uso del panel',
	itDocumentationTitle: 'Documentación IT',
	itDocumentationDescription: 'Link a documentación sobre la configuración del servidor',
	welcome:
		'Bienvenido {{name}}, si necesitás ayuda con el uso del panel, o con alguna configuración del servidor, puedes acceder a la documentación con los links de abajo.',
	welcomeClient: 'Bienvenido {{name}}',
	export: 'Exportar',
	tokenExpired: 'Tu sesión ha expirado, por favor ingrese nuevamente su usuario y contraseña',
	serverIsDown: 'El servidor se encuentra inactivo, por favor contacte al departamento de sistemas para más ayuda',
	followingElementsWithError:
		'Los siguientes elementos con la columna "{{key}}" ({{elements}}) no pudieron ser creados, error: {{error}}',
	status: 'Estado',
	deleteTitle: 'Eliminación',
	deleteText: '¿Está seguro que desea eliminar el elemento seleccionado?',
	cancelDescription: '¿Está seguro que desea regresar a la pantalla de login?',
	exampleFile: 'Descargar un archivo .csv de ejemplo',
	account: 'cuenta',
	account_plural: 'cuentas',
	dashboard: 'escritorio',
	favorite: 'favorito',
	favorite_plural: 'favoritos',
	inbox: 'buzón de entrada',
	personal_area: 'Perfil',
	profile: 'perfil',
	user: 'usuario',
	user_plural: 'usuarios',
	user_me: 'mis datos',
	userTitle: 'datos de {{name}}',
	resetPassword: 'Blanquear contraseña',
	resetPasswordTitle: 'Blanquear contraseña',
	resetPasswordText: '¿Desea blanquear la contraseña del usuario seleccionado?',
	creationPasswordText:
		'La primera vez que el usuario inicie sesión creará su contraseña la cual será utilizada en los acesos siguientes.',
	client: 'Cliente',
	client_plural: 'Clientes',
	loginDate: 'Último ingreso',
	loadError: 'Hubo un problema al intentar cargar los datos',
	clientTitle: 'Clientes',
	identifier: 'Identificador',
	companyName: 'Nombre de la compañía',
	company: 'Compañía',
	area: 'Area',
	position: 'Posición',
	city: 'Ciudad',
	address: 'Dirección',
	phone: 'Teléfono',
	phoneNumber: 'Numero de Telefono',
	alternativeEmail: 'Email alternativo',
	associationLabel: 'Asociación de Marca',
	actions: 'Acciones',
	theseAreAllEmails: 'Lista de emails',
	theseAreAllPhoneNumbers: 'Lista de teléfonos',
	data: 'Datos',
	contact: 'Contacto',
	mode: 'Modo',
	date: 'Fecha',
	range: 'Rango',
	from: 'Desde',
	to: 'Hasta',
	to2: 'al',
	website: 'Sitio web',
	users: 'Usuarios',
	crm: 'CRM',
	bill: 'factura',
	bill_plural: 'facturas',
	person: 'persona',
	person_plural: 'personas',
	document: 'documento',
	document_plural: 'documentos',
	main: 'principal',
	documentation: 'documentación',
	dates: 'Fechas',
	contacts: 'Contactos',
	remove: 'remover',
	total: 'Total',
	action: 'Acción',
	debtsAccount: 'Cuenta (deudas)',
	payment_plan: 'Plan de pagos',
	dateSendEmail: 'Fecha de envío del Email',
	changeDay: 'Cambiar día',
	assingDay: 'Asignar día',
	maturitiesOfTheDay: 'Vencimientos del día',
	transfer: 'Transferencia',
	invoice: 'Factura',
	draft: 'Borrador',
	pending: 'Enviando',
	accepted: 'Aceptado',
	rejected: 'Rechazado',
	deleted: 'Eliminado',
	copy: 'Copiar',
	copied: 'Copiado',
	copy_all: 'Copiar todo',
	contact_plural: 'Contactos',
	debtor_plural: 'Deudores',
	capital: 'Capital',
	capitalValue: 'CAPITAL: {{value}}',
	capitalInterestValue: 'INTERÉS: {{value}}',
	debtData: 'Datos de Deuda',
	other: 'Otros',
	additionalData: 'Datos Adicionales',
	agreement: 'Acuerdo',
	replace: 'Reemplazar',
	acceptance: 'Aceptación',
	contractSent: 'Contrato Enviado',
	installmentCount: 'Cuotas',
	installments: 'cuotas',
	interest: 'Interes',
	monthlyInterest: 'Interes mensual',
	paymentsAgreement: 'Plan de pagos',
	acceptanceStatus: 'Aceptación',
	instaallments: 'coutas',
	description: 'Descripción',
	firstPaymentDate: 'Fecha de la 1.ª cuota',
	paymentDate: 'Fecha del pago',
	dueDate: 'Fecha de vencimiento',
	nextDueDates: 'próximos Vencimientos',
	month: 'Mes',
	month_0: 'Enero',
	month_1: 'Febrero',
	month_2: 'Marzo',
	month_3: 'Abril',
	month_4: 'Mayo',
	month_5: 'Junio',
	month_6: 'Julio',
	month_7: 'Agosto',
	month_8: 'Septiembre',
	month_9: 'Octubre',
	month_10: 'Noviembre',
	month_11: 'Diciembre',
	sendContract: 'Enviar contrato',
	resendContract: 'Reenviar contrato',
	unpaid: 'debe',
	paid: 'pagado',
	nextDate: 'Próxima fecha',
	nextDueDate: 'Próximo vencimiento',
	documentTitle: 'Comprobante',
	documentType: 'Tipo',
	invoiceNumber: 'Nro de comprobante',
	receipt: 'Recibo',
	documentDate: 'Fecha',
	todayDueDates: 'Notificaciones del día',
	lastNotification: 'Última notificación',
	monthlyDueDates: 'Vencimientos acumulados del mes',
	withoutNotifications: 'No hay notificaciones pendientes para el día de hoy',
	importOverWriteText: '¿Sobrescribir base de datos?',
	importOverWriteWarning:
		'ADVERTENCIA: Todos los elementos en la base de datos serán sobrescritos y aquellos elementos no presentes en el archivo serán eliminados.',
	changeFrequency: 'Cambiar frecuencia',
	assignFrequency: 'Asignar frecuencia',
	dateResendEmail: 'Frecuencia de recordatorio',
} as const;

export default common;
