import { useCallback } from 'react';

import useStoreDispatch from 'store/useStoreDispatch';

import { serverDown } from 'store/reducers/session';

function useSetServerDown() {
	const dispatch = useStoreDispatch();

	const setExpired = useCallback(
		(isDown: boolean) => {
			dispatch(serverDown(isDown));
		},
		[dispatch]
	);

	return setExpired;
}

export default useSetServerDown;
