import React from 'react';

import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

type IconCardProps = {
	title: string;
	description: string;
	icon: React.ReactElement;
	primaryAction?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
};

function IconCard({ title, description, icon, primaryAction }: IconCardProps) {
	return (
		<Card sx={{ width: 345, m: 2 }}>
			<CardMedia
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'primary.main',
					p: 4,
				}}
			>
				{icon}
			</CardMedia>
			<CardActionArea onClick={primaryAction}>
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						{title}
					</Typography>
					<Typography variant="body2" color="textSecondary" component="p">
						{description}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

export default IconCard;
