const clients = {
	debtCollectionContact: 'Debt contact',
	identificationErrorMessage: 'The identification received is already used in another client',
	debtCollectionTitle: 'Debt area',
	supplierPaymentDepartment: 'Supplier payment department',
	collectionDays: 'Collections days',
	paymentDays: 'Payment days',
	openClientDetail: 'Edit',
	warningUploader:
		'WARNING: If the file you are uploading contains clients that are already present in the database, it will overwrite them with the new values ​​and any changes you have made to them will be lost.',
	debtsAccount: 'debts account',
	invoiceTitle: 'Debts (invoices)',
	paymentPlanTitle: 'Payment plans',
	debtsAccountSuccess: 'Debt account correctly updated',
	debtAccountDuplicate: 'Debt account duplicated correctly',
	notesSuccess: 'notes correctly updated',
	debt: 'debt',
	debt_plural: 'debts',
	debtor: 'debtor',
	debtor_plural: 'debtors',
	debtsAccountDescription: 'Account A0001 receivable from service debtors',
	actionCollect: 'collect',
	actionDelete: 'delete',
	actionManage: 'manage',
	contactCreation: 'Contact creation',
	contactEdition: 'Contact edition',
	editContactDescription:
		'Remember to save the client after the modal is closed, this form only edits the contact data in your browser, but you must save the client to store your modifications',
	importDebtors: 'Import debtors',
	warningDebtorsUploader:
		'WARNING: Your are uploading new debtors, the import will create a new debt in "draft" mode for each debtor in the file (it doesn\'t matter if the debtor is already present in the client)',
	warningDebtorsAccountsUploader:
		'WARNING: Your are uploading new debtors accounts, the import will create a new account for each debtor found, all debts will be created in "draft" mode (it doesn\'t matter if the debtor is already present in the client, a new account will be created)',
	generatePlan: 'Generate plan',
	regeneratePlan: 'Regenerate plan',
	generatePlanDescription:
		"When generating a plan, all charges will be added up and divided by the selected number of installments. Then, the monthly percentage will be added. Please note that these values can be modified individually later, but it will be the user's responsibility to ensure that the total remains valid.",
	regeneratePlanDescription:
		"When regenerating a plan, all charges will be added up and divided by the selected number of installments. Then, the monthly percentage will be added. Please note that these values can be modified individually later, but it will be the user's responsibility to ensure that the total remains valid. Please consider that your current plan will be replaced.",
	generatePlanTitleWarning: 'Current plan REPLACEMENT',
	generatePlanDescriptionWarning:
		"Do you wan't to override the generated plan? Be aware that the existing agreement will be removed (the installments and payments already made will be lost)",
	dateEmailSendDescription:
		'Select how many days prior to the due date an email will be sent to the debtor (assign 0 if you want to send it on the same day as the due date).',
	dateEmailResendDescription:
		'Specify the frequency, in days, for sending email reminders to the debtor after the due date (minimum of one day).',
	dateEmailSchedulePayments: 'The due date is used as the day declared in the "1st Installment Date" field.',
	dateEmailScheduleCash: 'The due date is used as the day declared in the "Due Date" field.',
	importAccounts: 'Import debts',
	import_invoice: 'Import invoice',
	import_payment_plan: 'Import payment plans',
	differentPersons:
		'Warning: Different debtors found within the same account, it cannot be updated automatically by uploading a ".csv" file.',
	paymentPlantWarning: 'Warning: payment plans does not allows to update them using ".csv" files.',
	deleteDebtAccountTitle: 'Delete account',
	deleteDebtAccountDescription:
		'Are you sure you want to proceed? This action will delete all debts associated with this account.',
};

export default clients;
