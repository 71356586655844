import { ChangeEvent, useCallback, useState } from 'react';

import DebtCollectionAccordions from './DebtCollectionAccordions';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { Box, Button, FormGroup, LinearProgress } from '@mui/material';
import { updateCLient } from 'lib/models/clients';
import { enqueueSnackbarError } from 'lib/helpers';
import { Save as SaveIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
//import { useNavigate} from 'react-router-dom';

type Props = {
	client: Client;
};

function ClientDebtsCollectionForm({ client }: Props) {
	const { t } = useTranslation();

	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();

	const [loading, setLoading] = useState<boolean>(false);
	const [data, setClientData] = useState<Client>(client);
	//const navigate = useNavigate()

	const save = async () => {
		try {
			setLoading(true);
			await updateCLient(data);
			successSnackbar();
		} catch (error) {
			enqueueSnackbarError(error, errorParser);
		}
		setLoading(false);
		//navigate('/clients'); /*in the contacts is not necessary */
	};

	const onDebtCollectionUpdate = (key: keyof Client['debtCollectionInformation']) => {
		return (event: ChangeEvent<HTMLInputElement>) => {
			const value = typeof event === 'object' && event.hasOwnProperty('target') ? event.target.value : event;
			if (data) {
				setClientData({
					...data,
					debtCollectionInformation: {
						...data.debtCollectionInformation,
						[key]: value,
					},
				});
			}
		};
	};

	function onDebtCollectionContactUpdate(contact: Person) {
		const found = (data.debtCollectionInformation.contacts || []).find(
			(current) => current.identifier === contact.identifier,
		);

		let contacts = found
			? (data.debtCollectionInformation.contacts || []).map((current) => {
					if (current.identifier === contact.identifier) {
						return contact;
					}
					return current;
			  })
			: [...(data.debtCollectionInformation.contacts || []), contact];

		setClientData({
			...data,
			debtCollectionInformation: {
				...data.debtCollectionInformation,
				contacts,
			},
		});
	}

	const onRemoveContact = (identifier: string) => {
		setClientData({
			...data,
			debtCollectionInformation: {
				...data.debtCollectionInformation,
				contacts: [...(data.debtCollectionInformation.contacts || []).filter((el) => el.identifier !== identifier)],
			},
		});
	};

	return (
		<Box>
			<FormGroup>
				{loading && <LinearProgress sx={{ mb: 3 }} />}

				<DebtCollectionAccordions
					data={data}
					onUpdate={onDebtCollectionUpdate}
					onContactUpdate={onDebtCollectionContactUpdate}
					onRemoveContact={onRemoveContact}
				/>

				<Button variant="contained" color="primary" size="large" sx={{ m: 1 }} startIcon={<SaveIcon />} onClick={save}>
					{t('common:save')}
				</Button>
			</FormGroup>
		</Box>
	);
}

export default ClientDebtsCollectionForm;

/**
 * Returns an error parser method that will receive a string and return a custom label if the string "the identifier received" is present
 */
function useErrorParser() {
	const { t } = useTranslation();

	const parser = useCallback(
		/**
		 * An error parser method that will receive a string and return a custom label if the string "the identifier received" is present
		 */
		(message: string) => {
			return message.includes('the identifier received ') ? t('clients:identificationErrorMessage') : message;
		},
		[t],
	);

	return parser;
}
