const people = {
	id: 'ID',
	email: 'Email',
	name: 'Name',
	phone_number: 'Phone number',
	document: 'Document',
	debt: 'Debt',
	debts: 'Debts',
	createPerson: 'Create person',
	editPerson: 'Edit person',
	createContact: 'Create contact',
	editContact: 'Edit contact',
	createDebtor: 'Create debtor',
	editDebtor: 'Edit debtor',
	editModalDescription:
		'Remember to save once the modal is closed, this form only edits the {{type}} data, but you must save if you want to add this {{type}} into the {{source}}',
} as const;

export default people;
