import { useSearchParams } from 'react-router-dom';

import { useMemo } from 'react';

function useURLParam<T>(isT: (data: any) => data is T, defaultValue: T, paramKey: string) {
	let [searchParams, setSearchParams] = useSearchParams();

	const param = searchParams.get(paramKey);

	const result = useMemo(() => {
		const data = isT(param) ? param : defaultValue;

		return [data || defaultValue, setSearchParams];
	}, [defaultValue, isT, param, setSearchParams]);

	return result as [T, typeof setSearchParams];
}

export default useURLParam;
