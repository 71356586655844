import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ListItemText, TableHead, TableRow, TableCell, Button, Box, LinearProgress } from '@mui/material';

import SearchToolbar from 'components/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable';
import FullLayout from 'components/layouts/FullLayout';

import { rowsPerPageOptions } from 'config/constants';

import { fetchClients } from 'lib/models/clients';
import { getStringFromData } from 'lib/helpers';

import usePaginatedData from 'hooks/usePaginatedData';
import useURLQuery from 'hooks/useURLQuery';

import useQuery, { Query, defaultQuery, isQuery } from '../../hooks/useQuery';
import { reducer } from 'lib/queryReducer';
import MobileItem from './MobileItem';

import Phone from 'components/Phone';
// import Email from 'components/Email';
import DebtDate from './DebtDate';

function ClientTable() {
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useURLQuery(isQuery, defaultQuery);

	const navigate = useNavigate();

	const [{ order, page, rowsPerPage, search: searchValue }, dispatch] = useReducer(reducer, searchParams);

	const query = useQuery(searchValue, order, page, rowsPerPage);

	/**
	 * Checks if the search value was updated, if so it clears the mobile state
	 */
	const resetMobileState = useCallback((prev: Query | null) => !prev || prev.search !== searchValue, [searchValue]);

	const {
		loading,
		elements: clients,
		total,
		requestTotal,
	} = usePaginatedData(fetchClients, query, '_id', resetMobileState);

	const onEdit = useCallback(
		(selected: Client) => {
			const { _id } = selected;
			navigate(`/clients/${_id}`);
		},
		[navigate],
	);

	const search = useCallback(
		(payload: string) => {
			dispatch({ type: 'SetSearch', payload });
		},
		[dispatch],
	);

	useEffect(() => {
		const queryString = `query=${getStringFromData(query)}`;
		setSearchParams(queryString);
	}, [query, setSearchParams]);

	return (
		<FullLayout>
			{loading && <LinearProgress />}
			<SearchToolbar onSearch={(value) => search(value)} live={false} initialValue={query.search} />

			<ResponsiveTable
				elements={clients}
				list={{
					primaryKey: '_id',
					infiniteScroll: {
						endOfList: requestTotal < rowsPerPage,
						onEndReached: () => dispatch({ type: 'SetPage', payload: page + 1 }),
					},
					renderListItemText: (client) => {
						return <ListItemText primary={<MobileItem client={client} onEdit={onEdit} />} />;
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:identifier')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:companyName')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:phone')}
								</TableCell>
								{/* @TODO: add contacts list */}
								{/* <TableCell align="left" padding="normal">
									{t('clients:debtCollectionContact')}
								</TableCell> */}
								<TableCell align="left" padding="normal">
									{t('clients:collectionDays')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('clients:paymentDays')}
								</TableCell>
								<TableCell>{t('common:users')}</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:actions')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (client) => {
						return (
							<TableRow tabIndex={-1} key={client._id}>
								<TableCell>{client.identifier}</TableCell>
								<TableCell>
									<CompanyName companyName={client.companyName} website={client.website} />
								</TableCell>
								<TableCell>
									<Phone phone={client.phone} />
								</TableCell>
								{/* <TableCell>
									<Email email={client.debtCollectionInformation?.contactPerson?.email} />
								</TableCell> */}
								<TableCell>
									<DebtDate date={client.debtCollectionInformation?.collectionDays} />
								</TableCell>
								<TableCell>
									<DebtDate date={client.debtCollectionInformation?.paymentDays} />
								</TableCell>
								<TableCell>{(client.users || []).length}</TableCell>
								<TableCell>
									<Box>
										<Button onClick={() => onEdit(client)} variant="outlined">
											{t(`common:go`)}
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: (_, payload) => dispatch({ type: 'SetPage', payload }),
						onRowsPerPageChange: ({ target }) => {
							return dispatch({ type: 'SetRowsPerPage', payload: parseInt(target.value, 10) });
						},
					},
				}}
			/>
		</FullLayout>
	);
}

function CompanyName({ companyName, website }: { companyName: string; website?: string }) {
	if (website) {
		return (
			<a href={website} target="_blank" rel="noreferrer">
				{companyName}
			</a>
		);
	}
	return companyName;
}

export default ClientTable;
