import common from './common';
import system from './system';
import users from './users';
import clients from './clients';
import people from './people';

const en = {
	common,
	system,
	users,
	clients,
	people,
} as const;

export default en;
