import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

type Props = {
	amount: number;
};

const DeletionTypography = ({ amount }: Props) => {
	const { t } = useTranslation();

	return (
		<Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
			{amount} {t('common:selected')}
		</Typography>
	);
};

export default DeletionTypography;
