import { Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import Email from 'components/Email';
import Phone from 'components/Phone';
import { capitalize } from 'lib/helpers';
import { useTranslation } from 'react-i18next';

type Props = {
	debtor: Debt['debtor'];
	onClose: () => void;
};

function DebtorModal({ onClose, debtor }: Props) {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				position: 'absolute',
				width: '90%',
				maxWidth: '250px',
				top: 0,
				right: 0,
				height: '500px',
				backgroundColor: 'lightgrey',
			}}
		>
			<Box
				sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', p: 2, cursor: 'pointer' }}
				onClick={onClose}
			>
				<Close onClick={onClose} />
			</Box>

			<Box sx={{ p: 2 }}>
				<Typography variant="body1">{t('common:name')}</Typography>

				<Typography variant="caption">{debtor.name}</Typography>
			</Box>

			<Box sx={{ p: 2 }}>
				<Typography variant="body1">{t('common:email')}</Typography>

				<Typography variant="caption">
					<Email email={debtor.email || '-'} />
				</Typography>
			</Box>

			<Box sx={{ p: 2 }}>
				<Typography variant="body1">{t('common:phone')}</Typography>

				<Typography variant="caption">
					<Phone phone={debtor.phone_number?.[0] ?? '-'} />
				</Typography>
			</Box>

			<Box sx={{ p: 2 }}>
				<Typography variant="body1">{capitalize(t('common:document'))}</Typography>

				<Typography variant="caption">{debtor.document || '-'}</Typography>
			</Box>

			<Box sx={{ p: 2 }}>
				<Typography variant="body1">{capitalize(t('common:address'))}</Typography>

				<Typography variant="caption">{debtor.address || '-'}</Typography>
			</Box>
		</Box>
	);
}

export default DebtorModal;
