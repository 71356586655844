import { Route, BrowserRouter, Routes } from 'react-router-dom';

import Dashboard from 'components/screens/Dashboard';
import Inbox from 'components/screens/inbox/Inbox';
import Users from 'components/screens/Users';
import UsersEdition from 'components/screens/UserEdition';
import Clients from 'components/screens/Clients';
import ClientEdition from 'components/screens/ClientEdition';
import Documents from 'components/screens/Documents/Documents';
import People from 'components/screens/People';

import Documentation from 'components/screens/Documentation';
import Debts from 'components/screens/Debts';

import Login from 'components/screens/system/Login';
import NoMatch from 'components/screens/system/NoMatch';

import Auth from './Auth';

import useLogoutOnTokenExpired from './hooks/useLogoutOnTokenExpired';
import Logged from './Logged';

function Router() {
	// If token has expired, logout user
	useLogoutOnTokenExpired();

	return (
		<BrowserRouter>
			<Routes>
				<Route key="auth" element={<Logged />}>
					{/* PRIVATE ROUTES */}
					<Route key="route-me" path="/me" element={<Auth element={UsersEdition} rules={['Profile']} />} />
					<Route key="route-users-edit" path="/users/:id" element={<Auth element={UsersEdition} rule="UserFull" />} />
					<Route key="route-users" path="/users" element={<Auth element={Users} rule="UserFull" />} />
					<Route
						key="route-clients-edit"
						path="/clients/:id"
						element={<Auth element={ClientEdition} rule="ClientFull" />}
					/>
					<Route key="route-clients" path="/clients" element={<Auth element={Clients} rule="ClientFull" />} />
					<Route key="route-bills" path="/documents" element={<Auth element={Documents} rule="ClientFull" />} />
					<Route key="route-contact" path="/contacts" element={<Auth element={People} rule="PersonFull" />} />
					<Route key="route-debtor" path="/debtors" element={<Auth element={People} rule="PersonFull" />} />
					{/* SYSTEM PRIVATE ROUTES */}
					<Route key="route-dashboard" path="/dashboard" element={<Auth element={Dashboard} rule="ClientFull" />} />
					<Route
						key="route-debts"
						path="/clients/:id/agreement/:agreement/account/:code"
						element={<Auth element={Debts} />}
					/>
					<Route
						key="route-debts"
						path="/clients/:id/agreement/:agreement/account/:code/debt/:debt"
						element={<Auth element={Debts} />}
					/>
					<Route key="route-documentation" path="/documentation" element={<Auth element={Documentation} />} />
					<Route key="route-inbox" path="/inbox" element={<Auth element={Inbox} />} />
					<Route key="route-home" path="/" element={<Auth element={Dashboard} />} />
				</Route>
				{/* SYSTEM PUBLIC ROUTES */}
				<Route key="route-login" path="/login" element={<Login />} />
				{/* NoMatch */}
				<Route element={<NoMatch />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
