import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, ListSubheader, List } from '@mui/material';

import { DRAWER_WIDTH } from 'config/constants';

import DashboardItem from './MenuItems/Dashboard';

import UserItem from './MenuItems/User';
import UsersItem from './MenuItems/Users';
import ClientsItem from './MenuItems/Clients';
import DocumentsItem from './MenuItems/Documents';
import DocumentationItem from './MenuItems/Documentation';

import AuthorizedComponent from 'components/AuthorizedComponent';

import useInDev from 'hooks/useInDev';

import useDrawer from '../hooks/useDrawer';
import useDrawerActions from '../hooks/useDrawerActions';
import Contacts from './MenuItems/Contacts';
import Debtors from './MenuItems/Debtors';

type Props = {
	open?: boolean;
};

function Menu({ open }: Props) {
	const inDEV = useInDev();

	const { t } = useTranslation();

	const { selectedItem } = useDrawer();

	const { setSelectedItem } = useDrawerActions();

	const handleListItemClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		setSelectedItem(index);
	};

	return (
		<List
			component="nav"
			aria-labelledby="nested-list-subheader"
			subheader={
				<ListSubheader component="div" id="nested-list-subheader">
					{t('common:general')}
				</ListSubheader>
			}
			sx={{
				width: '100%',
				maxWidth: DRAWER_WIDTH,
				backgroundColor: 'background.paper',
				paddingTop: 4,
			}}
		>
			<AuthorizedComponent
				element={() => <DashboardItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="*"
			/>

			<AuthorizedComponent
				element={() => <UsersItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="UserFull"
			/>

			<Divider />

			{open && (
				<ListSubheader component="div" id="nested-list-subheader">
					{t('common:crm')}
				</ListSubheader>
			)}

			<AuthorizedComponent
				element={() => <ClientsItem selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['UserFull']}
				mode="or"
			/>

			<Divider />

			{open && (
				<ListSubheader component="div" id="nested-list-subheader">
					{t('common:main')}
				</ListSubheader>
			)}

			<AuthorizedComponent
				element={() => <DocumentsItem selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['UserFull']}
				mode="or"
			/>

			<AuthorizedComponent
				element={() => <Debtors selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['PersonFull']}
				mode="or"
			/>

			<AuthorizedComponent
				element={() => <Contacts selected={selectedItem} onSelect={handleListItemClick} />}
				rules={['PersonFull']}
				mode="or"
			/>

			<Divider />

			{inDEV && open && (
				<ListSubheader component="div" id="nested-list-subheader">
					{t('common:personal_area')}
				</ListSubheader>
			)}

			<AuthorizedComponent
				element={() => <UserItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule={['Profile']}
				mode="or"
			/>

			<AuthorizedComponent
				element={() => <DocumentationItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="*"
			/>
		</List>
	);
}

export default Menu;
