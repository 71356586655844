import { Accordion, AccordionDetails, AccordionSummary, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslation } from 'react-i18next';

import { ContactsProps } from './ContactsAccordion';

type Props = {
	onUpdate: (key: keyof Client['debtCollectionInformation']) => (event: any) => void;
	data: Client;
} & ContactsProps;

function DebtCollectionAccordion(props: Props) {
	const { onUpdate, data } = props;

	const { t } = useTranslation();

	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="DebtCollection-content"
				id="DebtCollection-header"
			>
				<Typography>{t('clients:debtCollectionTitle')}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<TextField
					id="supplierPaymentDepartment"
					label={t('clients:supplierPaymentDepartment')}
					fullWidth={true}
					value={data.debtCollectionInformation.supplierPaymentDepartment}
					variant="outlined"
					sx={{ mb: 1 }}
					onChange={onUpdate('supplierPaymentDepartment')}
				/>
			</AccordionDetails>
		</Accordion>
	);
}

export default DebtCollectionAccordion;
