import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';

import ClientTable from 'components/ClientTable';
import CreateIcon from 'components/icons/CreateIcon';

function Clients() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<ScreenContainer title={t('common:client_plural')}>
			<CreateIcon onCreate={() => navigate('/clients/new')} />

			<ClientTable />
		</ScreenContainer>
	);
}

export default Clients;
