import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';

import CreateIcon from 'components/icons/CreateIcon';

import PeopleTable from 'components/PeopleTable';
import PersonEditModal from 'components/PersonManagement/PersonEditModal';
import { ChangeEvent, useState } from 'react';
import { nanoid } from 'nanoid';
import PersonElementFields from 'components/PersonManagement/PersonElementFields';
import PersonInfoDisplay from 'components/PersonManagement/PersonInfoDisplay';

function People() {
	const { t } = useTranslation();

	const { pathname } = useLocation();

	const [person, setPerson] = useState<Person | null>(null);
	const [emailIsValid, setEmailIsValid] = useState<boolean>(true);
	const [refetchKey, setRefetchKey] = useState(nanoid());
	const [alternativeEmailIsValid, setAlternativeEmailIsValid] = useState<boolean>(true);
	const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>(true);
	const [userShowPerson, setShowPerson] = useState<Person | null>(null);
	const [showModalNumbers, setShowModalNumbers] = useState<boolean>(false);
	const [showModalAlternativeEmails, setShowModalAlternativeEmails] = useState<boolean>(false);

	const isDebtorList = pathname.includes('debtors');

	const type = isDebtorList ? 'debtor' : 'contact';

	function updatePersonKey(key: keyof Person) {
		return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setPerson({ ...(person || {}), [key]: ev.target.value } as Person);
		};
	}

	const showPersonNumber = (person: Person | null) => {
		setShowPerson(person);
		setShowModalNumbers(true);
	};

	const showPersonAlternativeEmail = (person: Person | null) => {
		setShowPerson(person);
		setShowModalAlternativeEmails(true);
	};

	const isButtonDisabled: () => boolean | undefined = () => {
		const hasEmptyPhoneNumber = person?.phone_number?.slice(1).some((num) => num === '');
		const hasEmptyAlternativeEmail = person?.alternative_email?.slice(1).some((email) => email === '');
		return !person?.name || !person.email || !emailIsValid || hasEmptyPhoneNumber || hasEmptyAlternativeEmail;
	};

	function onClose() {
		setPerson(null);
		setRefetchKey(nanoid());
	}

	return (
		<ScreenContainer title={t(isDebtorList ? 'common:debtor_plural' : 'common:contact_plural')}>
			<CreateIcon
				onCreate={() =>
					setPerson({
						email: '',
						name: '',
						alternative_email: [''],
						phone_number: [''],
						identifier: nanoid(),
						type,
					})
				}
			/>
			<PeopleTable
				type={type}
				onSelected={setPerson}
				refetchKey={refetchKey}
				showPersonAlternativeEmail={showPersonAlternativeEmail}
				showPersonNumber={showPersonNumber}
			/>
			<PersonInfoDisplay
				userPerson={userShowPerson}
				keyName="phone_number"
				openModal={showModalNumbers}
				setOpenModal={setShowModalNumbers}
				title={t(`common:theseAreAllPhoneNumbers`)}
			/>
			<PersonInfoDisplay
				userPerson={userShowPerson}
				keyName="alternative_email"
				openModal={showModalAlternativeEmails}
				setOpenModal={setShowModalAlternativeEmails}
				title={t(`common:theseAreAllEmails`)}
			/>
			<PersonEditModal
				editPerson={person}
				onClose={onClose}
				open={!!person}
				type={type}
				updatePerson={updatePersonKey}
				emailIsValid={emailIsValid}
				setEmailIsValid={setEmailIsValid}
				isButtonDisabled={isButtonDisabled}
				onSave={onClose}
			>
				<PersonElementFields
					editPerson={person}
					setEditPerson={setPerson}
					label={t('common:phoneNumber')}
					identifier="phone_number"
					setAlternativeEmailIsValid={null}
					alternativeEmailIsValid={alternativeEmailIsValid}
					setPhoneNumberIsValid={setPhoneNumberIsValid}
					phoneNumberIsValid={phoneNumberIsValid}
				/>

				<PersonElementFields
					editPerson={person}
					setEditPerson={setPerson}
					label={t('common:alternativeEmail')}
					identifier="alternative_email"
					setAlternativeEmailIsValid={setAlternativeEmailIsValid}
					alternativeEmailIsValid={alternativeEmailIsValid}
					setPhoneNumberIsValid={null}
					phoneNumberIsValid={phoneNumberIsValid}
				/>
			</PersonEditModal>
		</ScreenContainer>
	);
}

export default People;
