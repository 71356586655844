import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	FormControl,
	Input,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DayPicker } from 'react-day-picker';
import { es, enUS as en } from 'date-fns/locale';

import i18next from 'i18next';
import moment from 'moment';

type DateMode = 'date' | 'range';

type Props = {
	label: string;
	date?: DebtCollectionDate | string;
	onChange: (date?: DebtCollectionDate) => any;
};

function DebtDate({ date, label, onChange }: Props) {
	const { t } = useTranslation();

	const defaultMode = getDefaultMode(date);
	const [mode, setMode] = useState<DateMode>(defaultMode);

	const value = useMemo(() => getCurrentValue(mode, date), [date, mode]);

	/**
	 * DateMode selector onChange handler
	 */
	const handleModeChange = useCallback(
		(event: SelectChangeEvent) => {
			const newMode = event.target.value as DateMode;
			setMode(newMode);
			const newDate = getNewDate(newMode);

			onChange(newDate);
		},
		[onChange],
	);

	return (
		<Accordion variant="outlined" expanded={true}>
			<AccordionSummary aria-controls="Contact-content" id="Contact-header">
				<Typography>{label}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<FormControl fullWidth>
					<InputLabel id="select-label">{t('common:mode')}</InputLabel>
					<Select labelId="select-label" id="select" value={mode} label={t('common:mode')} onChange={handleModeChange}>
						<MenuItem value={'date'}>{t('common:date')}</MenuItem>
						<MenuItem value={'range'}>{t('common:range')}</MenuItem>
					</Select>

					{mode === 'date' && (
						<DayPicker
							mode="single"
							fromDate={new Date()}
							locale={i18next.language.includes('en') ? en : es}
							onSelect={(date) => onChange(date)}
							selected={(value as Date) || new Date()}
							defaultMonth={value ? (value as Date) : new Date()}
						/>
					)}

					{mode === 'range' && isDebtRangeDate(value) && (
						<Box
							sx={{
								mt: 1,
								mb: 2,
								display: 'flex',
								flexDirection: 'row',
								gap: 4,
								pl: 1,
								pr: 1,
							}}
						>
							<FormControl fullWidth sx={{ mt: 2 }}>
								<InputLabel id="from-label">{t('common:from')}</InputLabel>
								<Input
									type="number"
									inputProps={{ itemType: 'number', min: 1, max: value?.to ?? 31 }}
									value={value?.from}
									onChange={(ev) => onChange({ from: Number(ev.target.value), to: value?.to } as DebtRangeDate)}
								/>
							</FormControl>
							<FormControl fullWidth sx={{ mt: 2 }}>
								<InputLabel id="to-label">{t('common:to')}</InputLabel>
								<Input
									type="number"
									inputProps={{ itemType: 'number', min: value?.from ?? 1, max: 31 }}
									value={value?.to}
									onChange={(ev) => onChange({ to: Number(ev.target.value), from: value?.from } as DebtRangeDate)}
								/>
							</FormControl>
						</Box>
					)}
				</FormControl>
			</AccordionDetails>
		</Accordion>
	);
}

export default DebtDate;

/**
 * Returns the mode to be used depending on the received date
 *
 * @param date The date of the current debt collection
 *
 * @returns A valid DateMode
 */
function getDefaultMode(date?: DebtCollectionDate | string): DateMode {
	return isDebtRangeDate(date) ? 'range' : 'date';
}

/**
 * Returns the current value from the received date inferring the date mode from its value, if value is a string it will be converted into a Date object
 *
 * @param date The current debt collection value
 *
 * @returns A valid DebtCollectionDate date
 */
export function getDebtCollectionDate(date?: DebtCollectionDate | string): DebtCollectionDate {
	const mode = getDefaultMode(date);
	return getCurrentValue(mode, date);
}

/**
 * Returns the current value from the received date, if value is a string it will be converted into a Date object
 *
 * @param mode The current date mode
 * @param date The current debt collection value
 *
 * @returns A valid DebtCollectionDate date
 */
function getCurrentValue(mode: DateMode, date?: DebtCollectionDate | string): DebtCollectionDate {
	return !date
		? mode === 'date'
			? new Date()
			: { from: 1, to: 10 }
		: typeof date === 'string'
		  ? moment(date).toDate()
		  : date;
}

/**
 * Returns a new valid DebtCollectionDate depending in the received mode
 *
 * @param mode The date mode to be used to create the new date
 *
 * @returns A valid DebtCollectionDate date
 */
function getNewDate(mode: DateMode): DebtCollectionDate {
	return mode === 'range' ? { from: 1, to: 10 } : new Date();
}

/**
 * Checks if the received DebtCollectionDate is of DebtRangeDate type
 *
 * @param date The current debt collection date
 *
 * @returns true if the date is of type DebtRangeDate
 */
export function isDebtRangeDate(date?: DebtCollectionDate | string): date is DebtRangeDate {
	if (!!date && typeof date === 'object' && date.hasOwnProperty('from')) {
		return true;
	}
	return false;
}

// /**
//  * Checks if the received DebtCollectionDate is of Date type
//  *
//  * @param date The current debt collection date
//  *
//  * @returns true if the date is of type Date
//  */
// function isDebtDate(date?: DebtCollectionDate | string): date is Date {
// 	return !!date && !isDebtRangeDate(date) && typeof date !== 'string';
// }
