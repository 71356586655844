import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, List, Paper, Stack, Tooltip, Typography, styled } from '@mui/material';

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { FileCopy } from '@mui/icons-material';

import Phone from 'components/Phone';
import Email from 'components/Email';

const Item = styled(Paper)(({ theme }) => ({
	//backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	//...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'start',
	boxShadow: 'none',
	border: 'none',
	mb: 1,
}));

const PersonInfoDisplay = ({
	userPerson,
	keyName,
	openModal,
	setOpenModal,
	title,
}: {
	userPerson: Person | null;
	keyName: keyof Person;
	openModal: boolean;
	setOpenModal: Dispatch<SetStateAction<boolean>>;
	title: string;
}) => {
	const { t } = useTranslation();
	const [isCopied, setIsCopied] = useState<boolean>(false);
	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleCopyClick = () => {
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 2000);
	};

	return (
		<Dialog open={openModal} onClose={handleCloseModal}>
			<DialogTitle>
				<Typography variant="h5" sx={{ fontWeight: 700 }}>
					{title}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Box
						sx={{
							borderWidth: 2,
							borderRadius: 4,
							minHeight: 50,
							maxHeight: 150,
							minWidth: 250,
							width: '100%',
							mb: 2,
							overflowY: 'auto',
						}}
					>
						<List>
							{userPerson &&
								userPerson[keyName]?.map((value: string, index: number) => (
									<Stack spacing={2} key={index}>
										<Item>
											<Typography
												variant="body1"
												sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
											>
												<Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
													{keyName === 'alternative_email' && <EmailIcon sx={{ color: 'green', mr: 1 }} />}
													{keyName !== 'alternative_email' && <PhoneIcon sx={{ color: 'green', mr: 1 }} />}
													{keyName === 'alternative_email' && <Email email={value} />}
													{keyName !== 'alternative_email' && <Phone phone={value} />}
												</Box>
												<CopyToClipboard text={value}>
													<IconButton size="small" sx={{ color: '#10bc97', ml: 1 }} onClick={handleCopyClick}>
														<Tooltip title={isCopied ? t('common:copied') : t('common:copy')} arrow>
															<FileCopy />
														</Tooltip>
													</IconButton>
												</CopyToClipboard>
											</Typography>
										</Item>
									</Stack>
								))}
						</List>
					</Box>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseModal} color="error">
					{t(`common:cancel`)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PersonInfoDisplay;
