import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FormGroup, Modal, TextField, Typography } from '@mui/material';

import PaperContainer from 'components/containers/PaperContainer';

import { recovery, recoveryConfirm, setToken as setTokenApi } from 'lib/api';
import { setSession } from 'lib/storage';

import useLogin from 'components/screens/system/Login/hooks/useLogin';
import ConfirmationDialog from 'components/ConfirmationDialog';

type Props = {
	onClose: () => any;
};

function Recovery(props: Props) {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [showCancel, setShowCancel] = useState(false);

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [code, setCode] = useState('');
	const [successToken, setSuccessToken] = useState('');
	const [error, setError] = useState(false);
	const loginSession = useLogin();

	const onClose = useCallback(
		async (sendRequest: boolean) => {
			if (sendRequest) {
				setLoading(true);
				try {
					const response = await recovery({ username, password });
					setSuccessToken(response.token);
				} catch (error) {
					console.error(error);
					setError(true);
				}
			}
			setLoading(false);
		},
		[password, username],
	);

	const update = useCallback(async () => {
		setLoading(true);
		try {
			const responseAPI = await recoveryConfirm({ code, password, token: successToken });
			const response: Session = { ...responseAPI, logged: true, expired: false, serverDown: false };
			setTokenApi(responseAPI.token);

			// Save in local storage
			setSession(response);

			loginSession(response);
		} catch (error) {
			console.warn(error);
			setError(true);
		}

		setLoading(false);
	}, [code, loginSession, password, successToken]);

	return (
		<Modal open={true} sx={{ width: '100%', maxWidth: '500px', margin: 'auto', mt: 2 }}>
			<PaperContainer>
				<FormGroup sx={{ width: '100%', mt: 1 }}>
					{!loading && !successToken && !error && (
						<Typography variant="body1">{t('system:recoveryMessage')}</Typography>
					)}

					{!!successToken && !loading && !error && (
						<Typography variant="body1">{t('system:recoveryCodeMessage')}</Typography>
					)}

					{!!error && <Typography variant="body1">{t('system:recoveryErrorMessage')}</Typography>}

					{!loading && !successToken && !error && (
						<TextField
							id="username"
							label={t('common:username')}
							fullWidth={true}
							value={username}
							variant="outlined"
							sx={{ mt: 2 }}
							onChange={(ev) => setUsername(ev.currentTarget.value)}
							type="email"
						/>
					)}
					{!loading && !successToken && !error && (
						<TextField
							id="password"
							label={t('common:password')}
							fullWidth={true}
							value={password}
							variant="outlined"
							sx={{ mt: 2 }}
							onChange={(ev) => setPassword(ev.currentTarget.value)}
							type="password"
						/>
					)}

					{!loading && !!successToken && (
						<TextField
							id="code"
							label={t('common:code')}
							fullWidth={true}
							value={code}
							variant="outlined"
							sx={{ mt: 2 }}
							onChange={(ev) => setCode(ev.currentTarget.value)}
							type="text"
						/>
					)}

					{!successToken && !error && (
						<Button
							variant="contained"
							color="primary"
							disabled={loading || !username || !password}
							onClick={() => onClose(true)}
							sx={{ mt: 2 }}
						>
							{t('common:send')}
						</Button>
					)}

					{successToken && (
						<Button variant="contained" color="primary" onClick={() => update()} sx={{ mt: 2 }} disabled={!code}>
							{t('common:send')}
						</Button>
					)}

					<Button
						variant="contained"
						color="secondary"
						onClick={() => setShowCancel(true)}
						sx={{ mt: 2 }}
						disabled={loading}
					>
						{t('common:cancel')}
					</Button>
				</FormGroup>
				{showCancel && (
					<ConfirmationDialog
						title={t('common:cancel')}
						description={t('common:cancelDescription') || ''}
						onClose={(confirmed) => {
							setShowCancel(false);
							if (confirmed) props.onClose();
						}}
						loading={loading}
					/>
				)}
			</PaperContainer>
		</Modal>
	);
}

export default Recovery;
