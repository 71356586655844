import { useTranslation } from 'react-i18next';

import { CloudUpload as Icon } from '@mui/icons-material';

import FloatIcon from 'components/icons/FloatIcon';

type Props = {
	title?: string;
	onImport: () => any;
	secondIcon?: boolean;
};

function ImportIcon({ onImport, title, secondIcon }: Props) {
	const { t } = useTranslation();

	return (
		<FloatIcon onClick={() => onImport()} title={title || t('common:import')} icon={Icon} secondIcon={secondIcon} />
	);
}

export default ImportIcon;
