import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources, { ns } from 'config/i18n';

const configuration = {
	resources,
	ns,
	debug: false,
	lng: 'es',
	fallbackLng: 'es',
	react: {
		useSuspense: false,
	},
	interpolation: {
		escapeValue: false,
	},
};

i18n
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init(configuration);

export default i18n;
