import { useCallback } from 'react';

import useStoreDispatch from 'store/useStoreDispatch';
import {
	handleDrawerClose as handleDrawerCloseDispatch,
	handleDrawerOpen as handleDrawerOpenDispatch,
	setSelectedItem as setSelectedItemDispatch,
} from 'store/reducers/drawer';

function useDrawerActions() {
	const dispatch = useStoreDispatch();

	const handleDrawerClose = useCallback(() => {
		dispatch(handleDrawerCloseDispatch());
	}, [dispatch]);

	const handleDrawerOpen = useCallback(() => {
		dispatch(handleDrawerOpenDispatch());
	}, [dispatch]);

	const setSelectedItem = useCallback(
		(index: number) => {
			dispatch(setSelectedItemDispatch(index));
		},
		[dispatch]
	);

	return { handleDrawerClose, handleDrawerOpen, setSelectedItem };
}

export default useDrawerActions;
