import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useUser from 'hooks/useUser';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';
import useLoggedUserDocument from 'hooks/useLoggedUserDocument';

import UserEditionForm from 'components/UserEditionForm';
import { useEffect } from 'react';

function UserEdition() {
	const { t } = useTranslation();

	const { id } = useParams<{ id: string }>();
	const { pathname } = useLocation();

	const navigate = useNavigate();

	const newUser = !!id && id === 'new' && pathname !== '/me';

	const userData = useUser();

	const isClientEdition = pathname.includes('clients');

	const title =
		userData.loading || userData.error
			? t('common:loading')
			: newUser
			  ? t('users:createUser')
			  : userData.fromParams
			    ? t('common:userTitle', { name: userData.user.first_name })
			    : t('common:profile');

	const userSession = useLoggedUserDocument();

	const editingSelf = !id || id === userSession?._id;

	useEffect(() => {
		if (userData.error) {
			navigate(isClientEdition ? '/clients' : '/users');
		}
	}, [userData.error, isClientEdition, navigate]);

	return (
		<ScreenContainer title={title}>
			<PaperContainer>
				<UserEditionForm
					userLoading={userData.loading}
					editingSelf={editingSelf}
					isModal={false}
					withDelete={true}
					withPasswordReset={true}
					withStatus={true}
					id={id || (newUser ? 'new' : '')}
					isClientEdition={isClientEdition}
					initialize={userData.user}
				/>
			</PaperContainer>
		</ScreenContainer>
	);
}

export default UserEdition;
