import React from 'react';

import { Container, SxProps } from '@mui/material';

import Drawer from 'components/Drawer';

import ImportIcon from 'components/icons/ImportIcon';
import AuthorizedComponent from 'components/AuthorizedComponent';
import { Theme } from '@emotion/react';

type Props = React.PropsWithChildren<
	{
		className?: string;
		title: string;
		onImport?: () => any;
		goBack?: boolean;
		sx?: SxProps<Theme>;
	} & Omit<AuthorizedComponentProps, 'component'>
>;

function FluidContainer({ children, className, title, onImport, goBack, sx, ...authorizationProps }: Props) {
	return (
		<Drawer title={title} goBack={goBack}>
			<Container maxWidth={false} className={className} sx={sx}>
				{!!onImport && (
					<AuthorizedComponent {...authorizationProps}>
						<ImportIcon onImport={onImport} />
					</AuthorizedComponent>
				)}
				{children}
			</Container>
		</Drawer>
	);
}

export default FluidContainer;
