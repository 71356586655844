import useStoreSelector from 'store/useStoreSelector';

function useLoggedUserDocument() {
	const session = useStoreSelector(({ session }) => session);

	const loggedUser = session.user || null;

	return loggedUser;
}

export default useLoggedUserDocument;
