import useStoreSelector from 'store/useStoreSelector';

function useRules() {
	const session = useStoreSelector(({ session }) => session);

	const rules = session.user?.Rules || ({} as ServerRules);

	return rules;
}

export default useRules;
