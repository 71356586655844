import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { validateEmail } from 'lib/validateEmail';
import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { updatePerson } from 'lib/models/person';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar';

const MAX_EMAIL_LENGTH = 320;

type PersonEditModalProps = {
	open: boolean;
	onClose: () => void;
	onSave: (person: Person | null) => void;
	editPerson: Person | null;
	updatePerson: (key: keyof Person) => (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	emailIsValid: boolean;
	setEmailIsValid: Dispatch<SetStateAction<boolean>>;
	isButtonDisabled: () => boolean | undefined;
	children?: ReactNode;
	type: PersonType;
	tab?: string;
};
type StateSnackbar = {
	openSnackbar: boolean;
	message: string;
	color: string;
};

function PersonEditModal({
	open,
	onClose,
	onSave,
	editPerson,
	updatePerson: updatePersonKey,
	emailIsValid,
	setEmailIsValid,
	isButtonDisabled,
	children,
	type,
	tab,
}: PersonEditModalProps) {
	const { t } = useTranslation();
	const [errorsName, setErrorsName] = useState({
		name: false,
	});
	const [detailsSnackbar, setDetailsSnackbar] = useState<StateSnackbar>({
		openSnackbar: false,
		message: '',
		color: 'success',
	});

	const { openSnackbar, message, color } = detailsSnackbar;

	const handleClick = (message: string, color: string) => {
		setDetailsSnackbar({ openSnackbar: true, message, color });
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setDetailsSnackbar({ ...detailsSnackbar, openSnackbar: false });
	};
	const handleUpdatePerson = (key: keyof Person) => (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newValue = ev.target.value;
		if (key === 'name') {
			const isError = key === 'name' && newValue.trim() === '';

			setErrorsName((prevErrorsName) => ({
				...prevErrorsName,
				[key]: isError,
			}));
		} else if (key === 'email') {
			const newEmail = ev.target.value;
			setEmailIsValid(validateEmail(newEmail) && newEmail.length <= MAX_EMAIL_LENGTH);
		}
		updatePersonKey(key)(ev);
	};

	async function save(person: Person | null) {
		if (person === null) return;
		try {
			if (tab && tab === 'accordion') {
				onSave(person);
			} else {
				const saved = await updatePerson(person);
				onSave(saved);
			}
		} catch (error: any) {
			console.warn(error);
			if (error.response && error.response.status === 409) {
				handleClick(t('system:errorEmailRegistered'), 'error');
			} else handleClick(t('system:requestError'), 'error');
		}
	}

	return (
		<>
			<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '90%',
						bgcolor: 'background.paper',
						border: '2px solid lightgrey',
						boxShadow: 24,
						p: 4,
						overflowY: 'auto',
						maxHeight: '90vh',
						display: 'block',
					}}
				>
					<Button onClick={onClose} variant="outlined" color="error" sx={{ position: 'absolute', top: 2, right: 2 }}>
						<Close />
					</Button>

					<Box sx={{ mt: 2 }}>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							{t(editPerson?._id ? `people:${type}Edition` : `people:${type}Creation`)}
						</Typography>

						<Typography id="modal-modal-description" variant="body1" component="p">
							{t('people:editModalDescription', {
								type: (type === 'contact' ? t('common:contact') : t('common:debtor')).toLowerCase(),
								source: (type === 'contact' ? t('common:client') : t('common:account')).toLowerCase(),
							})}
						</Typography>

						<Box sx={{ mt: 2 }}>
							<TextField
								id="firstName"
								label={t('common:firstName')}
								fullWidth={true}
								value={editPerson?.name}
								variant="outlined"
								sx={{ mb: 3 }}
								error={errorsName.name}
								helperText={errorsName.name ? t('system:emptyField') : ''}
								onChange={handleUpdatePerson('name')}
							/>

							<TextField
								id="email"
								label={t('common:email')}
								fullWidth={true}
								value={editPerson?.email}
								variant="outlined"
								sx={{ mb: 3 }}
								error={!emailIsValid}
								helperText={!emailIsValid ? t('system:errorEmail') : ''}
								onChange={handleUpdatePerson('email')}
							/>

							<TextField
								id="document"
								label={t('people:document')}
								fullWidth={true}
								value={editPerson?.document}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={handleUpdatePerson('document')}
							/>

							{children}

							<TextField
								id="company"
								label={t('common:companyName')}
								fullWidth={true}
								value={editPerson?.company}
								variant="outlined"
								sx={{ mb: 3, mt: 2 }}
								onChange={updatePersonKey('company')}
							/>

							<TextField
								id="area"
								label={t('common:area')}
								fullWidth={true}
								value={editPerson?.area}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={updatePersonKey('area')}
							/>

							<TextField
								id="position"
								label={t('common:position')}
								fullWidth={true}
								value={editPerson?.position}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={updatePersonKey('position')}
							/>

							<TextField
								id="address"
								label={t('common:address')}
								fullWidth={true}
								value={editPerson?.address}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={updatePersonKey('address')}
							/>

							<TextField
								id="associationLabel"
								label={t('common:associationLabel')}
								fullWidth={true}
								value={editPerson?.association_label}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={updatePersonKey('association_label')}
							/>
							<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
								<Button onClick={() => save(editPerson)} variant="outlined" disabled={isButtonDisabled()}>
									{editPerson?._id ? t(`common:edit`) : t(`common:save`)}
								</Button>
							</Box>
						</Box>
					</Box>
					<CustomSnackbar
						openSnackbar={openSnackbar}
						handleClose={handleClose}
						message={message}
						color={color}
						positionVertical="bottom"
					/>
				</Box>
			</Modal>
		</>
	);
}
export default PersonEditModal;
