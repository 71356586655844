import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { LinearProgress, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { rowsPerPageOptions } from 'config/constants';

import usePaginatedData from 'hooks/usePaginatedData';
import useQuery, { Query, defaultQuery, isQuery } from 'hooks/useQuery';
import useURLQuery from 'hooks/useURLQuery';

import { currency, getStringFromData } from 'lib/helpers';
import { fetchAccountsPayable } from 'lib/models/clients';
import { reducer } from 'lib/queryReducer';

import ResponsiveTable from 'components/ResponsiveTable';
import FullLayout from 'components/layouts/FullLayout';

import MobileItemDebt from './MobileItemDebt';

function PersonDebtTable() {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useURLQuery(isQuery, defaultQuery);

	const [{ order, page, rowsPerPage, search: searchValue }, dispatch] = useReducer(reducer, searchParams);

	const query = useQuery(searchValue, order, page, rowsPerPage);

	const resetMobileState = useCallback((prev: Query | null) => !prev || prev.search !== searchValue, [searchValue]);

	const {
		loading,
		elements: accountsPayable,
		total,
		requestTotal,
	} = usePaginatedData(fetchAccountsPayable, query, 'index', resetMobileState);

	useEffect(() => {
		const queryString = `query=${getStringFromData(query)}`;
		setSearchParams(queryString);
	}, [query, setSearchParams]);

	return (
		<FullLayout>
			{loading && <LinearProgress />}
			<Toolbar
				sx={{
					pl: { sm: 2 },
					pr: { xs: 1, sm: 1 },
					bgcolor: (theme) => theme.palette.primary.main,
					color: 'white',
				}}
			>
				<Typography sx={{ flex: '1 1 100%', textTransform: 'capitalize' }} variant="h6" id="tableTitle" component="div">
					{t('common:nextDueDates')}
				</Typography>
			</Toolbar>
			<ResponsiveTable
				elements={accountsPayable}
				list={{
					primaryKey: 'index',
					infiniteScroll: {
						endOfList: requestTotal < rowsPerPage,
						onEndReached: () => dispatch({ type: 'SetPage', payload: page + 1 }),
					},
					renderListItemText: (accountPayable) => (
						<MobileItemDebt key={accountPayable.scheduledPayment._id} tableScheduledPayment={accountPayable} />
					),
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								{/* <TableCell align="left" padding="normal">
									{t('common:code')}
								</TableCell> */}
								<TableCell align="left" padding="normal">
									{t('people:name')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('people:email')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:date')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('people:debt')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (accountPayable) => (
						<>
							<TableRow
								tabIndex={-1}
								key={accountPayable.scheduledPayment._id}
								onClick={() =>
									navigate(
										`/clients/${accountPayable._id}/agreement/${accountPayable.debt.agreement}/account/${accountPayable.code}/debt/${accountPayable.debt._id}`
									)
								}
								sx={{
									'&:hover': {
										backgroundColor: 'lightgray',
										cursor: 'pointer',
									},
								}}
							>
								{/* <TableCell>{accountPayable.code}</TableCell> */}
								{accountPayable.debt.agreement === 'payment_plan' ? (
									<>
										<TableCell sx={{ mb: 1 }}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'flex-start',
												}}
											>
												{accountPayable.debtor.name}
											</Box>
										</TableCell>
										<TableCell sx={{ mb: 1 }}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'flex-start',
												}}
											>
												{accountPayable.debtor.email}
											</Box>
										</TableCell>
										<TableCell>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'flex-start',
												}}
											>
												{t(`common:month_${moment(accountPayable.scheduledPayment.date).get('month')}`).substring(0, 3)}{' '}
												{moment(accountPayable.scheduledPayment.date).get('date')}
											</Box>
										</TableCell>
										<TableCell>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'flex-start',
												}}
											>
												{currency(accountPayable.scheduledPayment.amount - accountPayable.scheduledPayment.amountPaid)}
											</Box>
										</TableCell>
									</>
								) : (
									<>
										<TableCell sx={{ mb: 1 }}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'flex-start',
												}}
											>
												{accountPayable.debtor.name}
											</Box>
										</TableCell>
										<TableCell sx={{ mb: 1 }}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'flex-start',
												}}
											>
												{accountPayable.debtor.email}
											</Box>
										</TableCell>
										<TableCell>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'flex-start',
												}}
											>
												{t(`common:month_${moment(accountPayable.debt.dueDate).get('month')}`).substring(0, 3)}{' '}
												{moment(accountPayable.debt.dueDate).get('date')}
											</Box>
										</TableCell>
										<TableCell>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'flex-start',
												}}
											>
												{currency(accountPayable.debt.totalAmount)}
											</Box>
										</TableCell>
									</>
								)}
							</TableRow>
						</>
					),
					pagination: {
						count: total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: (_, payload) => dispatch({ type: 'SetPage', payload }),
						onRowsPerPageChange: ({ target }) => {
							return dispatch({ type: 'SetRowsPerPage', payload: parseInt(target.value, 10) });
						},
					},
				}}
			/>
		</FullLayout>
	);
}

export default PersonDebtTable;
