import { useCallback } from 'react';

import { removeSession } from 'lib/storage';
import useStoreDispatch from 'store/useStoreDispatch';

import { logout as logoutDispatch } from 'store/reducers/session';

function useLogout() {
	const dispatch = useStoreDispatch();

	const logout = useCallback(() => {
		dispatch(logoutDispatch());
		removeSession();
	}, [dispatch]);

	return logout;
}

export default useLogout;
