import React from 'react';

import { Paper, SxProps, Theme } from '@mui/material';

function PaperContainer({
	children,
	className,
	sx,
}: React.PropsWithChildren<{ className?: string; sx?: SxProps<Theme> }>) {
	const sxData: SxProps<Theme> = { ...(sx || {}), p: 2 };

	return (
		<Paper elevation={1} className={className} sx={sxData}>
			{children}
		</Paper>
	);
}

export default PaperContainer;
