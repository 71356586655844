import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Button, LinearProgress, TextField, Typography } from '@mui/material';

import { Form, FormikProps } from 'formik';

import Recovery from './Recovery';

const Login: React.FunctionComponent<FormikProps<LoginFormValues>> = ({
	isSubmitting,
	touched,
	errors,
	values,
	handleBlur,
	handleChange,
}) => {
	const { t } = useTranslation();
	const { classes } = useStyles();

	const [showForgot, setShowForgot] = useState(false);

	return (
		<Form className={classes.form}>
			<TextField
				fullWidth={true}
				type="text"
				name="username"
				onChange={handleChange('username')}
				onBlur={handleBlur('username')}
				value={values.username}
				variant="outlined"
				label={t('common:username')}
				sx={{ mt: 2 }}
				autoComplete="username"
			/>
			{!!touched['username'] && !!errors['username'] && (
				<Typography variant="button" color="error">
					{errors['username']}
				</Typography>
			)}
			<TextField
				fullWidth={true}
				type="password"
				name="password"
				onChange={handleChange('password')}
				onBlur={handleBlur('password')}
				value={values.password}
				variant="outlined"
				label={t('common:password')}
				sx={{ mt: 2 }}
				autoComplete="current-password"
			/>
			{!!touched['password'] && !!errors['password'] && (
				<Typography variant="button" color="error">
					{errors['password']}
				</Typography>
			)}
			{isSubmitting && <LinearProgress />}
			<Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 1 }}>
				{t('system:login')}
			</Button>
			<Button
				type="button"
				fullWidth
				variant="contained"
				color="secondary"
				sx={{ mt: 1 }}
				onClick={() => setShowForgot(true)}
			>
				{t('system:forgot')}
			</Button>
			{showForgot && <Recovery onClose={() => setShowForgot(false)} />}
		</Form>
	);
};

const useStyles = makeStyles()((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
}));

export default Login;
